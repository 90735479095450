import dayjs from 'dayjs'

import 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import duration from 'dayjs/plugin/duration'
import calendar from 'dayjs/plugin/calendar'
import minMax from 'dayjs/plugin/minMax'
import isToday from 'dayjs/plugin/isToday'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(duration)
dayjs.extend(calendar)
dayjs.extend(minMax)
dayjs.extend(isToday)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

dayjs.locale('fr')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
