export default async function ({ store, route, redirect }) {
  // check if auth for the last 1h
  const lastChecked = Date.now() - (store.state?.authSession?.lastChecked || 0)
  if (store.getters.isAuthenticated && lastChecked >= 1 * 3600 * 1000) {
    await store.dispatch('validateAuth')
  }
  // check its validity
  if (!process.server && !store.getters.isAuthenticated) {
    console.log('middleware pas auth !!!!', route.fullPath)
    if (
      route.path.startsWith('/app') &&
      !route.path.startsWith('/app/connexion') &&
      !route.path.startsWith('/app/onboarding')
    ) {
      console.log('middleware access denied to', route.fullPath)
      return redirect(302, '/app/connexion', { redirect: encodeURI(route.fullPath) })
    }
  }
}
