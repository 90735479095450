export default function (req, res, next, isDev) {
  // req is the Node.js http request object

  // filter bot searching index.php
  if (req.url.startsWith('/index.php')) {
    res.statusCode = 404
    return res.end()
  }
  // res is the Node.js http response object

  const starthrTime = process.hrtime()
  next()
  const diff = process.hrtime(starthrTime)
  const elapse = diff[0] * 1e3 + diff[1] * 1e-6

  console.log(new Date().toISOString(), req.ip, res.statusCode, req.method, req.url, elapse.toFixed(2))
}
