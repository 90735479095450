<template>
  <v-list-item v-bind="$attrs" v-on="$listeners">
    <v-list-item-avatar v-if="!hideDoneIcon" tile>
      <v-menu>
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon v-if="value.status === 'DONE'">mdi-check</v-icon>
            <v-icon v-else-if="value.status === 'CANCEL'">mdi-close</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="value.status !== 'TODO'" @click="setStatus('TODO')">
            <v-list-item-title> <v-icon left>mdi-checkbox-blank-outline</v-icon> à faire </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="value.status !== 'DONE'" @click="setStatus('DONE')">
            <v-list-item-title> <v-icon left color="green">mdi-check</v-icon> Fait </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="value.status !== 'CANCEL'" @click="setStatus('CANCEL')">
            <v-list-item-title> <v-icon left color="red">mdi-close</v-icon> Annulé </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-avatar>
    <v-list-item-content :class="dense ? 'py-0' : ''">
      <v-list-item-title>
        <v-icon v-if="value.where === 'Chez le client'" small>mdi-truck-outline</v-icon> {{ value.kind }}
      </v-list-item-title>
      <template v-if="coverMultipleDays">
        <v-list-item-subtitle>
          Du
          <span class="font-weight-bold">{{ start.calendar() }}</span>
          au
          <span class="font-weight-bold">{{ end.calendar() }}</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="publicStart && publicEnd && !publicIsSameAsEvent">
          Horraire publiques <br />
          <span>{{ publicStart.calendar() }}</span>
          au
          <span class="font-weight-bold">{{ publicEnd.calendar() }}</span>
        </v-list-item-subtitle>
      </template>
      <template v-else>
        <v-list-item-subtitle>
          <template v-if="value.fullday">
            Le <span class="font-weight-bold">{{ start.startOf('day').calendar() }}</span> toute la journée
          </template>
          <template v-else>
            <span class="font-weight-bold">{{ start.calendar() }}</span> -
            <span class="font-weight-bold">{{ end.format('HH:mm') }}</span>
          </template>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="publicStart && publicEnd && !publicIsSameAsEvent">
          Horraire publiques <br />
          <span>{{ publicStart.calendar() }}</span> -
          <span>{{ publicEnd.format('HH:mm') }}</span>
        </v-list-item-subtitle>
      </template>
      <v-list-item-subtitle v-if="value && value.assigned_to" class="text-wrap">
        Assigné à {{ value.assigned_to.map((u) => u.firstname || u.lastname || 'Inconnue').join(', ') || 'personne.' }}
        <template v-if="value.notes !== ''">
          <br />
          {{ value.notes }}
        </template>
      </v-list-item-subtitle>
    </v-list-item-content>
    <slot />
    <v-list-item-action v-if="canedit">
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon color="grey lighten-1">more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <!-- <v-list-item @click="editEvent">
            Modifier
          </v-list-item> -->
          <v-list-item @click="deleteEvent"> Supprimer </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog v-model="showEdit" max-width="500">
        <v-card>
          <v-card-title>{{ newEvent.id ? "Modifier l'évenement" : 'Nouvel évenement' }}</v-card-title>

          <v-card-text>
            <EventInput v-model="newEvent" />
          </v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn text @click="showEdit = false">Retour</v-btn>
            <v-btn v-if="newEvent.id" color="primary" :disabled="!isValidNewEvent" @click="modifyEvent">
              Je modifie ce rendez-vous
            </v-btn>
            <v-btn v-else color="primary" :disabled="!isValidNewEvent" @click="modifyEvent">
              Je créé ce rendez-vous
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
/* eslint-disable camelcase */
import cloneDeep from 'lodash-es/cloneDeep'
import { gql } from 'graphql-tag'
export default {
  components: {
    EventInput: () => import('~/components/EventInput'),
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          id: null,
          start_at: '',
          end_at: '',
          fullday: false,
          kind: '',
          assigned_to: [],
          location: 'Chez le client',
          notes: '',
        }
      },
    },
    canedit: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    hideDoneIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEdit: false,
      newEvent: {},
    }
  },
  computed: {
    start() {
      return this.value && this.$dayjs(this.value.start_at)
    },
    end() {
      return this.value && this.$dayjs(this.value.end_at)
    },
    publicStart() {
      return this.value && this.$dayjs(this.value.public_start_at)
    },
    publicEnd() {
      return this.value && this.$dayjs(this.value.public_end_at)
    },
    isPast() {
      return this.$dayjs().isBetween(this.start, this.end)
    },
    isPresent() {
      return this.end && this.end.isAfter(this.$dayjs())
    },
    coverMultipleDays() {
      return this.start && !this.start.isSame(this.$dayjs(this.end), 'day')
    },

    isValidNewEvent() {
      if (this.newEvent?.assigned_to?.length === 0) return false
      if (!this.newEvent?.start_at || !this.newEvent?.end_at) return false
      return true
    },

    publicIsSameAsEvent() {
      return this.publicStart?.isSame(this.start, 'min') && this.publicEnd?.isSame(this.end, 'min')
    },
  },
  methods: {
    editEvent() {
      this.newEvent = cloneDeep(this.value)
      this.showEdit = true
    },
    setStatus(status) {
      this.modifyEvent(status)
    },
    modifyEvent(status) {
      const event = {
        id: this.value.id,
        start_at: this.value.start_at,
        end_at: this.value.end_at,
        duration: this.value.duration,
        fullday: this.value.fullday,
        kind: this.value.kind,
        where: this.value.where,
        shop_id: this.value.shop_id,
        repair_id: this.value.repair_id || (this.value.repair && this.value.repair.id) || null,
        assigned_to: this.value.assigned_to.map((a) => a.id || a),
        notes: this.value.notes,
        status: status || this.value.status,
        public_start_at: this.value.public_start_at || this.value.start_at,
        public_end_at: this.value.public_end_at || this.value.end_at,
        constraint_start_at: this.value.constraint_start_at || this.value.start_at,
        constraint_end_at: this.value.constraint_end_at || this.value.end_at,
        trip_duration_before: this.value.trip_duration_before,
        trip_duration_after: this.value.trip_duration_after,
        trip_is_included_before: this.value.trip_is_included_before,
        trip_is_included_after: this.value.trip_is_included_after,
      }
      console.log(event)

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: EventInput!) {
              upsertEvent(input: $input) {
                id
                start_at
                end_at
                duration
                fullday
                kind
                where
                created_by
                shop_id
                repair_id
                assigned_to {
                  id
                }
                notes
                status
                public_start_at
                public_end_at
                constraint_start_at
                constraint_end_at
                trip_duration_before
                trip_duration_after
                trip_is_included_before
                trip_is_included_after
              }
            }
          `,
          // Parameters
          variables: {
            input: event,
          },
        })
        .then((res) => {
          // Result
          this.$emit('success', this.newEvent)
          console.log('event modifued', res.data.upsertEvent)
          this.showEdit = false
        })
        .catch((error) => {
          // Error
          this.$sentry.captureException(error)
          console.log(error)
          alert(error)
        })
    },
    deleteEvent() {
      if (!this.value || !this.value.id) {
        return
      }
      let question
      if (this.coverMultipleDays) {
        question = `Etes vous sûr de vouloir supprimer le rendez-vous\ndu ${this.start.calendar()}\nau ${this.end.calendar()} ?`
      } else {
        question = `Etes vous sûr de vouloir supprimer le rendez-vous du\n${this.start.calendar()} à ${this.end.format(
          'HH:mm'
        )} ?`
      }
      const res = confirm(question)
      if (res) {
        console.log('delete event')
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!) {
                removeEvent(id: $id)
              }
            `,
            variables: {
              id: this.value.id,
            },
          })
          .catch((e) => {
            this.$sentry.captureException(e)
            console.err(e)
            alert(e)
          })
      }
    },
  },
}
</script>
