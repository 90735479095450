<template>
  <v-combobox
    :items="filteredAppliances"
    item-text="name"
    label="Type d'appareil"
    :filter="filter"
    return-object
    :menu-props="{ 'allow-overflow': true }"
    v-bind="$attrs"
    :value="appliance"
    v-on="$listeners"
  >
    <template #prepend-inner>
      <img v-if="appliance && appliance.imgsrc" :src="appliance.imgsrc" height="24" class="img-gray-filter" />
    </template>
    <template #item="data">
      <template v-if="isObject(data.item)">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-avatar tile>
          <img v-if="data.item.imgsrc" :src="data.item.imgsrc" class="img-gray-filter" />
        </v-list-item-avatar>
      </template>
    </template>
  </v-combobox>
</template>

<script>
import { appliancesKind } from '~/api/skills.js'
export default {
  props: {
    value: {
      type: [Object, String],
      default: null,
    },
    categories: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      appliancesKind: appliancesKind.filter((a) => a.type === 'category'),
    }
  },
  computed: {
    filteredAppliances() {
      if (this.categories === null) {
        return appliancesKind
      }
      const ids = this.categories.map((c) => c.id || c)
      return appliancesKind.filter((a) => a.categories.filter((cid) => ids.includes(cid)).length > 0)
    },

    appliance() {
      if (typeof this.value === 'string') {
        return appliancesKind.find((a) => a.id === this.value)
      }
      return this.value
    },
  },
  methods: {
    isObject(o) {
      return typeof o !== 'object'
    },
    filter(item, queryText, itemText) {
      if (item.header) return false
      const text = item.name + ' ' + (item.search?.join(' ') || '')
      const res = text
        .toString()
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
        .includes(
          queryText
            .toString()
            .toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '')
        )
      return res
    },
  },
}
</script>
