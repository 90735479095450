export const state = (_) => ({
  statusSelected: JSON.parse(localStorage.getItem('aposio_une_task_filter')) || {},
})

export const mutations = {
  selectStatus(state, statusSelected) {
    state.statusSelected = statusSelected
    localStorage.setItem('aposio_une_task_filter', JSON.stringify(statusSelected))
  },
}

export const actions = {
  selectStatus({ commit }, statusSelected) {
    commit('selectStatus', statusSelected)
  },
}
