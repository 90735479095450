<template>
  <v-card v-bind="$attrs" v-on="$listeners">
    <v-card-title>
      <div>
        <v-chip v-if="urgency > 0" small color="red" dark @click="urgency = 0">
          <v-icon small left>mdi-fire</v-icon> Urgent
        </v-chip>
        <v-chip v-if="urgency === 0" outlined small color="grey" @click="urgency = 1">
          <v-icon small left>mdi-fire</v-icon> Urgent
        </v-chip>
      </div>
      <v-spacer />
      <v-btn outlined to="/app/sheet" @click="$emit('cancel')">Ancienne page</v-btn> <v-spacer />
      <v-btn icon @click="handleCancel"> <v-icon>mdi-close</v-icon> </v-btn>
    </v-card-title>
    <v-card-text class="px-0 px-sm-4">
      <v-stepper v-model="currentStep" vertical non-linear flat class="">
        <v-stepper-step
          ref="step-1"
          step="1"
          :complete="!!repairType"
          editable
          class="pt-2 px-2"
          :color="!!repairType ? '#29a30d' : 'primary'"
          edit-icon="check"
        >
          {{ repairType ? repairType.name : "Type d'intervention" }}
        </v-stepper-step>

        <v-stepper-content ref="step-content-1" step="1" class="d-flex justify-center py-1 ml-5 mr-0 px-0">
          <v-card flat class="d-flex flex-column align-center pl-n4">
            <v-item-group v-model="repairType">
              <v-item v-for="type in rdvType" :key="type.id" v-slot="{ active, toggle }" :value="type">
                <v-btn
                  class="my-2 d-flex justify-start"
                  block
                  :depressed="!active"
                  :outlined="active"
                  :color="`${type.color}60`"
                  @click="
                    !active && toggle()
                    nextStep()
                  "
                >
                  <v-icon v-if="type.icon" left>{{ type.icon }}</v-icon>
                  {{ type.name }}
                </v-btn>
              </v-item>
            </v-item-group>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
          ref="step-2"
          step="2"
          :complete="!!where"
          :editable="hasRepairType"
          class="pb-0 pt-2 px-2"
          :color="!!where ? '#29a30d' : 'primary'"
          edit-icon="check"
        >
          <span v-if="!where">Localisation</span>
          <span v-else> {{ where }} </span>
        </v-stepper-step>

        <v-stepper-content ref="step-content-2" step="2" class="mx-5 pr-0">
          <v-list>
            <v-list-item-group v-model="where" color="primary">
              <v-list-item value="Chez le client" class="grey lighten-5 my-2" @click="nextStep">
                <v-avatar>
                  <v-icon>mdi-map-marker-distance</v-icon>
                </v-avatar>
                <v-list-item-content> Chez le client </v-list-item-content>
              </v-list-item>
              <v-list-item value="Atelier" class="grey lighten-5 my-2" @click="nextStep">
                <v-avatar>
                  <v-icon>mdi-home-city</v-icon>
                </v-avatar>
                <v-list-item-content> En atelier </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-stepper-content>

        <v-stepper-step
          ref="step-3"
          step="3"
          :complete="!!client"
          :editable="hasRepairType"
          class="py-0 px-2"
          :class="client ? '' : 'pt-4'"
          :color="!!client ? '#29a30d' : 'primary'"
          edit-icon="check"
        >
          <span v-if="!client">Client</span>
          <client-item v-else :client="client" class="px-0 py-0" />
        </v-stepper-step>

        <v-stepper-content ref="step-content-3" step="3" class="mx-5 pr-0">
          <ClientSearch
            :value="client"
            @input="
              (c) => {
                client = c
                c && nextStep()
              }
            "
            @new-client="
              (search) => {
                editClient = true
                clientSearchQuery = search
                client = null
              }
            "
          />
          <v-dialog v-model="editClient" max-width="450" :fullscreen="$vuetify.breakpoint.xsOnly">
            <UpsertClient
              v-if="editClient"
              :value="client"
              :lastname="clientSearchQuery"
              :client-id="client && client.id"
              @cancel="editClient = false"
              @refresh="onUpsertClientInput"
            />
          </v-dialog>
        </v-stepper-content>

        <v-stepper-step
          ref="step-4"
          step="4"
          :complete="!!(appliance && appliance.kind_id)"
          :editable="hasRepairType"
          class="py-0 px-2"
          :color="!!appliance ? '#29a30d' : 'primary'"
          edit-icon="check"
        >
          <span v-if="!appliance">Intervention</span>
          <ApplianceItem v-else :value="appliance" hide-icon class="px-0" />
          <!-- <RepairItem v-else :repair="repair" /> -->
        </v-stepper-step>

        <v-stepper-content ref="step-content-4" step="4" class="ml-5 mr-0 px-0">
          <!-- TODO: faire un composant "list-item" pour les fiches existante -->
          <v-list v-if="clientRepairs && clientRepairs.length > 0" outlined class="py-0">
            <v-list-item-group v-model="repair" color="primary">
              <v-btn block text color="primary" @click="handleNewRepair">
                <v-icon left>mdi-plus</v-icon> Nouvelle fiche
              </v-btn>
              <v-divider />
              <template v-for="otherRepair in clientRepairs">
                <RepairItem
                  :key="otherRepair.id"
                  :value="otherRepair"
                  :repair="otherRepair"
                  @click="
                    {
                      appliance = otherRepair.appliance
                      nextStep()
                    }
                  "
                />
                <v-divider :key="'div_' + otherRepair.id" />
              </template>
              <v-btn block text color="primary" @click="handleNewRepair">
                <v-icon left>mdi-plus</v-icon> Nouvelle fiche
              </v-btn>
            </v-list-item-group>
          </v-list>

          <ApplianceInput
            v-show="showApplianceInput"
            ref="applianceInput"
            v-model="appliance"
            :categories="repair_shop_skills"
          />
        </v-stepper-content>

        <v-stepper-step
          ref="step-5"
          step="5"
          :complete="!!symptom"
          :editable="hasRepairType"
          class="py-0 px-2"
          :color="!!symptom ? '#29a30d' : 'primary'"
          edit-icon="check"
        >
          <span v-if="!symptom">Symptômes</span>
          <div>
            <span v-for="ans in symptom" :key="ans.id"> <span v-if="symptom[0] != ans"> > </span> {{ ans.name }} </span>
          </div>
        </v-stepper-step>

        <v-stepper-content ref="step-content-5" step="5" class="mx-5 pr-0">
          <Tunnel
            v-if="initTunnel"
            :value="symptom"
            :init="initTunnel"
            :qcm="AppliPbForm"
            @input="
              (sym) => {
                symptom = sym
                nextStep()
              }
            "
          />
        </v-stepper-content>

        <v-stepper-step
          ref="step-6"
          step="6"
          :complete="!!note_description"
          :editable="hasRepairType"
          class="py-0 px-2"
          :color="!!note_description ? '#29a30d' : 'primary'"
          edit-icon="check"
        >
          <span v-if="currentStep === '6' || note_description === ''">Précisions</span>
          <span v-else> {{ note_description }} </span>
        </v-stepper-step>

        <v-stepper-content ref="step-content-6" step="6" class="mx-5 pr-0">
          <v-textarea v-model="note_description" dense filled hide-details auto-grow @change="nextStep()" />
        </v-stepper-content>

        <!-- <v-stepper-step
          ref="step-7"
          step="7"
          :complete="photos.length > 0"
          :editable="hasRepairType"
          class="py-0 px-2"
          :color="!!photos ? '#29a30d' : 'primary'"
          edit-icon="check"
        >
          <span> {{ photos.length }} photos </span>
        </v-stepper-step>

        <v-stepper-content ref="step-content-7" step="7" class="mx-5 pr-0">
          <p>Photo de la plaque signalétique :</p>
          <FilesItem v-model="photos" />

          <div class="d-flex justify-start">
            Aspect physique : <v-rating v-model="appliance_aspect_rating" color="yellow darken-3" dense />
          </div>

          <p>Photo de l'appareil :</p>
          <FilesItem v-model="photos" />
        </v-stepper-content> -->

        <v-stepper-step ref="step-7" step="7" :complete="!!eventSelected" :editable="hasRepairType" class="py-0 px-2">
          <span v-if="!eventSelected">Rendez-vous</span>
          <span v-else> {{ $dayjs(eventSelected.start_at).format('LLLL') }} </span>
        </v-stepper-step>

        <v-stepper-content ref="step-content-7" step="7" class="mx-0 px-0">
          <!-- <v-item-group v-model="duration" flat color="primary" class="d-flex flex-row flex-wrap justify-space-around">
          <v-btn v-for="dur in durations" :key="dur" :value="dur" class="mx-4">
            {{ $dayjs.duration(dur, 'minutes').format('HH:mm') }}
          </v-btn>
        </v-item-group> -->

          <v-list dense>
            <v-list-item-group v-model="usersSelected" flat multiple color="primary">
              <v-list-item v-for="user in users" :key="user.id" :value="user.id">
                <template #default="value">
                  <v-list-item-action class="my-n8">
                    <v-simple-checkbox
                      class="my-n8"
                      :value="value.active"
                      color="primary"
                      @click="value.toggle()"
                    ></v-simple-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ user.firstname || user.lastname }}</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{ firstSlotForUserID(user.id) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <div class="px-4">
            <div class="d-flex justify-space-between align-center mt-2">
              <v-icon small class="pr-2">mdi-calendar</v-icon>
              <v-spacer />
              <v-switch v-model="fullday" dense label="Toute la journée"></v-switch>
            </div>
            <div class="d-flex justify-space-between align-baseline">
              <div class="time-title">Début</div>
              <v-text-field
                v-model="date_start"
                dense
                single-line
                type="date"
                :rules="[(e) => (e && e !== '') || 'selectionner une date']"
                class="mx-2"
              ></v-text-field>
              <template v-if="!fullday">
                à
                <v-text-field
                  v-model="time_start"
                  dense
                  single-line
                  type="time"
                  :rules="[(e) => (e && e !== '') || 'selectionner une heure']"
                ></v-text-field>
              </template>
            </div>
            <div v-if="!fullday" class="d-flex justify-space-between align-baseline">
              <div class="time-title">Durée</div>
              <v-text-field v-model="duration" dense single-line type="time" @input="eventDurationChanged" />
            </div>
            <div class="d-flex justify-space-between align-baseline">
              <div class="time-title">Fin</div>
              <v-text-field
                v-model="date_end"
                dense
                single-line
                type="date"
                :rules="[(e) => (e && e !== '') || 'selectionner une date']"
                class="mx-2"
                @input="eventDateEndChanged"
              ></v-text-field>
              <template v-if="!fullday">
                à
                <v-text-field
                  v-model="time_end"
                  dense
                  single-line
                  type="time"
                  :rules="[(e) => (e && e !== '') || 'selectionner une heure']"
                  @input="eventTimeEndChanged"
                ></v-text-field>
              </template>
            </div>
            <v-textarea
              v-model="rdv_note"
              filled
              dense
              hide-details
              auto-grow
              label="Infos sur l'évènement"
              class="mb-2"
            />
          </div>

          <div class="d-flex flex-column px-3">
            <v-btn
              v-if="usersSelected && usersSelected.length === 1 && firstSlotForUserID(usersSelected[0])"
              block
              depressed
              color="yellow darken-3"
              class="mb-4"
              @click="createNewEventFromFirstSlot"
            >
              <v-icon left>mdi-auto-fix</v-icon> {{ firstSlotForUserID(usersSelected[0]) }}
            </v-btn>
            <v-btn v-if="hasDateTime" block depressed color="primary" class="mb-4" @click="handleNewEvent">
              <v-icon left>mdi-plus</v-icon>
              {{ fullday ? $dayjs(start_at).format('LL') : $dayjs(start_at).format('LLLL') }}
            </v-btn>
            <v-btn v-if="!$props.event" block depressed @click="selectInAgenda">
              <v-icon left>mdi-calendar-plus</v-icon> Sélectionner dans l'agenda
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- <v-stepper-step ref="step-9" step="9" class="py-0 px-2">
          <span v-if="true">Fin</span>
          <span v-else> {{ $dayjs(eventSelected.start_at).format('LLLL') }} </span>
        </v-stepper-step>    
            <v-stepper-content ref="step-content-9" step="9" class="mx-5 pr-0">
          <v-textarea v-model="note_description" dense filled hide-details auto-grow @change="nextStep()" />
        </v-stepper-content> -->
      </v-stepper>
    </v-card-text>

    <v-divider />
    <v-card-actions class="">
      <v-btn :disabled="currentStep <= 1" text @click="previousStep">
        <v-icon left>mdi-chevron-left</v-icon> Précedent
      </v-btn>
      <v-spacer />

      <v-progress-linear :value="(currentStep * 100) / 7" class="mx-2" />

      <v-spacer />
      <v-btn v-if="hasNext" text color="primary" :disabled="!hasRepairType" @click="nextStep">
        Suivant <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
        v-if="isLastStep"
        text
        color="primary"
        :disabled="!hasRepairType"
        :loading="$apollo.loading || sending"
        @click="createNewRepairButtonHandler"
      >
        Créer <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.time-title {
  width: 60px;
}
</style>

<script>
import { gql } from 'graphql-tag'
import { rdvType, repairStatusFromTypes } from '~/api/skills'
import { mainCategory } from '~/api/skills.js'
import { AppliPbForm } from '~/api/Appliance_Probleme_Form'

import ApplianceItem from '~/components/ApplianceItem.vue'
import clientItem from '~/components/client-item.vue'
import ClientSearch from '~/components/ClientSearch.vue'
import UpsertClient from '~/components/upsertClient.vue'
import ApplianceInput from '~/components/ApplianceInput.vue'
import RepairItem from '~/components/RepairItem.vue'
import Tunnel from '~/components/tunnel.vue'

export default {
  components: {
    clientItem,
    ClientSearch,
    UpsertClient,
    ApplianceItem,
    ApplianceInput,
    RepairItem,
    Tunnel,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sending: false,
      currentStep: 1,
      rdvType,
      repairType: null,
      client: null,
      repair: null,
      urgency: 0,
      clientRepairs: null,
      showApplianceInput: true,
      editClient: false,
      clientSearchQuery: null,
      appliance: null,
      symptom: null,
      AppliPbForm,
      note_description: '',
      photos: [],
      where: null,
      appliance_aspect_rating: 1,
      date_start: this.$dayjs(this.$props.event?.start_at).format('YYYY-MM-DD'),
      time_start: this.$dayjs(this.$props.event?.start_at).format('HH:mm'),
      date_end: this.$dayjs(this.$props.event?.end_at).format('YYYY-MM-DD'),
      time_end: this.$dayjs(this.$props.event?.end_at).format('HH:mm'),
      start_at: this.$props.event?.start_at,
      end_at: this.$props.event?.end_at,
      fullday: false,
      eventSelected: this.$props.event,
      usersSelected: this.$props.event?.assigned_to ?? [this.$store.state.authSession.me.id],
      slots: null,
      slotsloading: false,
      slotSelected: null,
      duration: '01:00',
      durations: [15, 20, 30, 45, 60, 75],
      rdv_note: '',
    }
  },
  computed: {
    hasDateTime() {
      return this.start_at
    },

    repair_shop_skills() {
      if (this.repair_shop && this.repair_shop.users) {
        const skills = new Set()
        this.repair_shop.users.forEach((u) => {
          u.skills.forEach((s) => {
            skills.add(s.id)
          })
        })

        return [...skills]
      }
      return []
    },
    initTunnel() {
      const id = this.appliance?.kind_id
      if (!id) return null
      return this.AppliPbForm.nodes[id]
    },

    users() {
      return this.repair_shop?.users
    },

    address_oneline() {
      if (this.client?.addresses?.length > 0) {
        const a = this.client.addresses[0]
        return a.street + ' ' + a.zip + ' ' + a.city
      }
      return null
    },

    hasAllInfoForSlotAvailability() {
      return !!(
        this.where === 'Chez le client' &&
        this.address_oneline &&
        this.appliance?.kind_id &&
        mainCategory(this.appliance?.kind_id)
      )
    },
    hasRepairType() {
      return !!this.repairType
    },

    isLastStep() {
      return this.currentStep >= 7
    },
    hasNext() {
      return !this.isLastStep
    },
  },

  watch: {
    'appliance.kind_id'(n, o) {
      if (mainCategory(n) !== mainCategory(o)) {
        this.preselectUser()
      }
    },
    'slots.slots'(n) {
      if (n) {
        this.preselectUser()
      }
    },
    duration(n) {
      if (n) {
        this.retreiveSlots()
      }
    },
    hasAllInfoForSlotAvailability(n) {
      if (n) {
        this.retreiveSlots()
      }
    },

    currentStep(s) {
      const comp = this.$refs[`step-content-${s}`]
      if (comp?.$el) {
        // this.$nextTick(() => this.$vuetify.goTo(comp, { offset: 0 }))
        setTimeout(() => comp.$el.scrollIntoView({ behavior: 'smooth', alignToTop: true }), 710)
        // console.log('scrooooool')
        // setTimeout(() => this.$vuetify.goTo(comp), 2000)
      }
    },

    repairType(rt) {
      if (rt?.where) {
        this.where = rt.where
        this.nextStep()
      }
    },

    date_start(d) {
      if (d && this.time_start) {
        this.start_at = this.$dayjs(d + 'T' + this.time_start)
      }
    },
    time_start(t) {
      if (t && this.date_start) {
        this.start_at = this.$dayjs(this.date_start + 'T' + t)
      }
    },
  },
  methods: {
    mainCategory,

    timeToMinutes(time) {
      if (!time) {
        return 0
      }
      const sp = time.split(':')
      if (sp.length === 0) return 0
      if (sp.length === 1) return Number(sp[0])
      const hour = Number(sp[0])
      const min = Number(sp[1])
      return hour * 60 + min
    },

    eventDurationChanged(value) {
      const t = this.$dayjs(this.start_at).add(this.timeToMinutes(value), 'minutes')
      this.date_end = t.format('YYYY-MM-DD')
      this.time_end = t.format('HH:mm')
    },
    eventTimeEndChanged(value) {
      const end = this.$dayjs(this.date_end + 'T' + value)
      const diff = end.diff(this.start_at)
      this.duration = this.$dayjs.duration(diff).format('HH:mm')
    },
    eventDateEndChanged(value) {
      const end = this.$dayjs(value + 'T' + this.time_end)
      const diff = end.diff(this.start_at)
      this.duration = this.$dayjs.duration(diff).format('DD:HH:mm')
      console.log(end, diff, this.duration)
    },

    nextStep() {
      this.currentStep = Number(this.currentStep) + 1
      if (this.currentStep === 7 && this.where === 'Atelier') {
        this.currentStep++
      }

      if (this.currentStep === 5 && this.repairType.id === '2f0ec7cf-1e3c-4f52-a790-99b6be4d7ad8') {
        // livraison
        this.currentStep++
      }

      if (this.repairType.id === '6e686830-a53a-4355-9632-48067788c60f') {
        // autre
        this.currentStep = 7
      }
      if (this.repairType.id === '47b13e62-4444-4f98-8e32-6dfa4cf490cb') {
        // congé
        this.currentStep = 7
      }
    },
    previousStep() {
      this.currentStep = Math.max(0, Number(this.currentStep) - 1)
      if (this.currentStep === 7 && this.where !== 'Atelier') {
        this.currentStep--
      }

      if (this.currentStep === 5 && this.repairType.id === '2f0ec7cf-1e3c-4f52-a790-99b6be4d7ad8') {
        // livraison
        this.currentStep--
      }
      if (this.repairType.id === '6e686830-a53a-4355-9632-48067788c60f') {
        // autre
        this.currentStep = 1
      }
      if (this.repairType.id === '47b13e62-4444-4f98-8e32-6dfa4cf490cb') {
        // congé
        this.currentStep = 1
      }
    },

    onUpsertClientInput(newClient) {
      this.client = newClient
      this.editClient = false
      this.nextStep()
    },

    handleCancel() {
      this.$emit('cancel')
    },

    preselectUser() {
      const catID = mainCategory(this.appliance?.kind_id)
      const usersWithSkill = this.users
        .filter((user) => user.skills.some((skill) => skill.id === catID))
        .map((user) => user.id)
      if (usersWithSkill.length === 0) return
      if (usersWithSkill.length === 1) {
        this.usersSelected = [usersWithSkill[0]]
        return
      }
      if (this.slots?.slots?.length > 0) {
        const firstSlots = this.slots.slots[0]
        this.usersSelected = [firstSlots.user_id]
        return
      }
      this.usersSelected = []
    },

    handleNewRepair() {
      this.showApplianceInput = true
      setTimeout(() => this.$refs.applianceInput.$el.scrollIntoView({ behavior: 'smooth', alignToTop: true }), 610)
    },

    firstSlotForUserID(id) {
      const slot = this.slots?.slots?.find((slot) => slot.user_id === id)
      if (!slot) return null
      return this.$dayjs(slot.from).format('LLLL')
    },

    retreiveSlots() {
      this.slots = null

      // const body = {
      //   shop_id: this.$store.getters.selectedCompanyID,
      //   address: this.address_oneline,
      //   appliance_kind: mainCategory(this.appliance?.kind_id),
      //   date: this.$dayjs().endOf('day').toISOString(),
      //   number_day: 7,
      //   duration: this.timeToMinutes(this.duration),
      // }
      // this.slotsloading = true
      // const r = await fetch('/api/v1/appointment', {
      //   method: 'POST',
      //   headers: {
      //     'content-type': 'application/json',
      //   },
      //   credentials: 'same-origin',
      //   body: JSON.stringify(body),
      // })
      // this.slots = await r.json()
      // console.log('event slots', this.slots)
      // this.slotsloading = false
    },

    async createNewRepairButtonHandler() {
      console.log('createNewRepairButtonHandler')
      const id = await this.createNewRepair()
      if (this.$props.event) {
        const eventId = await this.createNewEvent()
        console.log('Created repairId:', id, 'eventId:', eventId)
      }
      this.$emit('cancel', id)
      this.$router.push(`/app/sheet/${id}`)
    },

    async selectInAgenda() {
      console.log('select in agenda')

      const id = await this.createNewRepair()

      this.$emit('cancel', id)
      this.$router.push(
        '/app/agenda?appointemnt&repair_id=' +
          (id ? encodeURIComponent(id) : '') +
          '&event_kind=' +
          encodeURIComponent(this.repairType.name) +
          '&event_notes=' +
          encodeURIComponent(this.rdv_note) +
          '&where=' +
          encodeURIComponent(this.where || 'Atelier') +
          '&assigned_to=' +
          encodeURIComponent(JSON.stringify(this.usersSelected.map((u) => u.id || u) || []))
      )
    },

    async createNewEventFromFirstSlot() {
      console.log('createNewEventFromFirstSlot')

      if (this.usersSelected?.length === 1) {
        const id = await this.createNewRepair()
        this.start_at = this.firstSlotForUserID(this.usersSelected[0])
        await this.createNewEvent()
        this.$emit('cancel', id)
        this.$router.push(`/app/sheet/${id}`)
      }
    },

    async handleNewEvent() {
      const repairId = await this.createNewRepair()
      if (repairId) {
        const eventId = await this.createNewEvent()
        console.log('Created repairId:', repairId, 'eventId:', eventId)
        // return
      }
      this.$emit('cancel', repairId)
      if (repairId) {
        this.$router.push(`/app/sheet/${repairId}`)
      } else {
        this.$router.go()
      }
    },

    async createNewEvent() {
      const start = this.fullday ? this.$dayjs(this.start_at).startOf('day') : this.$dayjs(this.start_at)
      const end = this.fullday
        ? this.$dayjs(this.date_end + 'T' + this.time_end).endOf('day')
        : this.$dayjs(this.date_end + 'T' + this.time_end)
      const duration = end.diff(start, 'minutes')

      const event = {
        start_at: start.toISOString(),
        end_at: end.toISOString(),
        duration,
        fullday: this.fullday,
        kind: this.repairType.name,
        where: this.where || 'Atelier',
        shop_id: this.$store.getters.selectedCompanyID,
        repair_id: this.repair?.id || null,
        assigned_to: this.usersSelected.map((a) => a.id || a),
        notes: this.rdv_note,

        status: 'TODO',
        public_start_at: start.toISOString(),
        public_end_at: end.toISOString(),
        constraint_start_at: start.toISOString(),
        constraint_end_at: end.toISOString(),
        trip_duration_before: 0,
        trip_duration_after: 0,
        trip_is_included_before: false,
        trip_is_included_after: true,
      }
      console.log('createNewEvent', event)

      const data = await this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: EventInput!) {
              upsertEvent(input: $input) {
                id
                start_at
                end_at
                duration
                fullday
                kind
                where
                created_by
                shop_id
                repair_id
                assigned_to {
                  id
                }
                notes
                status
                public_start_at
                public_end_at
                constraint_start_at
                constraint_end_at
                trip_duration_before
                trip_duration_after
                trip_is_included_before
                trip_is_included_after
              }
            }
          `,
          // Parameters
          variables: {
            input: event,
          },
        })
        .catch((error) => {
          // Error
          this.$sentry.captureException(error)
          console.log(error)
          alert(error)
        })

      console.log('add event', data)

      const id = data?.upsertEvent?.id
      return id
    },

    async createNewRepair() {
      console.log('create new repair')

      if (
        this.repairType.id === '6e686830-a53a-4355-9632-48067788c60f' ||
        this.repairType.id === '47b13e62-4444-4f98-8e32-6dfa4cf490cb'
      ) {
        // 'autre or 'congé'
        await this.createNewEvent()
        return
      }

      // Save repair
      const items = []
      items.push({
        type: 'ClientAdded',
        payload: this.client,
      })

      items.push({
        type: 'ApplianceAdded',
        payload: this.appliance,
      })

      if (this.symptom) {
        items.push({ type: 'SymptomAdded', payload: { tunnel_answer: this.symptom } })
      }

      if (this.note_description?.trim() !== '') {
        items.push({ type: 'NotesAdded', payload: { description: this.note_description } })
      }

      // if (this.photos) {
      //   items.push({ type: 'FilesAdded', payload: { urls: this.photos } })
      // }

      const status = repairStatusFromTypes[this.repairType?.name] || 'Brouillon'

      const body = JSON.stringify({
        repair_id: this.repair?.id,
        client_id: this.client?.id,
        client: this.client,
        status,
        urgency: this.urgency || 0,
        items,
      })

      console.log('save items to new repair', body)

      const shopID = this.$store.getters.selectedCompanyID
      this.sending = true
      const res = await fetch('/api/v1/shops/' + shopID + '/repairs', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body,
      })

      this.sending = false
      if (res.status !== 200) {
        console.log('cannot send repair items', res.statusText)
        this.sendSuccess = false
        return null
      }
      const j = await res.json()
      console.log('emit new repairID', j, j.repair_id)
      if (!this.repair) {
        this.repair = {}
      }
      if (this.repair.id !== j.repair_id) {
        this.$emit('input', j.repair_id)
      }
      this.repair.id = j.repair_id

      return j.repair_id
    },
  },

  apollo: {
    repair_shop: {
      query: gql`
        query RepairShop($id: ID!) {
          repair_shop(id: $id) {
            id
            users {
              id
              firstname
              lastname
              skills {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return { id: this.$store.getters.selectedCompanyID }
      },
    },

    clientRepairs: {
      query: gql`
        query Client($id: ID!) {
          client(id: $id) {
            id
            repairs {
              id
              created_at
              status
              urgency
              archived
              lastUpdateAt
              statusFrom
              appliance {
                id
                created_at
                type
                model
                gtin
                serial
                underwarranty
                brand
                category
                category_id
                kind
                kind_id
              }
            }
          }
        }
      `,
      variables() {
        return { id: this.client.id }
      },
      skip() {
        return !this.client
      },
      update: (data) => data?.client?.repairs,
      result({ data }) {
        this.showApplianceInput = data?.client?.repairs.length === 0
      },
    },
  },
}
</script>
