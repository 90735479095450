import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _de478f2e = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _a7dabd24 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _403e75b4 = () => interopDefault(import('../pages/annuaire/index.vue' /* webpackChunkName: "pages/annuaire/index" */))
const _4d644a80 = () => interopDefault(import('../pages/app/index.vue' /* webpackChunkName: "pages/app/index" */))
const _5dad0195 = () => interopDefault(import('../pages/legal.vue' /* webpackChunkName: "pages/legal" */))
const _7a3bb645 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _32661d40 = () => interopDefault(import('../pages/partnership.vue' /* webpackChunkName: "pages/partnership" */))
const _bbecceee = () => interopDefault(import('../pages/pro.vue' /* webpackChunkName: "pages/pro" */))
const _2d945fbd = () => interopDefault(import('../pages/rgpd.vue' /* webpackChunkName: "pages/rgpd" */))
const _828768ce = () => interopDefault(import('../pages/suivi/index.vue' /* webpackChunkName: "pages/suivi/index" */))
const _7f0b59f6 = () => interopDefault(import('../pages/tarifs.vue' /* webpackChunkName: "pages/tarifs" */))
const _766d89e4 = () => interopDefault(import('../pages/app/agenda.vue' /* webpackChunkName: "pages/app/agenda" */))
const _55473410 = () => interopDefault(import('../pages/app/client/index.vue' /* webpackChunkName: "pages/app/client/index" */))
const _0977db7a = () => interopDefault(import('../pages/app/connexion/index.vue' /* webpackChunkName: "pages/app/connexion/index" */))
const _08cb458c = () => interopDefault(import('../pages/app/logout.vue' /* webpackChunkName: "pages/app/logout" */))
const _00851ec6 = () => interopDefault(import('../pages/app/onboarding.vue' /* webpackChunkName: "pages/app/onboarding" */))
const _5c77da6d = () => interopDefault(import('../pages/app/quote/index.vue' /* webpackChunkName: "pages/app/quote/index" */))
const _3a961552 = () => interopDefault(import('../pages/app/repair/index.vue' /* webpackChunkName: "pages/app/repair/index" */))
const _506af0fd = () => interopDefault(import('../pages/app/sales/index.vue' /* webpackChunkName: "pages/app/sales/index" */))
const _534f5be8 = () => interopDefault(import('../pages/app/settings/index.vue' /* webpackChunkName: "pages/app/settings/index" */))
const _15edcf38 = () => interopDefault(import('../pages/app/teleassistance.vue' /* webpackChunkName: "pages/app/teleassistance" */))
const _e5098ada = () => interopDefault(import('../pages/app/settings/company.vue' /* webpackChunkName: "pages/app/settings/company" */))
const _3261febe = () => interopDefault(import('../pages/app/settings/newUser.vue' /* webpackChunkName: "pages/app/settings/newUser" */))
const _ce8d93c4 = () => interopDefault(import('../pages/app/settings/users.vue' /* webpackChunkName: "pages/app/settings/users" */))
const _9ed7d9a4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _40b5b8bb = () => interopDefault(import('../pages/app/repair/quote/_quoteid.vue' /* webpackChunkName: "pages/app/repair/quote/_quoteid" */))
const _fa1abb7a = () => interopDefault(import('../pages/app/client/_clientid.vue' /* webpackChunkName: "pages/app/client/_clientid" */))
const _6cfd7a05 = () => interopDefault(import('../pages/app/sheet/_repairid.vue' /* webpackChunkName: "pages/app/sheet/_repairid" */))
const _897cdb42 = () => interopDefault(import('../pages/quote/_quoteid.vue' /* webpackChunkName: "pages/quote/_quoteid" */))
const _30d64704 = () => interopDefault(import('../pages/suivi/_sheetid.vue' /* webpackChunkName: "pages/suivi/_sheetid" */))
const _61eac137 = () => interopDefault(import('../pages/_zipcode/_shopSlug/index.vue' /* webpackChunkName: "pages/_zipcode/_shopSlug/index" */))
const _3bfaef92 = () => interopDefault(import('../pages/_zipcode/_shopSlug/cgv.vue' /* webpackChunkName: "pages/_zipcode/_shopSlug/cgv" */))
const _133d11ae = () => interopDefault(import('../pages/_zipcode/_shopSlug/rdv.vue' /* webpackChunkName: "pages/_zipcode/_shopSlug/rdv" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _de478f2e,
    name: "about"
  }, {
    path: "/admin",
    component: _a7dabd24,
    name: "admin"
  }, {
    path: "/annuaire",
    component: _403e75b4,
    name: "annuaire"
  }, {
    path: "/app",
    component: _4d644a80,
    name: "app"
  }, {
    path: "/legal",
    component: _5dad0195,
    name: "legal"
  }, {
    path: "/login",
    component: _7a3bb645,
    name: "login"
  }, {
    path: "/partnership",
    component: _32661d40,
    name: "partnership"
  }, {
    path: "/pro",
    component: _bbecceee,
    name: "pro"
  }, {
    path: "/rgpd",
    component: _2d945fbd,
    name: "rgpd"
  }, {
    path: "/suivi",
    component: _828768ce,
    name: "suivi"
  }, {
    path: "/tarifs",
    component: _7f0b59f6,
    name: "tarifs"
  }, {
    path: "/app/agenda",
    component: _766d89e4,
    name: "app-agenda"
  }, {
    path: "/app/client",
    component: _55473410,
    name: "app-client"
  }, {
    path: "/app/connexion",
    component: _0977db7a,
    name: "app-connexion"
  }, {
    path: "/app/logout",
    component: _08cb458c,
    name: "app-logout"
  }, {
    path: "/app/onboarding",
    component: _00851ec6,
    name: "app-onboarding"
  }, {
    path: "/app/quote",
    component: _5c77da6d,
    name: "app-quote"
  }, {
    path: "/app/repair",
    component: _3a961552,
    name: "app-repair"
  }, {
    path: "/app/sales",
    component: _506af0fd,
    name: "app-sales"
  }, {
    path: "/app/settings",
    component: _534f5be8,
    name: "app-settings"
  }, {
    path: "/app/teleassistance",
    component: _15edcf38,
    name: "app-teleassistance"
  }, {
    path: "/app/settings/company",
    component: _e5098ada,
    name: "app-settings-company"
  }, {
    path: "/app/settings/newUser",
    component: _3261febe,
    name: "app-settings-newUser"
  }, {
    path: "/app/settings/users",
    component: _ce8d93c4,
    name: "app-settings-users"
  }, {
    path: "/",
    component: _9ed7d9a4,
    name: "index"
  }, {
    path: "/app/repair/quote/:quoteid?",
    component: _40b5b8bb,
    name: "app-repair-quote-quoteid"
  }, {
    path: "/app/client/:clientid",
    component: _fa1abb7a,
    name: "app-client-clientid"
  }, {
    path: "/app/sheet/:repairid?",
    component: _6cfd7a05,
    name: "app-sheet-repairid"
  }, {
    path: "/quote/:quoteid?",
    component: _897cdb42,
    name: "quote-quoteid"
  }, {
    path: "/suivi/:sheetid",
    component: _30d64704,
    name: "suivi-sheetid"
  }, {
    path: "/:zipcode/:shopSlug",
    component: _61eac137,
    name: "zipcode-shopSlug"
  }, {
    path: "/:zipcode/:shopSlug?/cgv",
    component: _3bfaef92,
    name: "zipcode-shopSlug-cgv"
  }, {
    path: "/:zipcode/:shopSlug?/rdv",
    component: _133d11ae,
    name: "zipcode-shopSlug-rdv"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
