const repairStatus = [
  'Brouillon',
  'Devis à faire',
  'Devis à envoyer',
  'Devis en attente Client',
  'Devis accepté',
  'Devis refusé',
  'Livraison / Enlèvement',
  'Attente de pièces',
  'Prêt à réparer',
  'En essai',
  'Réparé',
  'Attente de réglement',
  'Terminer/Archivé',
]

const quoteStatus = ['Brouillon', 'A envoyer', 'Envoyé', 'Accepté', 'Refusé', 'Expiré']
const quoteStatusPublic = ['A envoyer', 'Envoyé', 'Accepté', 'Refusé']

const invoiceStatus = ['Brouillon', 'A envoyer', 'Envoyé', 'Payé']

const rdvType = [
  { id: '070a5a6e-0000-4e29-925a-89fe75cbba2f', color: '#870da3', name: 'Diagnostic / Devis' },
  { id: '171eda25-1111-472d-a4f3-a3d33a2ab26c', color: '#fd3b5e', name: 'Réparation' },
  { id: '2f0ec7cf-1e3c-4f52-a790-99b6be4d7ad8', color: '#fed13c', name: 'Livraison / Enlèvement' },
  { id: '26c8afb2-63dd-4c2c-a5d2-9741562f5049', color: '#29a30d', name: 'Télédiagnostic' },
  { id: '6e686830-a53a-4355-9632-48067788c60f', color: '#3f51b5', name: 'Autre' },
  { id: '47b13e62-4444-4f98-8e32-6dfa4cf490cb', color: '#b5b5b5', name: 'Congé / indisponibilité' },
]

const repairStatusFromTypes = {
  'Diagnostic / Devis': 'Devis à faire',
  Réparation: 'Prêt à réparer',
  'Livraison / Enlèvement': 'Livraison / Enlèvement',
  Télédiagnostic: 'Devis à faire',
}

const familyType = [
  {
    type: 'category',
    id: 'bffda94f-d183-4395-88bb-82bd71be1be2',
    name: 'Gros électroménager',
    imgsrc: '/icons/tunnel/lave_linge.svg',
  },
  {
    type: 'category',
    id: 'c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0',
    name: 'Petit électroménager',
    imgsrc: '/icons/tunnel/pem.svg',
  },
  {
    type: 'category',
    id: '516e1f84-5627-447a-8424-c356448cfab3',
    name: 'TV/Hifi/Electronique',
    imgsrc: '/icons/tunnel/television.svg',
  },
  {
    type: 'category',
    id: '82c11348-7947-4547-af58-ea0667b173cd',
    name: 'Multimedia/Smartphone',
    imgsrc: '/icons/tunnel/multimedia.svg',
  },
  {
    type: 'category',
    id: '41d15eb8-24d4-4dc4-a997-27cf4a0de01f',
    name: 'Alarme/Videosurveillance',
    imgsrc: '/icons/tunnel/alamre.svg',
  },
  { type: 'category', id: '40c32fa6-7144-4acf-a1a5-a1cbeb51fdae', name: 'Antenne/Internet', imgsrc: '' },
  {
    type: 'category',
    id: '4d540856-6201-434a-a7a3-6874ee80823f',
    name: 'Electricite',
    imgsrc: '/icons/tunnel/electricite.svg',
  },
  {
    type: 'category',
    id: '4de25aad-9147-465f-a5fb-4784e4f39820',
    name: 'Plomberie',
    imgsrc: '/icons/tunnel/plomberie.svg',
  },
  {
    type: 'category',
    id: 'cf344821-8a72-46b5-8fe4-7e0ca9a54ddb',
    name: 'Chauffage',
    imgsrc: '/icons/tunnel/chauffage.svg',
  },
  {
    type: 'category',
    id: 'eb61f338-7a84-48c9-96b0-ba7b6f8813d7',
    name: 'Vitrerie',
    imgsrc: '/icons/tunnel/vitrerie.svg',
  },
  {
    type: 'category',
    id: '8b5cb542-e1d6-492c-8061-5eb0441dc057',
    name: 'Couverture',
    imgsrc: '/icons/tunnel/couvreur.svg',
  },
  {
    type: 'category',
    id: '54bf3898-2bbf-418e-a375-fba9503c5b3f',
    name: 'Peinture',
    imgsrc: '/icons/tunnel/peintre.svg',
  },
  { type: 'category', id: '88354ed6-e0e3-4687-a7f6-e7d9b70f63fc', name: 'Sol', imgsrc: '/icons/tunnel/sol.svg' },
  {
    type: 'category',
    id: 'ca33e7ac-b447-4806-870f-5e336428d250',
    name: 'Diagnostic immobilier',
    imgsrc: '/icons/tunnel/diagnostic.svg',
  },
  {
    type: 'sub-category',
    id: 'c2709c0f-130a-45d5-b428-37a6c9c357e6',
    name: 'Nettoyage des sols/des surfaces',
    imgsrc: '/icons/tunnel/nettoyeur.svg',
  },
  {
    id: '95bf8b15-0996-4782-a302-adaa29842c56',
    type: 'sub-category',
    name: 'Boissons',
    imgsrc: '/icons/tunnel/boisson.svg',
    search: [
      'cafetière',
      'expresso',
      'bouilloire',
      'machine à thé',
      'presse agrume',
      'broyeur à café',
      'théière',
      'tireuse à bière',
      'machine à glaçons',
      'nespresso',
      'dolce gusto',
      'senseo',
      'tassimo',
      'moulin à café',
      'percolateur',
      'sodastream',
      'centrifugeuse',
      'extracteur de jus',
    ],
  },
  {
    id: '2e8e77b3-4f50-490c-9460-37fcb2a78a3a',
    type: 'sub-category',
    name: 'Ordinateur',
    imgsrc: '/icons/tunnel/ordinateur.svg',
    search: ['pc portable', 'macbook', 'imac', 'ordinateur portable'],
  },
  {
    id: 'b17f3617-d96b-4a4a-adee-7f7f2246a52a',
    type: 'sub-category',
    name: 'Internet',
    imgsrc: '',
  },
]

const appliancesKind = [
  {
    type: 'appliance',
    id: '4e3bdb60-a680-46d7-86ab-70cccdb06de3',
    name: 'Lave-linge',
    imgsrc: '/icons/tunnel/lave_linge.svg',
    search: ['machine à laver'],
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: '4337eb33-053d-49a1-b9b3-2d24f120efa6',
    type: 'appliance',
    name: 'Réfrigerateur',
    imgsrc: '/icons/tunnel/refrigerateur.svg',
    search: ['frigo'],
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: '9cf53961-cc36-4ff9-838c-bd7c25f2d712',
    type: 'appliance',
    name: 'Lave-vaisselle',
    imgsrc: '/icons/tunnel/lave_vaisselle.svg',
    search: ['machine à laver la vaisselle'],
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: '5e720952-e1b0-450a-92e0-09b449adaf05',
    type: 'appliance',
    name: 'Four',
    imgsrc: '/icons/tunnel/four.svg',
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: '417fda7d-b7c5-4ecf-b64a-06e2cb3c875b',
    type: 'appliance',
    name: 'Sèche-linge',
    imgsrc: '/icons/tunnel/seche_linge.svg',
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: 'cd17945c-e67b-47a6-818e-c9e860bda519',
    type: 'appliance',
    name: 'Congélateur',
    imgsrc: '/icons/tunnel/congelateur.svg',
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: 'cf76916d-5849-476b-a5c3-6c5686da885c',
    type: 'appliance',
    name: 'Hotte',
    imgsrc: '/icons/tunnel/hotte.svg',
    search: ['groupe filtrant'],
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: '7d77ff89-0f7e-472f-8d75-1a24d0e87913',
    type: 'appliance',
    name: 'Plaque de cuisson',
    imgsrc: '/icons/tunnel/plaque_cuisson.svg',
    search: ['plaque électrique', 'plaque induction', 'plaque vitrocéramique', 'plaque à gaz'],
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: 'c9ecc857-6d1e-4351-992b-9fc1990055ab',
    type: 'appliance',
    name: 'Cuisiniere',
    imgsrc: '/icons/tunnel/cuisiniere.svg',
    search: ['gazinière'],
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: 'b8a4b1a4-2ac0-4b51-9652-8feddb5d21d2',
    type: 'appliance',
    name: 'Cave a vin',
    imgsrc: '/icons/tunnel/cave_vin.svg',
    search: ['cave de vieillissement'],
    categories: ['bffda94f-d183-4395-88bb-82bd71be1be2'],
  },
  {
    id: 'd1715369-0925-490f-aff0-f68d36fe18c2',
    type: 'appliance',
    name: 'Aspirateur',
    imgsrc: '/icons/tunnel/nettoyeur.svg',
    search: ['robot aspirateur', 'aspirateur bidon'],
    categories: ['c2709c0f-130a-45d5-b428-37a6c9c357e6', 'c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    id: '66b05113-366f-4fee-8a9e-8dc8b26d6cc8',
    type: 'appliance',
    name: 'Nettoyeur',
    imgsrc: '/icons/tunnel/nettoyeur.svg',
    search: ['nettoyeur vapeur', 'nettoyeur vitre'],
    categories: ['c2709c0f-130a-45d5-b428-37a6c9c357e6', 'c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    id: 'e628f657-befe-4b59-8e60-0a75ef4e5122',
    type: 'appliance',
    name: 'Préparation culinaire',
    imgsrc: '/icons/tunnel/culinaire.svg',
    search: [
      'robot pâtissier',
      'robot de cuisine',
      'cuiseur vapeur',
      'cuiseur à riz',
      'multicuiseur',
      'friteuse',
      'yaourtière',
      'machine à pain',
      'machine à pâtes',
      'sorbetière',
      'raclette',
      'grille pain',
      'crêpière',
      'gaufrier',
      'blender',
      'mixeur',
      'hachoir',
      'batteur',
      'cook expert',
      'companion',
      'robot artisan',
      'robot thermomix',
      'cookéo',
    ],
    categories: ['c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    type: 'appliance',
    id: 'caab47e0-9e08-43f1-a6ac-0bfce7386889',
    name: 'Préparation du café',
    imgsrc: '',
    categories: ['95bf8b15-0996-4782-a302-adaa29842c56', 'c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    type: 'appliance',
    id: '5acb8028-4a17-47c0-975f-ddb4a40fe773',
    name: 'Machine à soda',
    imgsrc: '',
    categories: ['95bf8b15-0996-4782-a302-adaa29842c56', 'c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    type: 'appliance',
    id: '25e1dd9f-4f80-4857-89cd-fad491237be4',
    name: 'Machine à glaçons',
    imgsrc: '',
    categories: ['95bf8b15-0996-4782-a302-adaa29842c56', 'c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    type: 'appliance',
    id: '7f1e0f10-b36d-4d45-bb95-8322137ad068',
    name: 'Tireuse à bière',
    imgsrc: '',
    categories: ['95bf8b15-0996-4782-a302-adaa29842c56', 'c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    type: 'appliance',
    id: 'd9421c9a-7ab6-446d-8eec-e01bb67e62af',
    name: 'Préparation du thé',
    imgsrc: '',
    categories: ['95bf8b15-0996-4782-a302-adaa29842c56', 'c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    type: 'appliance',
    id: 'd86311fa-099d-4f82-9bb0-7f526160169b',
    name: 'Autre appareil',
    imgsrc: '',
    categories: ['95bf8b15-0996-4782-a302-adaa29842c56', 'c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    type: 'appliance',
    id: '9562fb74-94a1-4eff-a632-b103baef6bc7',
    name: 'Soin du linge',
    imgsrc: '',
    categories: ['c167d3a0-c34a-4ad6-aac9-cacf4b8f11a0'],
  },
  {
    id: 'ceab213f-e6ae-4c1f-882e-5c837e0becbc',
    type: 'appliance',
    name: 'TV',
    imgsrc: '/icons/tunnel/tv.svg',
    search: ['télévision'],
    categories: ['516e1f84-5627-447a-8424-c356448cfab3'],
  },
  {
    id: '7de2805d-df98-48d0-9e4b-f28e56cb901f',
    type: 'appliance',
    name: 'Lecteur et/ou enregistreur vidéo',
    imgsrc: '/icons/tunnel/dvd.svg',
    search: ['lecteur DVD', 'enregistreur', 'graveur', 'magnétoscope', 'lecteur blueray'],
    categories: ['516e1f84-5627-447a-8424-c356448cfab3'],
  },
  {
    id: 'c314cece-7a77-4fdc-aab4-2d118a39bf05',
    type: 'appliance',
    name: 'Appareil audio',
    imgsrc: '/icons/tunnel/audio.svg',
    categories: ['516e1f84-5627-447a-8424-c356448cfab3'],
  },
  {
    type: 'appliance',
    id: '6d280077-d919-4f18-b446-428d9040c4fe',
    name: 'Autre appareil',
    imgsrc: '',
    categories: ['516e1f84-5627-447a-8424-c356448cfab3'],
  },
  {
    id: '0b41f197-acf5-4a14-9104-91131b79d100',
    type: 'appliance',
    name: "Ballon d'eau chaude",
    imgsrc: '/icons/tunnel/chauffe_eau.svg',
    search: ['chauffe eau'],
    categories: ['4de25aad-9147-465f-a5fb-4784e4f39820'],
  },
  {
    id: '36ba0ad4-be6a-4175-beab-9429fac5f321',
    type: 'appliance',
    name: 'Baignoire',
    imgsrc: '/icons/tunnel/baignoire.svg',
    categories: ['4de25aad-9147-465f-a5fb-4784e4f39820'],
  },
  {
    id: 'b7913516-14d6-4e66-8e1e-4a2aa3c240c9',
    type: 'appliance',
    name: 'Douche',
    imgsrc: '/icons/tunnel/douche.svg',
    categories: ['4de25aad-9147-465f-a5fb-4784e4f39820'],
  },
  {
    id: '925075eb-c4f7-43ec-bc30-3e76285c86d5',
    type: 'appliance',
    name: 'Evier ou lavabo',
    imgsrc: '/icons/tunnel/lavabo.svg',
    search: ['évier', 'lavabo', 'vasque'],
    categories: ['4de25aad-9147-465f-a5fb-4784e4f39820'],
  },
  {
    id: '2529ae8d-1ecd-4051-94b8-bb27c4735ec2',
    type: 'appliance',
    name: 'WC',
    imgsrc: '/icons/tunnel/wc.svg',
    search: ['toilettes'],
    categories: ['4de25aad-9147-465f-a5fb-4784e4f39820'],
  },
  {
    id: 'bd71be03-0280-4f31-9a5b-858395f09aba',
    type: 'appliance',
    name: 'Canalisation',
    imgsrc: '/icons/tunnel/plomberie.svg',
    categories: ['4de25aad-9147-465f-a5fb-4784e4f39820'],
  },
  {
    id: '958f6a3a-e49a-480e-afd4-544431dcc6c2',
    type: 'appliance',
    name: 'Téléphone',
    imgsrc: '/icons/tunnel/smartphone.svg',
    search: ['smartphone', 'iphone', 'téléphone mobile', 'téléphone portable'],
    categories: ['82c11348-7947-4547-af58-ea0667b173cd'],
  },
  {
    id: '1329a0c2-e11f-4cab-b934-d7d51527babb',
    type: 'appliance',
    name: 'Tablette',
    imgsrc: '/icons/tunnel/tablette.svg',
    categories: ['82c11348-7947-4547-af58-ea0667b173cd'],
  },
  {
    id: 'bd8b7abb-675a-4d4a-ac9c-33ed7229aa31',
    type: 'appliance',
    name: 'PC portable',
    imgsrc: '/icons/tunnel/laptop.svg',
    categories: ['2e8e77b3-4f50-490c-9460-37fcb2a78a3a', '82c11348-7947-4547-af58-ea0667b173cd'],
  },
  {
    id: '53722da8-59fb-4b28-b1cb-1c2d2ca9235d',
    type: 'appliance',
    name: 'PC de bureau',
    imgsrc: '/icons/tunnel/pc_bureau.svg',
    search: ['tour'],
    categories: ['2e8e77b3-4f50-490c-9460-37fcb2a78a3a', '82c11348-7947-4547-af58-ea0667b173cd'],
  },
  {
    id: '8f5c2c39-6593-4164-8f2b-3f4f0e3bd5df',
    type: 'appliance',
    name: 'Montre connectée',
    imgsrc: '/icons/tunnel/montre.svg',
    search: ['apple watch'],
    categories: ['82c11348-7947-4547-af58-ea0667b173cd'],
  },
  {
    id: 'fc2533ce-9699-4be3-9e87-187ac83be8b8',
    type: 'appliance',
    name: 'Imprimante/Scanner/Périphérique',
    imgsrc: '/icons/tunnel/imprimante.svg',
    categories: ['82c11348-7947-4547-af58-ea0667b173cd'],
  },
  {
    id: 'dfad7147-94a9-4a10-8598-8af5dde0d144',
    type: 'appliance',
    name: 'Lecteur MP3',
    imgsrc: '/icons/tunnel/mp3.svg',
    search: ['ipod'],
    categories: ['82c11348-7947-4547-af58-ea0667b173cd'],
  },
  {
    id: 'dfecd49d-b1e9-4932-88dc-0223c0a9c742',
    type: 'appliance',
    name: 'Console de jeux',
    imgsrc: '/icons/tunnel/console_jeux.svg',
    search: [
      'playstation',
      'xbox',
      'psp',
      'ps',
      'nintendo switch',
      'nintendo ds',
      'nintendo 2ds',
      'nintendo 3ds',
      'nintendo wii',
    ],
    categories: ['82c11348-7947-4547-af58-ea0667b173cd'],
  },
  {
    id: '5da7e724-44ac-4ac4-bd61-16458575ca47',
    type: 'appliance',
    name: 'Antenne hertzienne',
    imgsrc: '',
    categories: ['40c32fa6-7144-4acf-a1a5-a1cbeb51fdae'],
  },
  {
    id: '248aab36-2a94-4f8f-b487-11ea3128ceba',
    type: 'appliance',
    name: 'Antenne satellite',
    imgsrc: '',
    categories: ['40c32fa6-7144-4acf-a1a5-a1cbeb51fdae'],
  },
  {
    id: 'c547dc8b-3681-4287-8493-5774f1d477cc',
    type: 'appliance',
    name: 'Fibre',
    imgsrc: '',
    categories: ['b17f3617-d96b-4a4a-adee-7f7f2246a52a', '40c32fa6-7144-4acf-a1a5-a1cbeb51fdae'],
  },
  {
    id: 'b0fded4b-369c-420e-bc53-3e54eb726982',
    type: 'appliance',
    name: 'ADSL',
    imgsrc: '',
    categories: ['b17f3617-d96b-4a4a-adee-7f7f2246a52a', '40c32fa6-7144-4acf-a1a5-a1cbeb51fdae'],
  },
  {
    id: 'fb22b3db-ae06-4946-b04d-9d8aa999cad3',
    type: 'appliance',
    name: 'Internet par satellite',
    imgsrc: '',
    categories: ['b17f3617-d96b-4a4a-adee-7f7f2246a52a', '40c32fa6-7144-4acf-a1a5-a1cbeb51fdae'],
  },
  {
    id: '757cec5e-67b0-4aa7-ad7f-21d0ba78141e',
    type: 'appliance',
    name: 'Autre',
    imgsrc: '',
    categories: ['b17f3617-d96b-4a4a-adee-7f7f2246a52a', '40c32fa6-7144-4acf-a1a5-a1cbeb51fdae'],
  },
  {
    id: '7403847f-0601-4b02-a94f-f738951be8b8',
    type: 'appliance',
    name: 'Performance énergétique',
    imgsrc: '/icons/tunnel/diag_eneergie.svg',
    categories: ['ca33e7ac-b447-4806-870f-5e336428d250'],
  },
  {
    id: '80e77932-c6d5-4749-ab23-917f0c1a0a9d',
    type: 'appliance',
    name: 'Loi Carrez/Loi Boutin',
    imgsrc: '/icons/tunnel/diagnostic.svg',
    categories: ['ca33e7ac-b447-4806-870f-5e336428d250'],
  },
  {
    id: 'e04961dc-d89b-4785-b825-f3bb41798d12',
    type: 'appliance',
    name: 'Amiante',
    imgsrc: '/icons/tunnel/amiante.svg',
    categories: ['ca33e7ac-b447-4806-870f-5e336428d250'],
  },
  {
    id: 'd58e7147-8a4b-4e2f-896e-2cdb528daa7c',
    type: 'appliance',
    name: 'Gaz',
    imgsrc: '/icons/tunnel/gaz.svg',
    categories: ['ca33e7ac-b447-4806-870f-5e336428d250'],
  },
  {
    id: '018ab954-6cc1-4d85-850a-c79594b8e465',
    type: 'appliance',
    name: 'Electricité',
    imgsrc: '/icons/tunnel/electricite.svg',
    categories: ['ca33e7ac-b447-4806-870f-5e336428d250'],
  },
  {
    id: 'fe52d3fb-2ed8-4db9-977f-604387c50087',
    type: 'appliance',
    name: 'Plomb',
    imgsrc: '',
    categories: ['ca33e7ac-b447-4806-870f-5e336428d250'],
  },
  {
    id: 'e2c1496c-960d-4477-81c4-e836f3dd44a7',
    type: 'appliance',
    name: 'Mérules',
    imgsrc: '/icons/tunnel/merule.svg',
    categories: ['ca33e7ac-b447-4806-870f-5e336428d250'],
  },
  {
    id: 'abaf8cd7-581d-4d54-9b1a-c4c5b4a5034f',
    type: 'appliance',
    name: 'ERP',
    imgsrc: '/icons/tunnel/erp.svg',
    categories: ['ca33e7ac-b447-4806-870f-5e336428d250'],
  },
]

function familyTypeImg(search) {
  const app = appliancesKind.find((a) => a.id === search || a.name === search)
  if (!app) {
    return { id: '-1', name: 'Inconnu', imgsrc: '' }
  }

  if (!app.imgsrc || app.imgsrc === '') {
    for (const catId of app.categories) {
      const cat = familyType.find((f) => f.id === catId)
      if (cat && cat.imgsrc && cat.imgsrc !== '') {
        return cat
      }
    }
  }

  return app || { id: '-1', name: 'Inconnu', imgsrc: '' }
}
const SkillsList = familyType

function mainCategory(app) {
  if (!app) return null
  const id = app.id || app
  const kind = appliancesKind.find((a) => a.id === id)
  return kind && kind.categories.find((cid) => familyType.find((f) => f.id === cid).type === 'category')
}

function userInitial(user) {
  if (!user) return null
  const asFirstname = user.firstname !== ''
  const asLastname = user.lastname !== ''
  if (asFirstname && asLastname) {
    return user.firstname.charAt(0).toLocaleUpperCase() + user.lastname.charAt(0).toLocaleUpperCase()
  } else if (asFirstname) {
    return user.firstname.charAt(0).toLocaleUpperCase() + user.firstname.charAt(1).toLocaleLowerCase()
  } else if (asLastname) {
    return user.lastname.charAt(0).toLocaleUpperCase() + user.lastname.charAt(1).toLocaleLowerCase()
  }
  return null
}

export {
  repairStatus,
  quoteStatus,
  quoteStatusPublic,
  invoiceStatus,
  rdvType,
  appliancesKind,
  familyType,
  familyTypeImg,
  mainCategory,
  userInitial,
  SkillsList,
  repairStatusFromTypes,
}
