<template>
  <v-menu offset-y :close-on-content-click="false" bottom>
    <template #activator="{ on }">
      <v-btn icon dark :disabled="!hasFile" v-on="on">
        <v-icon v-if="!hasFile"> mdi-upload </v-icon>
        <v-progress-circular v-else :value="totalProgress / totalSize">
          <v-scroll-y-transition>
            <span :data="waitingFiles.length">{{ waitingFiles.length }}</span>
          </v-scroll-y-transition>
        </v-progress-circular>
      </v-btn>
    </template>

    <v-card width="500">
      <v-list dense>
        <v-scroll-x-transition group>
          <v-list-item v-for="file in waitingFiles" :key="file.id">
            <v-list-item-avatar tile>
              <v-img v-if="isImg(file.mimetype)" :src="file.cachedURL" />
              <v-icon v-else x-large>mdi-file</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-wrap"> {{ file.filename }} </v-list-item-title>
              <v-list-item-subtitle>
                {{ Math.round((file.progress * file.sizeTotal) / 100) }} / {{ file.sizeTotal }}
              </v-list-item-subtitle>
              <v-progress-linear
                :color="file.success ? 'green' : 'primary'"
                :value="file.progress"
                stream
                buffer-value="0"
              />
            </v-list-item-content>
            <v-list-item-avatar tile>
              <v-icon v-if="file.success" color="success"> mdi-check </v-icon>
              <v-icon v-if="file.fail" color="error"> mdi-close </v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-scroll-x-transition>

        <v-list-item v-if="!hasFile"> Aucun fichier </v-list-item>
        <!-- <v-btn plain small color="primary" @click="removeAllFinished"> enlever les terminé </v-btn> -->
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {}
  },

  computed: {
    waitingFiles() {
      return this.$store.state.deferedupload.waitingFiles
    },

    hasFile() {
      return this.waitingFiles && this.waitingFiles.length > 0
    },

    totalSize() {
      return this.waitingFiles?.reduce((sum, file) => sum + file.sizeTotal, 0) || 0
    },

    totalProgress() {
      return this.waitingFiles?.reduce((sum, file) => sum + file.progress * file.sizeTotal, 0) || 0
    },
  },

  methods: {
    imgFromFile(id) {
      return localStorage.getItem(id)
    },

    isImg(mimetype /* String */) {
      return mimetype?.startsWith('image')
    },

    removeAllFinished() {
      this.$store.commit('deferedupload/cleanAllFinished')
    },
  },
}
</script>
