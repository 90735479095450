// import { VuexPersistence } from 'vuex-persist'

// export const plugins = [ VuexPersistence ]
import { v4 as uuidv4 } from 'uuid'

import { ActionTree } from 'vuex'

import Vue from 'vue'

interface FileState {
  addedAt: Date
  filename: string
  mimetype: string
  loading: boolean
  failed: boolean
  success: boolean
  progress: number
  sizeUploaded: number
  sizeTotal: number
  url: string
  thumbnail?: string | null
  cachedURL?: string | null
  id: string
  tags: string[]
  recipientType: string
  recipientID: string
  req: XMLHttpRequest
}

export const state = () => ({
  waitingFiles: [] as FileState[],
})

export type uploadedState = ReturnType<typeof state>

interface pushOption {
  id: string
  filename: string
  mimetype: string
  size: number
  data: string
  file: Blob
  tags: string[]
  recipientType: string
  recipientID: string
  onSuccess(file: FileState | undefined): void
  req: XMLHttpRequest
}

interface ProgressOption {
  id: string
  progress: number
}

export const mutations = {
  push(state: uploadedState, option: pushOption) {
    console.log('push', option)
    const newFile = {
      addedAt: new Date(),
      endAt: 0,
      id: option.id,
      filename: option.filename,
      mimetype: option.mimetype,
      size: option.size,
      loading: true,
      failed: false,
      success: false,
      progress: 0,
      sizeUploaded: 0,
      sizeTotal: option.size,
      url: '',
      thumbnail: null,
      tags: option.tags,
      recipientType: option.recipientType,
      recipientID: option.recipientID,
      onSuccess: option.onSuccess,
      req: option.req,
    }
    state.waitingFiles.push(newFile)

    // localStorage.setItem(option.id, option.data)
  },

  updateCachedURL(state: uploadedState, { id, cachedURL }: { id: String; cachedURL: string }) {
    const i = state.waitingFiles.findIndex((f) => f.id === id)
    Vue.set(state.waitingFiles, i, { ...state.waitingFiles[i], cachedURL })
  },

  progress(state: uploadedState, { id, progress }: ProgressOption) {
    const i = state.waitingFiles.findIndex((f) => f.id === id)
    Vue.set(state.waitingFiles, i, { ...state.waitingFiles[i], progress })
  },

  loaded(state: uploadedState, { id, urls }: { id: String; urls: String[] }) {
    if (urls?.length < 1) return
    const i = state.waitingFiles.findIndex((f) => f.id === id)
    Vue.set(state.waitingFiles, i, {
      ...state.waitingFiles[i],
      url: urls[0],
      thumbnail: urls[1] || null,
      loading: false,
      success: true,
      failed: false,
      progress: 100,
    })
    // if (i >= 0) {
    //   state.waitingFiles = state.waitingFiles.splice(i, 1)
    // }
  },

  abord(state: uploadedState, { id }: any) {
    const i = state.waitingFiles.findIndex((f) => f.id === id)
    state.waitingFiles[i].req.abort()
    Vue.set(state.waitingFiles, i, {
      ...state.waitingFiles[i],
      loading: false,
      success: true,
      failed: false,
      progress: 100,
    })
  },
  clean(state: uploadedState, { id }: any) {
    const i = state.waitingFiles.findIndex((f) => f.id === id)
    Vue.delete(state.waitingFiles, i)
    caches.open('deferedupload').then((cache) => {
      cache.delete('/_deferedupload/' + id)
    })
  },
}

export const actions: ActionTree<uploadedState, uploadedState> = {
  uploadRepairFile({ state, commit }, option: pushOption) {
    const id = 'deferedupload.' + option.recipientType + '.' + option.recipientID + '.' + uuidv4()
    option.id = id

    // localStorage.setItem(id, option.data)

    caches
      .open('deferedupload')
      .then((cache) => {
        const cachedURL = '/_deferedupload/' + option.id
        cache
          .put(
            cachedURL,
            new Response(option.file, {
              status: 200,
              statusText: 'OK',
              headers: [
                ['Content-Type', option.mimetype],
                ['Content-Length', option.size.toString()],
              ],
            })
          )
          .then(() => {
            commit('updateCachedURL', { id: option.id, cachedURL })
          })
          .catch((err) => console.log('deferedupload.uploadRepairFile open cache', err))
      })
      .catch((err) => console.log('deferedupload.uploadRepairFile put cache', err))
    let req = new XMLHttpRequest()
    option.req = req

    commit('push', option)

    req.open('PUT', '/api/v1/upload/quote', true)
    req.upload.addEventListener(
      'progress',
      (ev) => {
        if (ev.lengthComputable) {
          commit('progress', { id: option.id, progress: Math.round((ev.loaded / ev.total) * 100) })
        }
      },
      false
    )
    req.addEventListener('load', (ev) => {
      console.log('uploadRepairFile.load', ev)

      const res = JSON.parse(req.responseText)
      console.log('uploadRepairFile.load.status', req.status)
      console.log('uploadRepairFile.load.res', res)
      commit('loaded', { id, urls: res.uploaded })
      if (option.onSuccess) {
        option.onSuccess(state.waitingFiles.find((f) => f.id === id))
      }
      commit('clean', { id })
    })

    const fd = new FormData()
    fd.append('files', option.file)
    // fd.append('files', dataURItoBlob(option.data))
    req.send(fd)
  },
}

function dataURItoBlob(dataURI: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}
