import * as Sentry from '@sentry/browser'

function setSentryUser(session) {
  Sentry.getCurrentHub().setExtra('commit', process.env.commitId)
  Sentry.getCurrentHub().setExtra('build_time', process.env.buildTime)
  if (!(session && session.me)) {
    Sentry.getCurrentHub().setUser(null)
    return
  }
  Sentry.getCurrentHub().setUser({
    id: session.me.id,
    username: session.me.firstname + ' ' + session.me.lastname,
    shop_id: session.me.shop_id,
    session_id: session.token,
  })
}

export const state = () => {
  const s = {
    isStorageBlocked: true,
    authSession: null,
    selectedCompany: null,
  }
  try {
    s.authSession = JSON.parse(localStorage.getItem('aposio_session'))
    s.isStorageBlocked = false
    setSentryUser(s.authSession)
  } catch (e) {
    s.authSession = null
    s.isStorageBlocked = true
  }
  return s
}

export const getters = {
  isAuthenticated: (state) => {
    if (state && state.authSession) return state.authSession.validated
    return false
  },
  isAdmin: (state) => {
    return state.authSession?.me?.role === 'ADMIN'
  },

  selectedCompanyID(state) {
    return state.selectedCompany?.id || state.authSession?.me?.shop_id
  },
}

export const mutations = {
  set_authSession(state, session) {
    session.lastChecked = Date.now()
    localStorage.setItem('aposio_session', JSON.stringify(session))
    state.authSession = session
    setSentryUser(session)
  },
  checkAuth(state) {
    try {
      state.authSession = JSON.parse(localStorage.getItem('aposio_session'))
    } catch (e) {
      state.authSession = null
    }
  },
  logout(state) {
    state.authSession = null
    localStorage.removeItem('aposio_session')
    setSentryUser(null)
  },

  changeSelectedCompany(state, company) {
    state.selectedCompany = company
  },
}

export const actions = {
  async validateAuth({ commit }) {
    console.log('validate Auth')
    const res = await fetch('/api/v1/auth/check', { credentials: 'include' })
    if (res.status === 401 || res.status === 404) {
      console.log('auth logout', res.statusText)
      commit('logout')
    } else if (res.status === 200) {
      const session = await res.json()
      console.log('auth valid')
      commit('set_authSession', session)
    } else {
      console.log('store validation auth', res.statusText)
    }
  },

  async logout({ commit }) {
    const res = await fetch('/api/v1/auth/logout', { credentials: 'include' })
    console.log('logout', res.statusText)
    commit('logout')
  },
}
