import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Touch } from 'vuetify/lib/directives'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  directives: {
    Touch,
  },
})

export default new Vuetify({
  theme: {
    dark: false,
    light: true,
    themes: {
      light: {
        // primary: '#820da3',
        primary: {
          base: '#870da3',
          // lighten5: '#820da320',
          lighten5: '#fff7ff',
          // lighten5: '#f4e5f5',
          lighten4: '#e4bde7',
          lighten3: '#d390d8',
          lighten2: '#c063c8',
          lighten1: '#a415b0',
          darken1: '#71099c',
          darken2: '#51028f',
        },
        secondary: '#FF2b42',
        ternary: '#fed13c',
        accent: colors.grey.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
      },
    },
  },
  icons: {
    iconfont: 'md', // default - only for display purposes
  },
})
