<template>
  <v-autocomplete
    v-bind="$attrs"
    :value="value"
    :loading="address_loading"
    :items="address_autocomplete"
    :search-input.sync="address_search"
    no-filter
    no-data-text="Tapez votre adresse complète"
    :item-text="addressToText"
    return-object
    label="Recherche d'adresse"
    append-icon="mdi-crosshairs-gps"
    v-on="$listeners"
    @click:append="getUserLocation"
  >
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash-es/debounce'

export default {
  props: {
    value: {
      type: Object,
      default() {
        return null
      },
    },
    locality: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      repair_shop: {},
      address_autocomplete: [],
      address_loading: false,
      address_search: '',
      geolocAddress: {},
      geoloc: {},
    }
  },
  watch: {
    address_search(val) {
      const tval = val?.trim()
      if (!tval || tval.length <= 4) {
        this.address_autocomplete = []
        return
      }
      tval !== this.value && this.api_address(tval)
    },
  },
  created() {
    this.api_address = debounce(this.api_address_once, 400)
  },
  methods: {
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.geoloc = { lon: position.coords.longitude, lat: position.coords.latitude }
            const url =
              'https://api-adresse.data.gouv.fr/reverse/?lon=' +
              this.geoloc.lon +
              '&lat=' +
              this.geoloc.lat +
              '&limit=5'
            fetch(url)
              .then((res) => res.json())
              .then((res) => {
                console.log('address data gouv reverse for', this.geoloc, res)
                this.geolocAddress = res.features
                this.address_autocomplete = this.geolocAddress.map(this.apiToAddress)
              })
          },
          (error) => {
            console.log(error.message)
          }
        )
      } else {
        alert('Votre navigateur ne supporte par la géolocalisation')
      }
    },

    api_address_once(query) {
      console.log('address search q="' + query + '"')
      this.address_loading = true
      const url =
        'https://api-adresse.data.gouv.fr/search/?type=housenumber&autocomplete=1&q=' +
        encodeURI(query) +
        (this.locality?.lat ? '&lat=' + encodeURIComponent(this.locality.lat) : '') +
        (this.locality?.lon ? '&lon=' + encodeURIComponent(this.locality.lon) : '')
      fetch(url)
        .then((res) => res.json())
        .then((res) => {
          this.address_loading = false
          this.address_autocomplete = res.features.map(this.apiToAddress)
        })
        .catch((e) => {
          console.log('Error', e)
          if (e instanceof Response) {
            console.log('Error api-adresse.data.gouv.fr:', e.status, e.statusText)
          }
        })
    },

    apiToAddress(address) {
      return {
        street: address?.properties?.name || '',
        complement: '',
        zip: address?.properties?.postcode || '',
        city: address?.properties?.city || '',
        country: 'France',
        lat: address?.geometry?.coordinates[1] || null,
        lon: address?.geometry?.coordinates[0] || null,
      }
    },
    addressToText(address) {
      if (!address) return ''
      return address.street + ' ' + address.zip + ' ' + address.city
    },
  },
}
</script>
