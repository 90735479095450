<template>
  <v-card v-bind="$attrs">
    <v-card-text>
      <h2 v-if="isNew" class="mb-3">Nouveau·elle client·e</h2>
      <h2 v-else class="mb-3">Modifier la fiche client</h2>
      <v-form>
        <v-btn-toggle v-model="client.type" dense mandatory color="primary">
          <v-btn value="Particulier"><v-icon left>mdi-account</v-icon> Particulier</v-btn>
          <v-btn value="Entreprise"><v-icon left>mdi-office-building</v-icon> Entreprise</v-btn>
        </v-btn-toggle>
        <div class="mt-6">
          <v-text-field :value="client.lastname" required dense label="Nom" @input="updateLastname">
            <template #append>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-simple-checkbox
                    v-model="formatLastname"
                    on-icon="mdi-format-letter-case"
                    off-icon="mdi-format-letter-case"
                    :color="formatLastname ? 'primary' : ''"
                    v-bind="attrs"
                    v-on="on"
                    @click="$event && updateLastname(client.lastname)"
                  />
                </template>
                <span>
                  {{ formatLastname ? 'Désactivé' : 'Activer' }} le formattage automatique du Nom en majuscule
                </span>
              </v-tooltip>
            </template>
          </v-text-field>

          <v-text-field :value="client.firstname" dense label="Prénom" @input="updateFirstname">
            <template #append>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-simple-checkbox
                    v-model="formatFirstname"
                    on-icon="mdi-format-letter-case"
                    off-icon="mdi-format-letter-case"
                    :color="formatFirstname ? 'primary' : ''"
                    v-bind="attrs"
                    v-on="on"
                    @click="$event && updateFirstname(client.firstname)"
                  />
                </template>
                <span>
                  {{ formatFirstname ? 'Désactivé' : 'Activer' }} le formattage automatique du Prénom avec majuscule à
                  la première lettre
                </span>
              </v-tooltip>
            </template>
          </v-text-field>

          <v-divider class="mb-4" />
          <div>
            <div v-for="(phone, iphone) in client.phones" :key="iphone" class="d-flex flex-row align-baseline">
              <v-combobox
                v-model="phone.name"
                dense
                class="mr-2"
                label="Type"
                style="max-width: 10rem"
                :items="defaultPhoneType"
              >
              </v-combobox>
              <VueTelInputVuetify
                v-model="phone.number"
                mode="international"
                :preferred-countries="['FR', 'BE', 'LU', 'CH']"
                dense
                label="Numéro"
                type="tel"
                class="flex-grow-1"
              >
              </VueTelInputVuetify>
              <v-btn color="grey" icon @click="removePhoneNumber(phone, iphone)">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </div>
            <div class="d-flex flex-row justify-center">
              <v-btn class="" text color="primary" @click="addPhoneNumber">
                <v-icon left>mdi-phone-plus</v-icon> Ajouter un numéro
              </v-btn>
            </div>
          </div>

          <v-text-field
            v-model.trim="client.email"
            dense
            label="Email"
            type="email"
            :loading="is_validating_email"
            :messages="(is_validating_email && 'validation en cours') || null"
            :success-messages="(valid_email === true && `L'email semble valide`) || []"
            :error-messages="(valid_email === false && `L'email ne semble pas valide : ${valid_email_msg}`) || ''"
          >
            <template #append>
              <v-tooltip top open-on-click>
                <template #activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>
                  Veuillez bien vérifier l'email, l'indicateur de validité des emails ne peut être fiable à 100%.
                </span>
              </v-tooltip>
            </template>
          </v-text-field>

          <v-textarea
            v-model="client.note"
            label="Information complémentaire sur le client"
            auto-grow
            rows="2"
          ></v-textarea>
        </div>
        <v-divider class="my-4" />

        <h2>Adresses</h2>
        <v-list>
          <v-list-item v-for="(address, i) in client.addresses" :key="i">
            <v-list-item-content>
              <v-chip-group v-model="address.tags" multiple color="primary" class="text-capitalize">
                <v-chip v-for="(tag, itag) in Taglist" :key="itag" small outlined :value="tag">
                  <v-icon v-if="tag === 'facturation'" small left>mdi-file</v-icon>
                  <v-icon v-if="tag !== 'facturation'" small left>mdi-truck</v-icon>
                  {{ tag }}
                </v-chip>
              </v-chip-group>
              <div>{{ address.street }}</div>
              <div>{{ address.complement }}</div>
              <div>{{ address.zip }} {{ address.city }} {{ address.country }}</div>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="UpdatingAddress(address, i)"> <v-icon> mdi-pencil </v-icon> </v-btn>
              <v-btn icon @click="removeAddress(address, i)">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="justify-center">
            <v-btn class="" outlined color="primary" @click="SetNewAddress">
              <v-icon left>mdi-plus</v-icon> Ajouter une adresse
            </v-btn>
          </v-list-item>
        </v-list>

        <v-dialog v-model="ShownUpsertDialog" max-width="600">
          <v-card>
            <v-card-title>
              {{ AddressToUpdate && AddressToUpdate.address_id ? 'Adresse à changer' : 'Nouvelle adresse' }}
            </v-card-title>
            <v-card-text>
              <UpsertClientAddress
                :value="AddressToUpdate"
                @finish="changeAddress"
                @cancel="ShownUpsertDialog = false"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-divider class="my-4" />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn text @click="$emit('cancel')">Annuler</v-btn>
      <v-spacer />
      <v-btn color="primary" :loading="$apollo.loading" :disabled="hasChanged" @click="upsertClient">
        {{ isNew ? 'Ajouter ce nouveau client' : 'Enregistrer les modifications' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { gql } from 'graphql-tag'
import isEqual from 'lodash-es/isEqual'
import cloneDeep from 'lodash-es/cloneDeep'
// import { VueTelInputVuetify } from 'vue-tel-input-vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify'
import UpsertClientAddress from '~/components/UpsertClientAddress'
import omitDeep from '~/api/omitDeep'

export default {
  components: {
    UpsertClientAddress,
    VueTelInputVuetify,
  },
  props: {
    lastname: {
      type: String && null,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    clientId: {
      type: String && null,
      default: null,
    },
  },
  data() {
    return {
      formatFirstname: true,
      formatLastname: true,

      is_validating_email: false,
      valid_email: null,

      ShownUpsertDialog: false,
      AddressToUpdate: null,
      AddressToUpdateIndex: null,
      type: ['Particulier', 'Entreprise'],
      Taglist: ['facturation', 'intervention'],
      defaultPhoneType: ['Portable', 'Fix', 'Fax'],

      initialClient: null,
      client: {
        id: null,
        type: 'Particulier',
        lastname: '',
        firstname: '',
        phones: [],
        email: '',
        addresses: [],
        note: '',
        ...cloneDeep(this.value),
      },

      addressesCRUD: [],
    }
  },
  computed: {
    hasChanged() {
      return isEqual(this.initialClient, this.client)
    },
    isNew() {
      return !this.clientId && !this.client?.id
    },

    lenphones() {
      return this.client?.phones?.length || -1
    },
  },
  mounted() {
    this.updateLastname(this.lastname)
  },
  // watch: {
  // $props: {
  //   deep: true,
  //   handler(p) {
  //     this.client = cloneDeep(p) || {
  //       id: null,
  //       type: 'Particulier',
  //       lastname: '',
  //       firstname: '',
  //       phones: [],
  //       email: '',
  //       addresses: [],
  //       note: '',
  //     }
  //   },
  // },
  // client: {
  //   deep: true,
  //   handler(c, old) {
  //     this.$emit('input', c)
  //   },
  // },
  // },
  methods: {
    toTitleCase(str, locale = navigator.language) {
      return str.replace(/\w[^-\s]*/g, function (txt) {
        return txt.charAt(0).toLocaleUpperCase() + txt.substr(1).toLocaleLowerCase()
      })
    },

    updateFirstname(name) {
      if (!name) name = ''
      name = name.trim()
      this.client.firstname = this.formatFirstname ? this.toTitleCase(name) : name
    },
    updateLastname(name) {
      if (!name) name = ''
      name = name.trim()
      this.client.lastname = this.formatLastname ? name.toLocaleUpperCase() : name
      return this.client.lastname
    },

    async checkEmail() {
      const email = this.client.email
      console.log('check email', email)

      if (email === '') return

      this.is_validating_email = true
      this.valid_email = null
      this.valid_email_msg = ''
      const res = await fetch('https://ssfy.sh/amaurymartiny/reacher@0d919999/check_email', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        referrer: '',
        body: JSON.stringify({
          to_email: email,
        }),
      }).then((r) => r.json())
      console.log(res)
      this.is_validating_email = false
      switch (res.is_reachable) {
        case 'safe':
          this.valid_email = true
          this.valid_email_msg = 'ok'
          break
        case 'risky':
          this.valid_email = false
          this.valid_email_msg = 'risqué'
          break
        case 'unknown':
          this.valid_email = false
          this.valid_email_msg = 'inconue'
          break
        default:
          this.valid_email = false
          this.valid_email_msg = ''
      }
    },

    async upsertClient() {
      const client = omitDeep(this.client, '__typename')
      delete client.search
      delete client.created_at
      client.shop_id = this.$store.getters.selectedCompanyID

      console.log('upsertclient', JSON.stringify(client))

      await this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: ClientInput!) {
              upsertClient(input: $input) {
                created_at
                id
                type
                lastname
                firstname
                phones {
                  name
                  number
                }
                email
                shop_id
                addresses {
                  address_id
                  tags
                  street
                  complement
                  zip
                  city
                  country
                  address_id
                  tags
                  lat
                  lon
                }
                note
              }
            }
          `,
          // Parameters
          variables: {
            input: client,
          },
        })
        .then((res) => {
          // Result
          console.log(res)
          this.selectedClientID = res?.data?.upsertClient?.id
          this.client = res?.data?.upsertClient
          this.refetch()
          this.refresh()
        })
        .catch((error) => {
          this.$sentry.captureException(error, { contexts: { client, error } })
          // Error
          console.log(error)
        })

      // update address if change the flag
      if (!this.isNew && this.client?.addresses && this.initialClient?.addresses) {
        this.client.addresses
          .filter((address, i) => !isEqual(address, this.initialClient.addresses[i]))
          .forEach(this.changeAddress)
      }
      // add new addresses
      if (!this.isNew && this.client?.addresses) {
        this.client.addresses
          .filter((address) => !address.address_id)
          .forEach((address) => this.InsertClientAddress(this.selectedClientID, address))
      }
      // Apply CRUD addresses
      if (!this.isNew) {
        this.addressesCRUD.forEach((action) => {
          switch (action.type) {
            case 'update':
              this.UpdateClientAddress(action.address)
              break
            case 'remove':
              this.removeClientAddress(action.address)
              break
          }
        })
      }

      this.$emit('input', this.client)
    },

    changeAddress(AddressInput) {
      const clientID = this.client?.id
      const isNewAddress = this.AddressToUpdateIndex === null

      console.log('upsertclient', clientID, isNewAddress, AddressInput)

      this.AddressToUpdate = AddressInput
      this.ShownUpsertDialog = false

      if (!isNewAddress) {
        // modify existing address
        this.$set(this.client.addresses, this.AddressToUpdateIndex, AddressInput)
      } else {
        // insert a new address
        this.$set(this.client.addresses, this.client.addresses?.length || 0, AddressInput)
      }

      if (AddressInput?.address_id) {
        // update an already saved address
        this.addressesCRUD.push({ type: 'update', address: AddressInput })
      }
    },

    removeAddress(address, index) {
      const clientID = this.client?.id
      // new client
      if (!clientID) {
        this.$delete(this.client.addresses, index)
        return
      }
      if (address.address_id) {
        this.addressesCRUD.push({ type: 'remove', address })
      }
      this.$delete(this.client.addresses, index)
      // this.removeClientAddress(address)
    },

    removeClientAddress(address) {
      const clientID = this.client?.id
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($clientID: ID!, $addressID: ID!) {
              removeClientAddress(clientID: $clientID, address_id: $addressID)
            }
          `,
          // Parameters
          variables: {
            clientID,
            addressID: address.address_id,
          },
        })
        .then((res) => {
          console.log(res)
          this.refetch()
        })
        .catch((e) => {
          this.$sentry.captureException(e)
          console.error(e)
        })
    },

    async InsertClientAddress(clientID, AddressInput) {
      await this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: AddressInput!, $clientID: ID!) {
              insertClientAddress(input: $input, clientID: $clientID) {
                address_id
                tags
                street
                complement
                zip
                city
                country
                tags
                lat
                lon
              }
            }
          `,
          // Parameters
          variables: {
            input: AddressInput,
            clientID,
          },
        })
        .then((res) => {
          console.log(res)
          this.refetch()
        })
        .catch((error) => {
          this.$sentry.captureException(error)
          // Error
          console.log(error)
        })
    },

    async UpdateClientAddress(Address) {
      console.log('updateclient', Address)
      // To avoid having a _typename
      Vue.delete(Address, '__typename')
      console.log('AddressMutate', Address)
      console.log('AddressMutate', 2)

      await this.$apollo

        .mutate({
          // Query
          mutation: gql`
            mutation ($input: AddressInput!) {
              updateClientAddress(input: $input) {
                address_id
                tags
                street
                complement
                zip
                city
                country
                tags
                lat
                lon
              }
            }
          `,
          // Parameters
          variables: {
            input: Address,
          },
        })
        .then((res) => {
          console.log(res)
          this.refetch()
        })
        .catch((error) => {
          this.$sentry.captureException(error)
          // Error
          console.log(error)
        })
    },

    SetNewAddress() {
      this.ShownUpsertDialog = true
      this.AddressToUpdate = null
      this.AddressToUpdateIndex = null
    },
    UpdatingAddress(addresse, index) {
      this.AddressToUpdate = addresse
      this.AddressToUpdateIndex = index
      this.ShownUpsertDialog = true
    },
    refresh() {
      this.ShownUpsertDialog = false
      this.AddressToUpdate = null
      this.AddressToUpdateIndex = null
      this.$emit('refresh', this.client)
    },

    refetch() {
      this.$apollo.queries.client.refetch()
    },

    addPhoneNumber() {
      const defaultPhone = { name: '', number: '' }
      if (!this.client.phones) {
        this.client.phones = [defaultPhone]
      } else {
        // this.$set(this.client.phones, this.client?.phones?.length || 0, defaultPhone)
        this.client.phones = [...this.client.phones, defaultPhone]
      }
      console.log('add ', this.client)
    },

    removePhoneNumber(phone, index) {
      if (this.client?.phones) {
        console.log('delete', phone, index)
        this.$delete(this.client.phones, index)
      }
    },
  },

  apollo: {
    client: {
      query: gql`
        query Client($client_id: ID!) {
          client(id: $client_id) {
            created_at
            id
            type
            lastname
            firstname
            phones {
              name
              number
            }
            email
            shop_id
            addresses {
              address_id
              tags
              street
              complement
              zip
              city
              country
              tags
              lat
              lon
            }
            note
          }
        }
      `,
      variables() {
        return {
          client_id: this.clientId,
          shop_id: this.$store.getters.selectedCompanyID,
        }
      },
      skip() {
        return !this.$store.state.authSession || !this.clientId
      },
      result({ data, loading }) {
        if (data) {
          this.initialClient = cloneDeep(data.client)
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  },
}
</script>
