const AGENDA_PARAM_KEY = 'aposio_agenda_param'

export const state = (_) => {
  const defaultparam = {
    type: '4day',
    max_days: 4,
    len_4day: 4,
    len_week: 1,
    show_ranges: true,
    interval_scale: 60,
    interval_step: 60,
    fit_screen: false,
    showFluidEvents: false,

    usersIDSelected: [],
  }
  try {
    const s = JSON.parse(localStorage.getItem(AGENDA_PARAM_KEY))
    return { params: { ...defaultparam, ...s?.params } }
  } catch (e) {
    console.log('error vuex agenda', e)
  }
  return { params: defaultparam }
}

export const getters = {
  max_days: (state) => {
    switch (state.type) {
      case 'day':
        return 1
      case '4day':
        return state.len_4day
      case 'week':
        return state.len_week * 7
      default:
        return 7
    }
  },
}

export const mutations = {
  zoom(state, zoom) {
    state.params = { ...state.params, interval_scale: zoom }
    try {
      localStorage.setItem(AGENDA_PARAM_KEY, JSON.stringify(state))
    } catch (e) {
      console.log(e)
    }
  },
  updateParams(state, p) {
    state.params = { ...state.params, ...p }
    try {
      localStorage.setItem(AGENDA_PARAM_KEY, JSON.stringify(state))
    } catch (e) {
      console.log(e)
    }
  },
}

export const actions = {
  zoom({ state, commit }, zoom) {
    commit('zoom', zoom)
  },
  updateParams({ state, commit }, params) {
    commit('updateParams', params)
  },
}
