if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const worker of registrations) {
      worker.addEventListener('controlling', (event) => {
        // window.location.reload()
        console.log('sw.controlling', event)
      })
      worker.addEventListener('message', (event) => {
        console.log('sw.message', event)
      })
      worker.addEventListener('updatefound', (event) => {
        console.log('sw.updatefound', event)
      })
      worker.addEventListener('updatefound', (event) => {
        console.log('sw.updatefound', event)
        const newWorker = worker.installing

        // On se branche à ses mises à jour pour savoir quand il a fini de s'installer
        newWorker.addEventListener('statechange', () => {
          console.log('sw.updatefound.statechange', newWorker.state)
          if (newWorker.state === 'installed') {
            // Un nouveau ServiceWorker est prêt. Donc on affiche la notification
          }
        })
      })
    }
  })
}
