<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      color="#333333"
      touchless
      :mini-variant="$vuetify.breakpoint.smAndUp && logged && !navbarExpended"
      :permanent="$vuetify.breakpoint.smAndUp && logged"
      clipped
      :right="$vuetify.breakpoint.xsOnly"
      mobile-breakpoint="720"
      :disable-resize-watcher="!logged"
      class="d-print-none"
    >
      <v-list flat>
        <v-list-item v-if="!$vuetify.breakpoint.smAndUp" key="menu_close">
          <v-list-item-content><h3>Menu</h3></v-list-item-content>
          <v-list-item-action @click="drawer = false">
            <v-btn icon ripple>
              <v-icon color="grey lighten-1">close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="!$vuetify.breakpoint.smAndUp" inset></v-divider>

        <v-list-item class="px-2" @click="showRepairStepperModal = true">
          <v-list-item-action>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" fab small dark color="primary" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span> Nouvelle intervention </span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Nouvelle intervention </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group color="#fed13c">
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" router exact link>
            <v-list-item-action>
              <v-tooltip right>
                <template #activator="{ on, attrs }">
                  <v-icon :color="item.color" v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
                </template>
                <span>
                  {{ item.title }}
                </span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template #append>
        <v-list-item v-if="isAdmin" to="/admin" router>
          <v-list-item-action>
            <v-icon color="red">mdi-shield-star</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Admin Aposio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-menu v-model="infomenu" offset-x :close-on-content-click="false">
          <template #activator="{ on }">
            <v-list-item v-on="on">
              <v-list-item-action>
                <v-icon>mdi-console</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Version</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-card>
            <v-list>
              <v-list-item> Version </v-list-item>
            </v-list>
            <v-divider />
            <DevInfo />
          </v-card>
        </v-menu>

        <v-list-item v-if="logged" to="/app/logout" router exact>
          <v-list-item-action>
            <v-icon>mdi-power</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Déconnexion</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      dense
      :color="hasOtherShopSelected ? 'red lighten-3' : '#333333'"
      dark
      class="d-print-none"
    >
      <v-btn v-if="$router.options.routes.length > 0" icon @click="$router.back()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <nuxt-link :to="inApp && logged ? '/app' : '/'" class="pt-2">
        <img
          contain
          height="36"
          class="hidden-xs-only"
          alt="Aposio"
          src="/logo_aposio_h_white_2x.png"
          srcset="/logo_aposio_h_white_1x.png 1x, /logo_aposio_h_white_2x.png 2x, /logo_aposio_h_white_3x.png 3x"
        />
        <img class="hidden-sm-and-up" alt="Aposio" src="/logo_aposio_r_white_3x.png" height="36" />
      </nuxt-link>
      <v-spacer />
      <template v-if="isAdmin">
        <v-menu dark v-bind="$attrs" :close-on-content-click="false" offset-y allow-overflow v-on="$listeners">
          <template #activator="{ on }">
            <v-btn icon :color="hasOtherShopSelected ? 'red' : null" v-on="on">
              <v-icon>mdi-account-switch</v-icon>
            </v-btn>
          </template>
          <v-sheet light class="pa-2" min-width="310">
            <CompanySelector />
          </v-sheet>
        </v-menu>
      </template>

      <v-spacer />

      <DeferedUploadMenu />

      <v-tooltip v-if="$nuxt.isOffline" bottom>
        <template #activator="{ on }">
          <v-icon v-on="on">mdi-cloud-off-outline</v-icon>
        </template>
        <span>Hors-ligne</span>
      </v-tooltip>
      <!-- <v-spacer /> -->
      <template v-if="!logged">
        <v-btn to="/tarifs" text class="hidden-xs-only">Tarifs</v-btn>
        <v-btn to="/about" text class="hidden-xs-only">A propos</v-btn>
        <v-btn to="/app/onboarding" text class="mx-3 hidden-xs-only"> Créer un compte Aposio </v-btn>
        <v-btn to="/app/connexion" text class="hidden-xs-only"> Connexion </v-btn>
      </template>
      <v-btn v-else-if="logged && !inApp" to="/app/" text class="hidden-xs-only"> Espace Artisan </v-btn>

      <v-menu offset-y :close-on-content-click="false">
        <template v-if="logged" #activator="{ on }">
          <v-btn icon dark v-on="on">
            <v-badge :value="notificationCount" :content="notificationCount" overlap>
              <v-avatar :color="logged && '#666666'" size="32">
                <v-icon v-if="!userInitial" dark>mdi-account-circle</v-icon>
                {{ userInitial }}
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>
        <template v-else #activator="{ on }">
          <v-btn to="/app/connexion" icon dark class="hidden-sm-and-up" v-on="on">
            <v-avatar color="grey ligthen-2" size="32">
              <v-icon dark>mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-list v-if="logged">
          <EventNotification v-if="logged" />

          <v-divider />
          <v-list-item v-if="logged" to="/app/logout" router exact>
            <v-list-item-action>
              <v-icon>mdi-power</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Se déconnecter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="!logged">
          <v-list-item v-if="!logged" to="/app/connexion" router exact>
            <v-list-item-content>
              <v-list-item-title>Se connecter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-app-bar-nav-icon class="hidden-sm-and-up" @click="drawer = !drawer" />
    </v-app-bar>

    <v-main>
      <nuxt />
    </v-main>

    <v-bottom-navigation v-if="logged && $vuetify.breakpoint.xsOnly" app color="primary darken-1" grow max-height="48">
      <v-btn to="/app" exact>
        <span>Dashboard</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>
      <v-btn to="/app/agenda">
        <span>Agenda</span>
        <v-icon>mdi-calendar-today</v-icon>
      </v-btn>
      <div style="min-width: 40px"></div>
      <v-btn to="/app/repair">
        <span>Suivi</span>
        <v-icon>mdi-view-column</v-icon>
      </v-btn>
      <v-btn to="/app/client">
        <span>Clients</span>
        <v-icon>mdi-account-box-outline</v-icon>
      </v-btn>
      <!-- <v-btn to="/app/settings">
        <span>Paramètres</span>
        <v-icon>mdi-cog</v-icon>
      </v-btn> -->
    </v-bottom-navigation>

    <v-btn
      v-if="showPlusButton && $vuetify.breakpoint.xsOnly"
      fab
      app
      bottom
      fixed
      dark
      color="primary"
      :right="!$vuetify.breakpoint.xsOnly"
      :style="$vuetify.breakpoint.xsOnly ? 'left: calc(50% - 28px); bottom: 14px;' : ''"
      @click="showRepairStepperModal = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog
      v-model="showRepairStepperModal"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500"
      persistent
      scrollable
      transition="dialog-bottom-transition"
    >
      <LazyRepairStepperCard v-if="showRepairStepperModal" @cancel="showRepairStepperModal = false" />
    </v-dialog>

    <v-footer v-if="!$route.path.startsWith('/app')">
      <v-row class="justify-space-between text-caption">
        <div @click="devmodal = true">Aposio &copy; {{ new Date().getFullYear() }}</div>
        <v-bottom-sheet v-model="devmodal"><DevInfo /></v-bottom-sheet>
        <a href="/legal#rgpd">Politique de protection des données</a>
      </v-row>
    </v-footer>
  </v-app>
</template>

<style scoped>
footer a {
  text-decoration: none;
  color: #000;
}
</style>
<style>
.img-gray-filter {
  filter: grayscale(100%) opacity(60%);
}
</style>
<script>
import { mapGetters } from 'vuex'
import { userInitial } from '../api/skills'
import DevInfo from '~/components/DevInfo'
import EventNotification from '~/components/EventNotification'
import CompanySelector from '~/components/CompanySelector'
import DeferedUploadMenu from '~/components/DeferedUploadMenu'
import LazyRepairStepperCard from '~/components/RepairStepperCard.vue'
export default {
  components: {
    DevInfo,
    EventNotification,
    CompanySelector,
    DeferedUploadMenu,
    LazyRepairStepperCard,
  },
  data() {
    return {
      drawer: false,
      navbarExpended: false,
      devmodal: false,
      infomenu: false,

      showRepairStepperModal: null,
    }
  },
  computed: {
    ...mapGetters({ notificationCount: 'notify/count' }),
    logged() {
      return this.$store.getters.isAuthenticated
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    inApp() {
      return this.$route.path.startsWith('/app')
    },

    loggedUser() {
      return this.$store.state.authSession?.me
    },
    userInitial() {
      return userInitial(this.loggedUser)
    },
    hasOtherShopSelected() {
      return (
        this.isAdmin &&
        this.$store.state.selectedCompany &&
        this.$store.state.selectedCompany.id !== this.loggedUser.shop_id
      )
    },
    showPlusButton() {
      return this.inApp && this.loggedUser
    },

    items() {
      if (this.$store.getters.isAuthenticated) {
        return [
          {
            icon: 'mdi-apps',
            title: 'Accueil',
            to: '/app',
          },
          {
            icon: 'mdi-calendar-today',
            title: 'Agenda',
            to: '/app/agenda',
          },
          {
            // icon: 'mdi-wrench',
            icon: 'mdi-view-column',
            title: 'Suivi des réparations',
            to: '/app/repair',
          },
          {
            // icon: 'mdi-wrench',
            icon: 'mdi-file-document-multiple',
            title: 'Factures - Paiements',
            to: '/app/quote',
          },
          {
            icon: 'mdi-account-box-multiple',
            title: 'Fiches Clients',
            to: '/app/client',
          },
          {
            icon: 'mdi-video',
            title: 'Télé assistance',
            to: '/app/teleassistance',
            color: 'grey',
          },
          {
            icon: 'mdi-cog',
            title: 'Paramètres',
            to: '/app/settings',
          },
        ]
      }
      return [
        {
          icon: 'mdi-cash-usd',
          title: 'Tarifs',
          to: '/tarifs',
        },
        {
          icon: 'mdi-account-multiple',
          title: 'A propos',
          to: '/about',
        },
        {
          icon: 'mdi-account-plus',
          title: "S'inscrire",
          to: '/app/onboarding',
        },
        {
          icon: 'mdi-key',
          title: 'Connexion',
          to: '/app/connexion',
        },
      ]
    },
  },
}
</script>

<style scoped>
.toggleNav {
  position: absolute;
  top: 30px;
  right: -15px;
  z-index: 20;
}
#app {
  background-color: whitesmoke;
}

@media print {
  #app {
    background-color: transparent;
  }
}
</style>

<style>
@media print {
  main {
    padding: 0px !important;
    margin: 8px;
  }
}
</style>
