<template>
  <v-list-item v-bind="$attrs" v-on="$listeners">
    <v-list-item-content v-if="value">
      <v-list-item-title class="text-wrap"> {{ name }} {{ value.brand }} </v-list-item-title>
      <v-list-item-subtitle v-if="value.gtin" class="text-wrap">Ref : {{ value.gtin }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="value.type" class="text-wrap">Type : {{ value.type }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="value.model" class="text-wrap">Modèle : {{ value.model }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="value.serial" class="text-wrap">N°Série : {{ value.serial }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="value.underwarranty" class="text-wrap primary--text">
        Sous garantie
      </v-list-item-subtitle>
      <!-- <v-list-item-subtitle v-else class="text-wrap">Hors garantie</v-list-item-subtitle> -->
    </v-list-item-content>
    <v-list-item-title v-else class="grey--text">Pas d'appareil</v-list-item-title>
    <v-list-item-avatar v-if="!hideIcon" tile>
      <v-img :src="(kind && kind.imgsrc) || ''" contain height="48" width="48" class="img-gray-filter" />
    </v-list-item-avatar>
    <v-list-item-action v-if="$slots.default">
      <slot></slot>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { appliancesKind } from '~/api/skills'
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          id: null,
          brand: '',
          gtin: '',
          model: '',
          type: '',
        }
      },
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    kind() {
      return this.value && this.value.kind_id && appliancesKind.find((a) => a.id === this.value.kind_id)
    },
    name() {
      const empty = ''
      if (!this.value) {
        return empty
      }
      if (this.value.name) {
        if (this.value.name === '') {
          return empty
        }
        return this.value.name
      }
      if (this.kind && this.kind.name) {
        return this.kind.name
      }
      return empty
    },
  },
}
</script>
