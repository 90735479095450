
import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      commitId: process.env.commitId,
      buildTime: new Date(process.env.buildTime || '').toLocaleString(),
    }
  },
})
