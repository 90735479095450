export default function ({ store }) {
  if (!store.getters.isAuthenticated) {
    return
  }
  console.log('in SSE')
  /*
  function connect() {
    if (!window.EventSource) {
      console.log('No SSE...')
      return
    }
    console.log('SSE connecting...')
    this.conn = new EventSource('/api/v1/notify', { withCredentials: true })

    this.conn.addEventListener('newEvent', (e) => {
      console.log('SSE newEvent: ', e.data)
      store.commit('notify/addNotification', { type: 'event', data: JSON.parse(e.data), received_at: new Date() })
    })
    this.conn.addEventListener('quote', (e) => {
      console.log('SSE quote: ', e.data)
      store.commit('notify/addNotification', { type: 'quote', data: JSON.parse(e.data), received_at: new Date() })
    })
    this.conn.addEventListener('notify', (e) => {
      console.log('SSE notify: ', e.data)
      const data = JSON.parse(e.data)
      store.commit('notify/addNotification', { type: data.type, data, received_at: new Date() })
    })

    // this.conn.addEventListener('message', (e) => console.log('SSE message: ', e.data))

    this.conn.addEventListener('reload', (e) => {
      console.log('Reload event received', e)
      if (confirm("Voulez-vous appliquer la mise à jour d'Aposio ?")) window.location.reload()
    })

    this.conn.addEventListener(
      'error',
      function (event) {
        switch (event.target.readyState) {
          case EventSource.CONNECTING:
            console.log('SSE Reconnecting...')
            break

          case EventSource.CLOSED:
            console.log('SSE Connection failed', event)
            setTimeout(connect, 5000)
            break
          default:
            console.log('SSE state changed', event.target.readyState)
        }
      },
      false
    )
  }

  setTimeout(connect, 2000)
  */
}
