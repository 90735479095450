<template>
  <v-container class="fill-height d-flex justify-center align-start">
    <v-card flat class="pa-0 mb-8" style="top: 10%">
      <div class="d-flex flex-column flex-sm-row justify-center align-baseline mx-4">
        <img
          src="/404error_aposio.svg"
          class="mx-auto"
          :style="$vuetify.breakpoint.xs ? 'height: 150px' : 'height: 240px'"
        />
        <h1 class="primary--text order-sm-first" style="font-size: 5rem; overflow-wrap: break-word">Ouuups...</h1>
      </div>
      <v-card-title class="mt-4 mb-6">
        <h1 class="word-wrap" style="overflow-wrap: break-word">Nous ne trouvons pas la page demandée</h1>
      </v-card-title>
      <v-card-subtitle style="font-size: 1.4em; overflow-wrap: break-word">
        Vous allez être redirigé vers la <nuxt-link :to="home">page d'accueil</nuxt-link> dans {{ remaining }}s...
      </v-card-subtitle>

      <v-card-text>
        Erreur: {{ error.statusCode }}<br />
        <pre>{{ error }}</pre>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      remaining: 5,
      timer: null,
    }
  },
  computed: {
    home() {
      if (this.$store.getters.isAuthenticated) {
        return '/app'
      }
      return '/'
    },
  },
  watch: {
    remaining(v) {
      if (v < 0) {
        this.$router.push(this.home)
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV === 'production') {
      this.timer = setInterval(() => (this.remaining = this.remaining - 1), 1000)
    }
    console.log(this.error)
  },
  destroyed() {
    this.timer && clearInterval(this.timer)
  },
}
</script>
