export default (apolloError, ctx) => {
  const { graphQLErrors, networkError, operation, forward } = apolloError
  console.log('apolloError', graphQLErrors, networkError, operation, forward)
  console.log('apolloError path: ' + ctx.route.path)
  console.error(graphQLErrors, networkError)
  if (graphQLErrors.find((error) => error.message === 'not logged in, please login fist')) {
    ctx.store.dispatch('logout')
    ctx.redirect('/app')
  }
}
