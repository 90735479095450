const AppliPbForm = {
  nodes: {
    '4e3bdb60-a680-46d7-86ab-70cccdb06de3': {
      type: 'appliance',
      id: '4e3bdb60-a680-46d7-86ab-70cccdb06de3',
      name: 'Lave-linge',
      imgsrc: '/icons/tunnel/lave_linge.svg',
      childs: [
        '8248fc54-889f-4680-8c28-750168858846',
        'ea4972f5-3ebe-4985-b239-df3083da757d',
        '0f912437-2925-42ef-833c-f6228fb5d552',
        '74e1e9c1-03e0-4586-983e-b1ff480302f9',
        '3932f19f-8d0e-460c-9503-5deec32cc810',
        '5c46f00d-04ff-45b2-8189-d5e3ec51feb0',
        'b78bf1ab-0798-4cec-b118-52854797391e',
        '742e50c5-51ff-482f-bac3-a1b86678efe5',
        'cb7c822d-9392-4671-afc2-0235520f4ac9',
        '586fe24e-7a9f-4bdb-a5db-d683c7e97e4e',
      ],
    },
    '4337eb33-053d-49a1-b9b3-2d24f120efa6': {
      type: 'appliance',
      id: '4337eb33-053d-49a1-b9b3-2d24f120efa6',
      name: 'Réfrigerateur',
      imgsrc: '/icons/tunnel/refrigerateur.svg',
      childs: [
        'fc60ac37-cfb2-4237-bb66-3879c7cd3a75',
        '991f070a-74cd-422f-b1e5-d47aa4826662',
        'a7583757-eace-4a7a-8aa6-1f57a27a1041',
        '6730cf86-503e-4342-8fad-ce0772cb6af4',
        '369c1522-c150-4c26-9ffb-376e47dbb5f4',
        '46a9f202-f104-47ea-b099-250975bda4c3',
        '2d0cb885-6f56-4537-8af4-931d52b5fff0',
        'e5ccdd25-c3a4-45c3-9de5-e6e41cc85eea',
      ],
    },
    '9cf53961-cc36-4ff9-838c-bd7c25f2d712': {
      type: 'appliance',
      id: '9cf53961-cc36-4ff9-838c-bd7c25f2d712',
      name: 'Lave-vaisselle',
      imgsrc: '/icons/tunnel/lave_vaisselle.svg',
      childs: [
        '9b0ba501-d898-495f-948e-f55781643718',
        'f267d30e-ca8e-4826-9642-0668d32d2b3c',
        'c19f80ff-a3e3-4abc-abb6-738b65e0d102',
        'c5c9383f-9bd7-4de1-b9d9-ca7ee479191c',
        'e7b1d7b0-07e3-47c1-86c4-fb160a565048',
        '20f29788-876a-4569-b426-209837d395ce',
        'f50aa9c2-6301-42ad-872c-211c843cef14',
        '041230b8-c309-4f3f-a5b8-ddc479c85851',
      ],
    },
    '5e720952-e1b0-450a-92e0-09b449adaf05': {
      type: 'appliance',
      id: '5e720952-e1b0-450a-92e0-09b449adaf05',
      name: 'Four',
      imgsrc: '/icons/tunnel/four.svg',
      childs: [
        'be5ae5d2-1d1b-4149-b4c6-f0db52abe666',
        '0217b315-0187-4071-b3c6-88e96cde9bca',
        '787c0106-0e99-4a6a-9399-47514e5ae6d6',
        '571186f4-6c8d-4688-afd5-7fd53e832482',
        '80eaf2b9-1c6c-4634-aaa0-fbfec22ef05c',
        '73196eb0-1dce-4f8d-a3e4-90399ceb2313',
        '42288a65-df1d-407e-9b7a-f327165047d2',
        '559dffc7-ef51-44a5-84db-5e857f452bf9',
      ],
    },
    '417fda7d-b7c5-4ecf-b64a-06e2cb3c875b': {
      type: 'appliance',
      id: '417fda7d-b7c5-4ecf-b64a-06e2cb3c875b',
      name: 'Sèche-linge',
      imgsrc: '/icons/tunnel/seche_linge.svg',
    },
    'cd17945c-e67b-47a6-818e-c9e860bda519': {
      type: 'appliance',
      id: 'cd17945c-e67b-47a6-818e-c9e860bda519',
      name: 'Congélateur',
      imgsrc: '/icons/tunnel/congelateur.svg',
      childs: [
        'dd3d6698-5dcc-4abb-9695-a7d4e39fd3a7',
        'e7283e10-1d36-4a90-b285-be479b8f7f15',
        '01be1923-1589-482b-a707-e3e0085bba2d',
        '41143efc-e536-471b-9a0a-ca93f97d84c1',
        '6e9635af-a099-4f6b-81ff-eacdae880586',
        '49d6323b-2413-4c31-bf40-05b5542382bd',
        '5601f538-7885-4313-a9d8-fbebc88acd68',
        '8cd14663-e17d-402c-a8d8-a614c4045987',
      ],
    },
    'cf76916d-5849-476b-a5c3-6c5686da885c': {
      type: 'appliance',
      id: 'cf76916d-5849-476b-a5c3-6c5686da885c',
      name: 'Hotte',
      imgsrc: '/icons/tunnel/hotte.svg',
    },
    '7d77ff89-0f7e-472f-8d75-1a24d0e87913': {
      type: 'appliance',
      id: '7d77ff89-0f7e-472f-8d75-1a24d0e87913',
      name: 'Plaque de cuisson',
      imgsrc: '/icons/tunnel/plaque_cuisson.svg',
      childs: [
        'a50493b8-9ffa-4e39-a94b-fa66cf39a902',
        'ebc7c74d-2e77-4d60-88a6-6ae325446830',
        'c98747d7-76e6-4fdb-a426-c0431f4f83a2',
        '60d3cf01-ea43-4454-a449-2a52ee8ebe16',
        '37e27a79-ffbd-4703-a1a8-850ec31eddbe',
        '97c2afbc-119f-43d9-97fa-7a793024f615',
        '81b8bd27-a467-4127-8783-4991ea3ff473',
        '6f1d4a45-9ae5-437f-8137-16b1b9e6467b',
      ],
    },
    'c9ecc857-6d1e-4351-992b-9fc1990055ab': {
      type: 'appliance',
      id: 'c9ecc857-6d1e-4351-992b-9fc1990055ab',
      name: 'Cuisiniere',
      imgsrc: '/icons/tunnel/cuisiniere.svg',
      childs: [
        'b77c244b-cb0b-4095-8690-c97c8ea27446',
        '57541f1d-c5c5-4fa8-8943-68d9cf151f15',
        'fa004e97-bbbe-49ea-9f68-011a57112a6d',
        'e3ba786e-f584-460c-87c5-cf007631c767',
        'f6a02482-34a3-4c26-9ee6-a64c87cdad21',
        '8436f2a5-40d2-4e45-8bf5-fa8a7aa99e2e',
        'f591f3a8-06a7-49f2-9e44-b1fcdd909f48',
        '7b4c5da7-924d-44da-8c9c-ec572516f19b',
      ],
    },
    'b8a4b1a4-2ac0-4b51-9652-8feddb5d21d2': {
      type: 'appliance',
      id: 'b8a4b1a4-2ac0-4b51-9652-8feddb5d21d2',
      name: 'Cave a vin',
      imgsrc: '/icons/tunnel/cave_vin.svg',
    },
    'e628f657-befe-4b59-8e60-0a75ef4e5122': {
      type: 'appliance',
      id: 'e628f657-befe-4b59-8e60-0a75ef4e5122',
      name: 'Préparation culinaire',
      imgsrc: '/icons/tunnel/culinaire.svg',
    },
    '9562fb74-94a1-4eff-a632-b103baef6bc7': {
      type: 'appliance',
      id: '9562fb74-94a1-4eff-a632-b103baef6bc7',
      name: 'Soin du linge',
      imgsrc: '',
    },
    'ceab213f-e6ae-4c1f-882e-5c837e0becbc': {
      type: 'appliance',
      id: 'ceab213f-e6ae-4c1f-882e-5c837e0becbc',
      name: 'TV',
      imgsrc: '/icons/tunnel/tv.svg',
      childs: [
        'ed882b6d-5ca5-4904-a20b-54689682ba4f',
        '4d687239-a8d3-4182-9b08-a7100e31c10d',
        'fcd777c3-8c6e-46ba-a4ad-2546db1b0673',
        '602abf97-adb7-40f2-a017-e90d1fa6c287',
        '20068839-8f03-4fd5-9a04-51b2f92f611a',
      ],
    },
    '7de2805d-df98-48d0-9e4b-f28e56cb901f': {
      type: 'appliance',
      id: '7de2805d-df98-48d0-9e4b-f28e56cb901f',
      name: 'Lecteur et/ou enregistreur vidéo',
      imgsrc: '/icons/tunnel/dvd.svg',
    },
    'c314cece-7a77-4fdc-aab4-2d118a39bf05': {
      type: 'appliance',
      id: 'c314cece-7a77-4fdc-aab4-2d118a39bf05',
      name: 'Appareil audio',
      imgsrc: '/icons/tunnel/audio.svg',
    },
    '6d280077-d919-4f18-b446-428d9040c4fe': {
      type: 'appliance',
      id: '6d280077-d919-4f18-b446-428d9040c4fe',
      name: 'Autre appareil',
      imgsrc: '',
    },
    '0b41f197-acf5-4a14-9104-91131b79d100': {
      type: 'appliance',
      id: '0b41f197-acf5-4a14-9104-91131b79d100',
      name: "Ballon d'eau chaude",
      imgsrc: '/icons/tunnel/chauffe_eau.svg',
      childs: [
        'c07ab84c-31ef-45fc-80ef-6f0cb6dafdcb',
        '9a51947c-13ca-477f-8e6a-52873c4ce653',
        '65add05f-6fc3-4a7b-a822-722c72b6670d',
        '8bbcf0e2-cb1f-41fa-b78a-c22c225d7315',
      ],
    },
    '36ba0ad4-be6a-4175-beab-9429fac5f321': {
      type: 'appliance',
      id: '36ba0ad4-be6a-4175-beab-9429fac5f321',
      name: 'Baignoire',
      imgsrc: '/icons/tunnel/baignoire.svg',
      childs: [
        '1bae3e13-54f7-482a-a7f5-608a160a99bc',
        'c92a3674-3951-4cf4-bb7b-462eb863442f',
        '86cad50b-7612-43de-b06c-098f720bc0ef',
      ],
    },
    'b7913516-14d6-4e66-8e1e-4a2aa3c240c9': {
      type: 'appliance',
      id: 'b7913516-14d6-4e66-8e1e-4a2aa3c240c9',
      name: 'Douche',
      imgsrc: '/icons/tunnel/douche.svg',
      childs: [
        '01c331aa-3d42-4fb7-9785-53b35ebe805d',
        '7aedaa9a-c375-4dca-840d-e0061b5137b0',
        '9f900172-06b3-406f-a4dc-f43a04cd3c0c',
      ],
    },
    '925075eb-c4f7-43ec-bc30-3e76285c86d5': {
      type: 'appliance',
      id: '925075eb-c4f7-43ec-bc30-3e76285c86d5',
      name: 'Evier ou lavabo',
      imgsrc: '/icons/tunnel/lavabo.svg',
      childs: [
        'ff138d8a-9ad7-466b-ae12-2c7fb4b4f143',
        '4dd31efe-8bed-411d-9696-4def20895739',
        '512a4b1a-afda-4709-9458-0ae9644aa444',
      ],
    },
    '2529ae8d-1ecd-4051-94b8-bb27c4735ec2': {
      type: 'appliance',
      id: '2529ae8d-1ecd-4051-94b8-bb27c4735ec2',
      name: 'WC',
      imgsrc: '/icons/tunnel/wc.svg',
      childs: [
        'cdef0253-8cc9-4c09-bc53-f1653a48c2d3',
        '732f34c1-aabd-49cd-b765-403f0dfaf54b',
        'f4ad8998-7919-4c50-a126-9b1f110a1042',
      ],
    },
    'bd71be03-0280-4f31-9a5b-858395f09aba': {
      type: 'appliance',
      id: 'bd71be03-0280-4f31-9a5b-858395f09aba',
      name: 'Canalisation',
      imgsrc: '/icons/tunnel/plomberie.svg',
      childs: [
        'e07805b2-f500-4daf-b0c1-18326bc97534',
        '789fa424-758c-42a7-98ff-52eccf194560',
        '1fe2b8a8-b8e3-469f-a311-bf267cfc97be',
        '371c3149-b642-4cc7-8402-90c7bad15eef',
      ],
    },
    '958f6a3a-e49a-480e-afd4-544431dcc6c2': {
      type: 'appliance',
      id: '958f6a3a-e49a-480e-afd4-544431dcc6c2',
      name: 'Téléphone',
      imgsrc: '/icons/tunnel/smartphone.svg',
      childs: [
        '239fa075-1e80-41cf-97f9-a4fc056fb2dc',
        '9b2f27c9-2bb8-44d1-b360-7bb0d833dd91',
        'c707db7c-17dc-4676-8c16-e07f057e98b3',
        'cffa1d11-3946-44d6-bec3-59947a50556c',
        'b76298a6-3b7d-4385-b12a-a1bbf081afa5',
      ],
    },
    '1329a0c2-e11f-4cab-b934-d7d51527babb': {
      type: 'appliance',
      id: '1329a0c2-e11f-4cab-b934-d7d51527babb',
      name: 'Tablette',
      imgsrc: '/icons/tunnel/tablette.svg',
      childs: [
        '239fa075-1e80-41cf-97f9-a4fc056fb2dc',
        '9b2f27c9-2bb8-44d1-b360-7bb0d833dd91',
        'c707db7c-17dc-4676-8c16-e07f057e98b3',
        'cffa1d11-3946-44d6-bec3-59947a50556c',
        'b76298a6-3b7d-4385-b12a-a1bbf081afa5',
      ],
    },
    '8f5c2c39-6593-4164-8f2b-3f4f0e3bd5df': {
      type: 'appliance',
      id: '8f5c2c39-6593-4164-8f2b-3f4f0e3bd5df',
      name: 'Montre connectée',
      imgsrc: '/icons/tunnel/montre.svg',
      childs: [
        '15f16e49-171b-4d97-b16f-4a6270ed2804',
        '94a5b5cc-9acc-4bf1-b15c-4217913cee56',
        '0b828828-b4fb-4816-b600-a7a2df169423',
        '4e19ebad-54df-4f2c-ba0d-505789ffd757',
        '34dafd7e-c51a-4c73-b761-39773cde46bc',
        '0543c72f-51bf-4205-8cfb-0c61bccbe40a',
      ],
    },
    'fc2533ce-9699-4be3-9e87-187ac83be8b8': {
      type: 'appliance',
      id: 'fc2533ce-9699-4be3-9e87-187ac83be8b8',
      name: 'Imprimante/Scanner/Périphérique',
      imgsrc: '/icons/tunnel/imprimante.svg',
    },
    'dfad7147-94a9-4a10-8598-8af5dde0d144': {
      type: 'appliance',
      id: 'dfad7147-94a9-4a10-8598-8af5dde0d144',
      name: 'Lecteur MP3',
      imgsrc: '/icons/tunnel/mp3.svg',
      childs: [
        'a110d47c-c30c-469b-872b-59276225f9a1',
        'baa713e6-9cce-4420-a762-eeda3098924e',
        '588ce31c-e45c-443a-853b-c93c4937a118',
        'e9e31f6f-8e0a-4cb5-8048-b8d7c6d8d56d',
        '2107c6cc-7443-42f5-95c1-220c74023f6a',
      ],
    },
    'dfecd49d-b1e9-4932-88dc-0223c0a9c742': {
      type: 'appliance',
      id: 'dfecd49d-b1e9-4932-88dc-0223c0a9c742',
      name: 'Console de jeux',
      imgsrc: '/icons/tunnel/console_jeux.svg',
    },
    'fed2a299-a81b-4ef6-8947-d502b74e92ab': {
      id: 'fed2a299-a81b-4ef6-8947-d502b74e92ab',
      name: 'Autre appareil multimédia',
      imgsrc: '',
    },
    '5da7e724-44ac-4ac4-bd61-16458575ca47': {
      type: 'appliance',
      id: '5da7e724-44ac-4ac4-bd61-16458575ca47',
      name: 'Antenne hertzienne',
      imgsrc: '',
      childs: [
        'cc0808d4-f0dd-4512-9b33-0e22ae825826',
        'b60e3c5c-c7aa-48ea-bcf0-dfe14492139c',
        'a6f38873-b264-4455-a390-625cfbb07df9',
        '91aae8c9-a7e1-49c4-a103-4555e1e7da8d',
      ],
    },
    '248aab36-2a94-4f8f-b487-11ea3128ceba': {
      type: 'appliance',
      id: '248aab36-2a94-4f8f-b487-11ea3128ceba',
      name: 'Antenne satellite',
      imgsrc: '',
      childs: [
        '5fc1898a-a397-4b06-92fc-4c998dd87fad',
        'b9e93831-8183-42b0-856b-987a7427ea48',
        '11d7179b-a3dc-4543-a31b-98fd400167f3',
        '379a4986-b0bf-4f0e-86d9-67bc6486a6b6',
      ],
    },
    '7403847f-0601-4b02-a94f-f738951be8b8': {
      type: 'appliance',
      id: '7403847f-0601-4b02-a94f-f738951be8b8',
      name: 'Performance énergétique',
      imgsrc: '/icons/tunnel/diag_energie.svg',
    },
    '80e77932-c6d5-4749-ab23-917f0c1a0a9d': {
      type: 'appliance',
      id: '80e77932-c6d5-4749-ab23-917f0c1a0a9d',
      name: 'Loi Carrez/Loi Boutin',
      imgsrc: '/icons/tunnel/diagnostic.svg',
    },
    'e04961dc-d89b-4785-b825-f3bb41798d12': {
      type: 'appliance',
      id: 'e04961dc-d89b-4785-b825-f3bb41798d12',
      name: 'Amiante',
      imgsrc: '/icons/tunnel/amiante.svg',
    },
    'd58e7147-8a4b-4e2f-896e-2cdb528daa7c': {
      type: 'appliance',
      id: 'd58e7147-8a4b-4e2f-896e-2cdb528daa7c',
      name: 'Gaz',
      imgsrc: '/icons/tunnel/gaz.svg',
    },
    '018ab954-6cc1-4d85-850a-c79594b8e465': {
      type: 'appliance',
      id: '018ab954-6cc1-4d85-850a-c79594b8e465',
      name: 'Electricité',
      imgsrc: '/icons/tunnel/electricite.svg',
    },
    'fe52d3fb-2ed8-4db9-977f-604387c50087': {
      type: 'appliance',
      id: 'fe52d3fb-2ed8-4db9-977f-604387c50087',
      name: 'Plomb',
      imgsrc: '',
    },
    'e2c1496c-960d-4477-81c4-e836f3dd44a7': {
      type: 'appliance',
      id: 'e2c1496c-960d-4477-81c4-e836f3dd44a7',
      name: 'Mérules',
      imgsrc: '/icons/tunnel/merule.svg',
    },
    'abaf8cd7-581d-4d54-9b1a-c4c5b4a5034f': {
      type: 'appliance',
      id: 'abaf8cd7-581d-4d54-9b1a-c4c5b4a5034f',
      name: 'ERP',
      imgsrc: '/icons/tunnel/erp.svg',
    },
    '8248fc54-889f-4680-8c28-750168858846': {
      id: '8248fc54-889f-4680-8c28-750168858846',
      name: "Fuite d'eau, problème d'arrivée d'eau",
      imgsrc: '',
      childs: [
        'e184ad45-0830-4ff1-8fb4-36aafaeee77a',
        'ab5a81ce-19a1-4bf6-8e37-edcf431b1f23',
        'a7e5703b-ea2c-4208-b99d-97b98ab2e04a',
        '26539943-fb3a-4907-a1f1-a1f35a3f3cf0',
        '53b6f8ff-7be4-4653-8f31-5045970738b3',
        'de79d149-c3b3-4961-baca-2d24a8313274',
        '878c2b09-49b7-46cb-9f95-63030db1719d',
        'c2bdb1f6-b5af-4c01-a01c-40edfce72f0c',
      ],
    },
    'ea4972f5-3ebe-4985-b239-df3083da757d': {
      id: 'ea4972f5-3ebe-4985-b239-df3083da757d',
      name: "Problème d'essorage, de vidange",
      imgsrc: '',
      childs: [
        '809747c2-7aa4-4d71-b603-081ffe7fdf70',
        '62b5e2a9-d620-4e07-8f99-f24768bbc349',
        '52feeb4f-b8a5-41ae-9270-dd3d997b6d88',
      ],
    },
    '0f912437-2925-42ef-833c-f6228fb5d552': {
      id: '0f912437-2925-42ef-833c-f6228fb5d552',
      name: 'Bruit suspect',
      imgsrc: '',
      childs: [
        'b1713c24-be6b-4966-835f-b96a40affc4f',
        '42241661-e14e-4527-b60f-8643da1076a2',
        'ab4233e9-bb5a-42c8-b34f-a44b22892d1a',
        'e5f32165-85d6-4928-a804-305efb22a460',
      ],
    },
    '74e1e9c1-03e0-4586-983e-b1ff480302f9': {
      id: '74e1e9c1-03e0-4586-983e-b1ff480302f9',
      name: 'Problème de qualité de lavage',
      imgsrc: '',
      childs: [
        '84cea3fb-a22d-4232-85a5-bab795a57b67',
        '2cd67d5f-540f-4442-9c78-0907ddfcd862',
        'face0d2d-bc39-4e98-993a-5ceb0604008b',
        'a6b626bf-3edc-4097-b426-db64aad6a7de',
      ],
    },
    '3932f19f-8d0e-460c-9503-5deec32cc810': {
      id: '3932f19f-8d0e-460c-9503-5deec32cc810',
      name: 'Odeur suspecte',
      imgsrc: '',
      childs: [
        '2a798635-3f6d-41d3-bbbc-aeed237fcdce',
        'c0cfe2ce-66db-4a89-ad95-907204d84f73',
        '1df6b349-dad2-4a08-bd66-36dbda1c0b86',
        'e5f03f70-e30f-4496-9b61-d673f85faf3d',
      ],
    },
    '5c46f00d-04ff-45b2-8189-d5e3ec51feb0': {
      id: '5c46f00d-04ff-45b2-8189-d5e3ec51feb0',
      name: 'Pièce cassée',
      imgsrc: '',
      childs: [
        '5a1f4601-55ad-4902-9e78-821ac1ea30f6',
        '56c47fc9-43c5-4624-932a-eaa9479ce8f6',
        '2d49fdb4-1cc4-4872-bbf5-5b63c27a3663',
        '4ba464b3-c407-4606-987d-136e83682054',
        '4b012a88-83c0-4a40-9e66-2907fcad781e',
        '51268b12-fb9f-43ba-b591-a00c11a83709',
        '142976d5-019d-4002-8edc-153cfb8d00d8',
      ],
    },
    'b78bf1ab-0798-4cec-b118-52854797391e': {
      id: 'b78bf1ab-0798-4cec-b118-52854797391e',
      name: 'Problème de mise en marche',
      imgsrc: '',
      childs: [
        '16ef4e98-2461-450d-b1af-ad5ba9ac0978',
        '64397c05-ec24-40d4-8139-d5d1f4c2043e',
        '95871eb3-e6ea-4ca3-bdc9-cdefb30c8c3b',
        'd75f9303-3207-4411-83c8-698b61326fa0',
        '14369373-fcd7-49c5-9606-289cbd92a1ac',
        '9475f9c5-edcc-47d6-a751-00a12b6548de',
      ],
    },
    '742e50c5-51ff-482f-bac3-a1b86678efe5': {
      id: '742e50c5-51ff-482f-bac3-a1b86678efe5',
      name: 'Code erreur',
      imgsrc: '',
      childs: ['91d9941a-2584-47a8-b0f5-60345066db3b'],
    },
    'cb7c822d-9392-4671-afc2-0235520f4ac9': {
      id: 'cb7c822d-9392-4671-afc2-0235520f4ac9',
      name: 'Orage/Surtension',
      imgsrc: '',
      childs: ['12bba103-7663-495a-b035-1b0896fe02e8', '8fd59eea-5ef7-470b-bd07-df1e237ef315'],
    },
    '586fe24e-7a9f-4bdb-a5db-d683c7e97e4e': {
      id: '586fe24e-7a9f-4bdb-a5db-d683c7e97e4e',
      name: 'Autre',
      imgsrc: '',
      childs: ['68dbc372-8240-4169-a46d-c3d5e0eac1ef'],
    },
    'fc60ac37-cfb2-4237-bb66-3879c7cd3a75': {
      id: 'fc60ac37-cfb2-4237-bb66-3879c7cd3a75',
      name: 'Problème de tempérarature',
      imgsrc: '',
      childs: [
        'c531951c-57de-4c22-aa54-ba2f0dd7bd8c',
        '17b590f1-53c0-430f-b6b0-badde597fbf8',
        '300c30d4-7b79-4536-93ab-f58cccdb4ac0',
        '4331ea36-7bdd-419c-9864-15248a64fdf4',
        '75e35db6-0db8-44d6-aba4-bdcc762b7d00',
        '297361b9-de1f-451a-a72a-9ebfd7d7e4c8',
        '10ddb143-0af3-44e5-b327-3d2909c125a3',
        '0dd5fb64-467d-4680-83cc-c220599f5b59',
      ],
    },
    '991f070a-74cd-422f-b1e5-d47aa4826662': {
      id: '991f070a-74cd-422f-b1e5-d47aa4826662',
      name: "Fuite d'eau",
      imgsrc: '',
      childs: [
        '393eac5b-d8ed-4804-bbf2-f39d63949825',
        'c5091844-5186-4687-a66a-b8c0c7828082',
        '1c840108-7afd-4877-a010-d0d4d4d5c3be',
        'cc842362-9027-4df6-bb8b-dbffcf818cbc',
        'e5405a4d-b83f-4b8f-acdc-e1af432e8532',
      ],
    },
    'a7583757-eace-4a7a-8aa6-1f57a27a1041': {
      id: 'a7583757-eace-4a7a-8aa6-1f57a27a1041',
      name: 'Bruit suspect',
      imgsrc: '',
      childs: [
        '36761cb6-7fff-4690-81c6-1b3a062d127d',
        '44f26d7c-b4ed-4dab-ace6-3b7a9d3b0fd5',
        '64b199ba-714e-4b94-a737-acbc3b648b25',
      ],
    },
    '6730cf86-503e-4342-8fad-ce0772cb6af4': {
      id: '6730cf86-503e-4342-8fad-ce0772cb6af4',
      name: 'Odeur suspecte',
      imgsrc: '',
      childs: [
        'ba621c9e-f745-462b-948b-2ed5e7f1fcee',
        'c823aee1-23c9-48c9-a944-7a010622311b',
        'dba4f734-3699-4bb6-a60c-17fc4072b116',
        '54a696f0-47ee-4db9-aca3-0d536e8d71b8',
      ],
    },
    '369c1522-c150-4c26-9ffb-376e47dbb5f4': {
      id: '369c1522-c150-4c26-9ffb-376e47dbb5f4',
      name: 'Problème de lumière, pièce cassée',
      imgsrc: '',
      childs: [
        '99f80f49-bd4d-4ada-b6aa-6833c63d3406',
        '86810bd3-bb05-4e1c-95d0-3a9fb01b909f',
        '95f97a65-e248-4789-81e8-11fbdf9c6503',
        'd328afb3-5066-4123-b9c9-a229c6197b60',
        '1c59bcb4-d139-4806-9ed3-59949e82738d',
        '8ad24cfc-0392-4990-b3d4-4d6ca2f9b04c',
        '56c3d2f3-0ef9-4132-b753-7ddf66af5191',
      ],
    },
    '46a9f202-f104-47ea-b099-250975bda4c3': {
      id: '46a9f202-f104-47ea-b099-250975bda4c3',
      name: 'Problème de mise en marche',
      imgsrc: '',
      childs: [
        '490be1f4-f1e7-4a2c-81c1-1e69d3434583',
        'e2d8fc39-aab5-49a1-8ba4-77dba3736b9f',
        'e8c49ea5-3966-4225-8e6e-1ac74b833f0e',
        '62f020fa-dc5e-40db-904e-651f85245181',
        'ca9784d6-3218-4499-8f28-6a963d5b4237',
      ],
    },
    '2d0cb885-6f56-4537-8af4-931d52b5fff0': {
      id: '2d0cb885-6f56-4537-8af4-931d52b5fff0',
      name: 'Problème suite orage ou surtension',
      imgsrc: '',
      childs: ['b4fe23b7-847e-4705-b205-bd323fa4d5c3', '9e513183-fcda-4aa8-9239-41471b0d62e1'],
    },
    'e5ccdd25-c3a4-45c3-9de5-e6e41cc85eea': { id: 'e5ccdd25-c3a4-45c3-9de5-e6e41cc85eea', name: 'Autre', imgsrc: '' },
    '9b0ba501-d898-495f-948e-f55781643718': {
      id: '9b0ba501-d898-495f-948e-f55781643718',
      name: 'Problème de mise en marche',
      imgsrc: '',
      childs: [
        '66a5a483-dbc3-4d3a-87d8-5d5468aa37ef',
        'ab4688ea-7af6-4931-a2c2-b242d49fca06',
        '953a0c59-536c-4181-8ff0-65b0f9fc20ab',
        'daeaafa4-7d0b-4b7a-8a11-b2ea7d102104',
        '6a918149-d700-4ced-9896-477e4c501213',
        '39276de3-400f-4a24-8053-c3e8c95074c6',
      ],
    },
    'f267d30e-ca8e-4826-9642-0668d32d2b3c': {
      id: 'f267d30e-ca8e-4826-9642-0668d32d2b3c',
      name: "Fuite d'Eau, problème de vidange",
      imgsrc: '',
      childs: [
        '252cf381-6119-49c5-bbd7-6e4f11c2d33f',
        '7262bc6a-bfee-4823-8351-f609ec2c30ee',
        'c4e19da2-c492-4ddb-92c9-75f879f7a685',
        '08f17afc-43fb-4995-b5b2-310cd383a9b3',
        'fb9c184a-30a6-4472-960f-153c17efc1d1',
      ],
    },
    'c19f80ff-a3e3-4abc-abb6-738b65e0d102': {
      id: 'c19f80ff-a3e3-4abc-abb6-738b65e0d102',
      name: 'Problème de qualité de lavage',
      imgsrc: '',
      childs: [
        '4927388a-462e-4e1f-9866-eac9735a200b',
        'eacfbe28-db19-4d94-a80d-1fdadc6ccd67',
        '2a82e2e8-e8e4-4162-a568-676eb186b66d',
        'e6db1fba-a7b2-40dd-b63b-ec0ee854afd9',
        '853292f1-231e-4c64-a9e3-4936f44da124',
      ],
    },
    'c5c9383f-9bd7-4de1-b9d9-ca7ee479191c': {
      id: 'c5c9383f-9bd7-4de1-b9d9-ca7ee479191c',
      name: 'Bruit suspect',
      imgsrc: '',
      childs: [
        'cfb7f305-1388-41c6-86e3-11da8116f034',
        '5431d32f-6561-4b19-aae0-002e32232144',
        '778fece1-750d-4e8b-b409-ff0ebe688a87',
        '345056c3-7a29-4ce9-a277-9cfcc8bfaa72',
      ],
    },
    'e7b1d7b0-07e3-47c1-86c4-fb160a565048': {
      id: 'e7b1d7b0-07e3-47c1-86c4-fb160a565048',
      name: 'Odeur suspecte',
      imgsrc: '',
      childs: [
        '301fedeb-2a4e-461d-8352-39c92d89afd7',
        '4e7314f0-73f6-4ad8-8558-ffdf4e14a052',
        '48dbadc6-241b-4784-b814-86b93575a7e5',
        '821ceca8-585d-4a17-bae4-dec4c20395c3',
      ],
    },
    '20f29788-876a-4569-b426-209837d395ce': {
      id: '20f29788-876a-4569-b426-209837d395ce',
      name: 'Affichage défectueux ou pièce endommagée',
      imgsrc: '',
      childs: [
        'c1142dac-74d7-4720-9754-b745c5ce56b5',
        '2dec6c72-2b5b-4d10-93e4-b5496c5ac715',
        '05b78268-ad98-4a3f-b8ec-888508684cb3',
        '34f4e796-6aad-43ca-b0db-abceee7c3225',
        '80e0fa47-9962-4fab-870d-ab668a22f4e4',
        '7453ca85-4a32-4cff-817e-6df42cf1e25f',
        '7375079b-2891-475d-ab82-7bff20c5aae0',
        'b77860fe-41d3-4169-a978-26a32fdda5b0',
        'a163a06d-cc28-4ae6-a44d-0b89bdd2a00d',
      ],
    },
    'f50aa9c2-6301-42ad-872c-211c843cef14': {
      id: 'f50aa9c2-6301-42ad-872c-211c843cef14',
      name: 'Code erreur',
      imgsrc: '',
      childs: ['0ed44a3c-ec78-4a62-84e4-5cc940a71ae0'],
    },
    '041230b8-c309-4f3f-a5b8-ddc479c85851': {
      id: '041230b8-c309-4f3f-a5b8-ddc479c85851',
      name: 'Problème suite orage ou surtension',
      imgsrc: '',
      childs: ['535e30e2-3fd2-444f-9d4b-30900389ea15', 'cf5c4ab5-e10d-445a-92d8-ef5a9499a8da'],
    },
    'be5ae5d2-1d1b-4149-b4c6-f0db52abe666': {
      id: 'be5ae5d2-1d1b-4149-b4c6-f0db52abe666',
      name: 'Problème de cuisson',
      imgsrc: '',
      childs: [
        '134d00e5-17d8-4e04-9ff6-09f8f36c04d6',
        'febee6cd-db7e-488a-8b03-fb4b5ec788bf',
        'cf025b8d-0482-4140-90aa-2a068679e9b3',
        'cdaadcec-6798-42c5-bc60-665ca11bf488',
      ],
    },
    '0217b315-0187-4071-b3c6-88e96cde9bca': {
      id: '0217b315-0187-4071-b3c6-88e96cde9bca',
      name: 'Problème de mise en marche',
      imgsrc: '',
      childs: [
        '8790a31a-2f2c-4e41-84e0-e12cc60a59d0',
        '6e6d80e9-b73b-4f7c-8162-d3c504bc851a',
        '7ce73901-cdc7-49cb-88bb-a22e901ad163',
        'd29c9a4f-49d0-4e5c-8b21-7e87045d3bf0',
        '8defff2b-8bad-4dc7-beb9-9e0a52f40485',
        '6b210444-84b6-49c2-a4af-464047f1da6d',
      ],
    },
    '787c0106-0e99-4a6a-9399-47514e5ae6d6': {
      id: '787c0106-0e99-4a6a-9399-47514e5ae6d6',
      name: "Défaut d'affichage",
      imgsrc: '',
      childs: [
        '671cc239-df92-479e-a9ef-8242ffe39db1',
        'd8959d04-1f73-4c37-bc3b-726e4bb2933a',
        '376f397b-f648-40d8-ab61-035ef2e74d32',
        '5dd75241-2813-4b55-96ff-ced144298b54',
        'fb6f7ba6-ffc1-4f06-9e84-01cefe7feba6',
        'a142842c-5209-47fd-8be7-f3ce1dc78958',
        '95a3685e-9541-4733-9d88-183e2850e35f',
      ],
    },
    '571186f4-6c8d-4688-afd5-7fd53e832482': {
      id: '571186f4-6c8d-4688-afd5-7fd53e832482',
      name: 'Pièce endommagée',
      imgsrc: '',
    },
    '80eaf2b9-1c6c-4634-aaa0-fbfec22ef05c': {
      id: '80eaf2b9-1c6c-4634-aaa0-fbfec22ef05c',
      name: 'Bruit suspect',
      imgsrc: '',
    },
    '73196eb0-1dce-4f8d-a3e4-90399ceb2313': {
      id: '73196eb0-1dce-4f8d-a3e4-90399ceb2313',
      name: 'Odeur suspecte',
      imgsrc: '',
    },
    '42288a65-df1d-407e-9b7a-f327165047d2': {
      id: '42288a65-df1d-407e-9b7a-f327165047d2',
      name: 'Problème suite orage ou surtension',
      imgsrc: '',
    },
    '559dffc7-ef51-44a5-84db-5e857f452bf9': {
      id: '559dffc7-ef51-44a5-84db-5e857f452bf9',
      name: 'Autre problème',
      input: 'text',
      imgsrc: '',
    },
    'dd3d6698-5dcc-4abb-9695-a7d4e39fd3a7': {
      id: 'dd3d6698-5dcc-4abb-9695-a7d4e39fd3a7',
      name: 'Problème de température',
      imgsrc: '',
      childs: [
        '305e66f8-7750-447f-8ca6-ff915897e312',
        '17b590f1-53c0-430f-b6b0-badde597fbf8',
        'd632fd08-cd95-4761-8287-0fce6d7aae5f',
      ],
    },
    'e7283e10-1d36-4a90-b285-be479b8f7f15': {
      id: 'e7283e10-1d36-4a90-b285-be479b8f7f15',
      name: "Fuite d'eau",
      imgsrc: '',
      childs: [
        '103179d6-bf0b-4de4-ac03-335de61dabce',
        '87673e53-e62b-440c-b234-d4d9795352af',
        '6638dcc6-e4a5-4eb0-a2b0-bef56b826836',
      ],
    },
    '01be1923-1589-482b-a707-e3e0085bba2d': {
      id: '01be1923-1589-482b-a707-e3e0085bba2d',
      name: 'Bruit suspect',
      imgsrc: '',
      childs: [
        'bb45a3e4-ffaa-4030-b68d-cc02d599e644',
        '48c3c254-be84-4e91-b360-d2e960035cf9',
        'ce284ec7-1869-4ff7-87b4-7bc18940baef',
      ],
    },
    '41143efc-e536-471b-9a0a-ca93f97d84c1': {
      id: '41143efc-e536-471b-9a0a-ca93f97d84c1',
      name: 'Odeur suspecte',
      imgsrc: '',
      childs: [
        '8f755d7c-63b1-4491-b351-df9ac5825718',
        '38ee6906-438e-4286-84c0-26779117f6fe',
        'd2afa176-666b-4cd1-bfa0-d625896ba5c7',
        '0b367ca4-008f-4896-b315-a681e4e13e37',
      ],
    },
    '6e9635af-a099-4f6b-81ff-eacdae880586': {
      id: '6e9635af-a099-4f6b-81ff-eacdae880586',
      name: 'Problème de lumière, pièce cassée',
      imgsrc: '',
      childs: [
        'e0aa5f31-13aa-4ebb-aa43-f1b718df9932',
        'abf70233-8700-4543-9bc6-4cbbaf7273c6',
        '047296c9-cce6-4249-bdcc-cf0858415a52',
        'a211152f-1b76-4b5d-acbd-8dcc755c626e',
        'bcdf13cf-6144-4616-946a-959e53dd582d',
        '0ac165b8-8b73-494c-be81-4a2954e3030a',
        'e2509548-3fc1-42aa-b712-dfcd255dc2b5',
      ],
    },
    '49d6323b-2413-4c31-bf40-05b5542382bd': {
      id: '49d6323b-2413-4c31-bf40-05b5542382bd',
      name: 'Problème de mise en marche',
      imgsrc: '',
      childs: [
        '370dc504-5179-45aa-89e7-22171112e0e7',
        '3a1d9266-a89f-47d7-92ea-b38a419376e1',
        'af00c2f6-b362-41a1-9c8e-0cffb92dd064',
        '1e0c2ede-a1b5-4dd4-b774-d137a25410a8',
        '691119d1-7f7f-4498-88b2-d386289c548a',
      ],
    },
    '5601f538-7885-4313-a9d8-fbebc88acd68': {
      id: '5601f538-7885-4313-a9d8-fbebc88acd68',
      name: 'Problème suite orage ou surtension',
      imgsrc: '',
      childs: ['1237dc5e-568d-439f-84e2-3ff2977a73a2', '010ab40c-04c8-4ca9-afd8-82b309e169ba'],
    },
    '8cd14663-e17d-402c-a8d8-a614c4045987': { id: '8cd14663-e17d-402c-a8d8-a614c4045987', name: 'Autre', imgsrc: '' },
    'a50493b8-9ffa-4e39-a94b-fa66cf39a902': {
      id: 'a50493b8-9ffa-4e39-a94b-fa66cf39a902',
      name: 'Problème de cuisson',
      imgsrc: '',
      childs: [
        '3adbbc8c-729f-4dd3-9fde-8909b67832b8',
        'bf02b4c7-be00-4cf7-b7f2-3059d93d9468',
        'c996a611-ad47-40ca-99fa-545aa558d808',
        '77487e86-26a4-4ac0-a6a9-8a361ff16555',
      ],
    },
    'ebc7c74d-2e77-4d60-88a6-6ae325446830': {
      id: 'ebc7c74d-2e77-4d60-88a6-6ae325446830',
      name: 'Problème de mise en marche',
      imgsrc: '',
      childs: [
        '7804b1ee-b727-472d-9b0c-c73849d8ced0',
        '0c245891-f475-49e0-84d0-655b82783fc2',
        '40fc160a-cb81-489e-8bbd-4169d8b09d04',
        '94cf725e-6b79-4603-82cd-e04fdd63d80e',
        'edf7fd66-eb5e-4a7e-9836-330aa9deeab4',
        'abc1cfb8-e8a3-4b8f-83aa-bcf3f773465a',
      ],
    },
    'c98747d7-76e6-4fdb-a426-c0431f4f83a2': {
      id: 'c98747d7-76e6-4fdb-a426-c0431f4f83a2',
      name: "Défaut d'affichage",
      imgsrc: '',
      childs: [
        '0710aa7b-f3d0-408f-bd0e-fdf1bb0dd57a',
        '564eb4d4-e4d8-4fe1-ab5e-a1f3fad0bbd8',
        '9be68297-392e-4758-9e25-8fc5590f09db',
        '7d4d98ea-8459-4586-9ecf-3d96959d22c5',
        'e583daf6-ff2f-4a4d-baab-40a52dfb3743',
      ],
    },
    '60d3cf01-ea43-4454-a449-2a52ee8ebe16': {
      id: '60d3cf01-ea43-4454-a449-2a52ee8ebe16',
      name: 'Pièce endommagée',
      imgsrc: '',
    },
    '37e27a79-ffbd-4703-a1a8-850ec31eddbe': {
      id: '37e27a79-ffbd-4703-a1a8-850ec31eddbe',
      name: 'Bruit suspect',
      imgsrc: '',
    },
    '97c2afbc-119f-43d9-97fa-7a793024f615': {
      id: '97c2afbc-119f-43d9-97fa-7a793024f615',
      name: 'Odeur suspecte',
      imgsrc: '',
    },
    '81b8bd27-a467-4127-8783-4991ea3ff473': {
      id: '81b8bd27-a467-4127-8783-4991ea3ff473',
      name: 'Problème suite orage ou surtension',
      imgsrc: '',
    },
    '6f1d4a45-9ae5-437f-8137-16b1b9e6467b': {
      id: '6f1d4a45-9ae5-437f-8137-16b1b9e6467b',
      name: 'Autre problème',
      imgsrc: '',
    },
    'b77c244b-cb0b-4095-8690-c97c8ea27446': {
      id: 'b77c244b-cb0b-4095-8690-c97c8ea27446',
      name: 'Problème de cuisson',
      imgsrc: '',
      childs: [
        '7f799ac7-cd21-4691-ba96-37cf121a5aeb',
        '535afef5-b949-4e42-86e1-e1cc53018d69',
        'd316cf69-3058-4322-9c7d-4c4fa2efaaba',
        '08d9f97f-7d0d-40ff-9dac-c7bbb2dd8c94',
      ],
    },
    '57541f1d-c5c5-4fa8-8943-68d9cf151f15': {
      id: '57541f1d-c5c5-4fa8-8943-68d9cf151f15',
      name: 'Problème de mise en marche',
      imgsrc: '',
      childs: [
        '808ff4dd-4659-4f75-b4e4-1e1f27dafc39',
        '5f15919e-bd1d-43f2-bdb3-5e806971a114',
        'd2c70b5d-c0ef-4231-ada2-3266fec0b0f1',
        '9208795b-758b-48f8-9815-e4b620033eaf',
        'c17c03c4-65ab-40ff-b051-4c7bb85c5d94',
        '772c92f1-a93a-43a6-9fe3-e949ac9676a7',
      ],
    },
    'fa004e97-bbbe-49ea-9f68-011a57112a6d': {
      id: 'fa004e97-bbbe-49ea-9f68-011a57112a6d',
      name: "Défaut d'affichage",
      imgsrc: '',
      childs: [
        '3558d795-a4fe-4081-bdd5-140af2f40e7a',
        '4e3f76dd-976e-4e62-b761-5a00ee793a3e',
        '7cb627de-8dc6-488e-84cc-eb98578a86e9',
        '937721e6-2f88-4f08-bd83-b92b4ddb7548',
        'e2edd7cb-7ffb-48d7-8e11-4cf5505f11eb',
        'dfed2d4f-7bba-4947-affd-0076852317f0',
        '0e95b2fe-1678-4310-962b-96ed82dc5b8a',
      ],
    },
    'e3ba786e-f584-460c-87c5-cf007631c767': {
      id: 'e3ba786e-f584-460c-87c5-cf007631c767',
      name: 'Pièce endommagée',
      imgsrc: '',
    },
    'f6a02482-34a3-4c26-9ee6-a64c87cdad21': {
      id: 'f6a02482-34a3-4c26-9ee6-a64c87cdad21',
      name: 'Bruit suspect',
      imgsrc: '',
    },
    '8436f2a5-40d2-4e45-8bf5-fa8a7aa99e2e': {
      id: '8436f2a5-40d2-4e45-8bf5-fa8a7aa99e2e',
      name: 'Odeur suspecte',
      imgsrc: '',
    },
    'f591f3a8-06a7-49f2-9e44-b1fcdd909f48': {
      id: 'f591f3a8-06a7-49f2-9e44-b1fcdd909f48',
      name: 'Problème suite orage ou surtension',
      imgsrc: '',
    },
    '7b4c5da7-924d-44da-8c9c-ec572516f19b': {
      id: '7b4c5da7-924d-44da-8c9c-ec572516f19b',
      name: 'Autre problème',
      imgsrc: '',
    },
    'd1715369-0925-490f-aff0-f68d36fe18c2': {
      type: 'appliance',
      id: 'd1715369-0925-490f-aff0-f68d36fe18c2',
      name: 'Aspirateur',
      imgsrc: '',
    },
    '66b05113-366f-4fee-8a9e-8dc8b26d6cc8': {
      type: 'appliance',
      id: '66b05113-366f-4fee-8a9e-8dc8b26d6cc8',
      name: 'Nettoyeur',
      imgsrc: '',
    },
    'caab47e0-9e08-43f1-a6ac-0bfce7386889': {
      type: 'appliance',
      id: 'caab47e0-9e08-43f1-a6ac-0bfce7386889',
      name: 'Préparation du café',
      imgsrc: '',
    },
    '5acb8028-4a17-47c0-975f-ddb4a40fe773': {
      type: 'appliance',
      id: '5acb8028-4a17-47c0-975f-ddb4a40fe773',
      name: 'Machine à soda',
      imgsrc: '',
    },
    '25e1dd9f-4f80-4857-89cd-fad491237be4': {
      type: 'appliance',
      id: '25e1dd9f-4f80-4857-89cd-fad491237be4',
      name: 'Machine à glaçons',
      imgsrc: '',
    },
    '7f1e0f10-b36d-4d45-bb95-8322137ad068': {
      type: 'appliance',
      id: '7f1e0f10-b36d-4d45-bb95-8322137ad068',
      name: 'Tireuse à bière',
      imgsrc: '',
    },
    'd9421c9a-7ab6-446d-8eec-e01bb67e62af': {
      type: 'appliance',
      id: 'd9421c9a-7ab6-446d-8eec-e01bb67e62af',
      name: 'Préparation du thé',
      imgsrc: '',
    },
    'd86311fa-099d-4f82-9bb0-7f526160169b': {
      type: 'appliance',
      id: 'd86311fa-099d-4f82-9bb0-7f526160169b',
      name: 'Autre appareil',
      imgsrc: '',
    },
    'ed882b6d-5ca5-4904-a20b-54689682ba4f': {
      id: 'ed882b6d-5ca5-4904-a20b-54689682ba4f',
      name: "Problème d'image",
      imgsrc: '',
    },
    '4d687239-a8d3-4182-9b08-a7100e31c10d': {
      id: '4d687239-a8d3-4182-9b08-a7100e31c10d',
      name: 'Problème de son',
      imgsrc: '',
    },
    'fcd777c3-8c6e-46ba-a4ad-2546db1b0673': {
      id: 'fcd777c3-8c6e-46ba-a4ad-2546db1b0673',
      name: 'Problème de mise en marche',
      imgsrc: '',
    },
    '602abf97-adb7-40f2-a017-e90d1fa6c287': {
      id: '602abf97-adb7-40f2-a017-e90d1fa6c287',
      name: 'Problème de signal',
      imgsrc: '',
    },
    '20068839-8f03-4fd5-9a04-51b2f92f611a': {
      id: '20068839-8f03-4fd5-9a04-51b2f92f611a',
      name: 'Autre problème',
      imgsrc: '',
    },
    'c07ab84c-31ef-45fc-80ef-6f0cb6dafdcb': {
      id: 'c07ab84c-31ef-45fc-80ef-6f0cb6dafdcb',
      name: "Fuite au niveau de l'arrivée d'eau",
      imgsrc: '',
    },
    '9a51947c-13ca-477f-8e6a-52873c4ce653': {
      id: '9a51947c-13ca-477f-8e6a-52873c4ce653',
      name: 'Fuite au niveau de la cuve',
      imgsrc: '',
    },
    '65add05f-6fc3-4a7b-a822-722c72b6670d': {
      id: '65add05f-6fc3-4a7b-a822-722c72b6670d',
      name: 'Fuite au niveau du groupe de sécurité',
      imgsrc: '',
    },
    '8bbcf0e2-cb1f-41fa-b78a-c22c225d7315': {
      id: '8bbcf0e2-cb1f-41fa-b78a-c22c225d7315',
      name: 'Autre fuite',
      imgsrc: '',
    },
    '1bae3e13-54f7-482a-a7f5-608a160a99bc': {
      id: '1bae3e13-54f7-482a-a7f5-608a160a99bc',
      name: 'Problème de fuite',
      imgsrc: '',
      childs: ['74055348-d755-4458-80d2-0dd7543671d3', '9ea3fb60-616e-48f5-b5c0-4082de03f961'],
    },
    'c92a3674-3951-4cf4-bb7b-462eb863442f': {
      id: 'c92a3674-3951-4cf4-bb7b-462eb863442f',
      name: "Problème d'évacuation",
      imgsrc: '',
      childs: [
        '7656d14f-a206-4b68-b256-b2fb2dc77a42',
        'aa5113f6-d3c5-476e-804c-fffd73d2daf7',
        'b5a231f4-4f5c-4f3f-8303-443d3bf4294d',
      ],
    },
    '86cad50b-7612-43de-b06c-098f720bc0ef': {
      id: '86cad50b-7612-43de-b06c-098f720bc0ef',
      name: 'Autre problème',
      imgsrc: '',
    },
    '01c331aa-3d42-4fb7-9785-53b35ebe805d': {
      id: '01c331aa-3d42-4fb7-9785-53b35ebe805d',
      name: 'Problème de fuite',
      imgsrc: '',
      childs: ['2f861993-27b0-4d34-b210-f1e70d92e741', '5eee23fd-d00e-4427-a960-436975e17870'],
    },
    '7aedaa9a-c375-4dca-840d-e0061b5137b0': {
      id: '7aedaa9a-c375-4dca-840d-e0061b5137b0',
      name: "Problème d'évacuation",
      imgsrc: '',
      childs: [
        '00274476-7ea7-4b2b-ac8c-f844368aedf6',
        'ef7b8605-ef89-44a7-8a5b-0d491c6d8913',
        '994f49c2-8eab-4d64-a90a-212bf4ea388a',
      ],
    },
    '9f900172-06b3-406f-a4dc-f43a04cd3c0c': {
      id: '9f900172-06b3-406f-a4dc-f43a04cd3c0c',
      name: 'Autre problème',
      imgsrc: '',
    },
    'ff138d8a-9ad7-466b-ae12-2c7fb4b4f143': {
      id: 'ff138d8a-9ad7-466b-ae12-2c7fb4b4f143',
      name: 'Problème de fuite',
      imgsrc: '',
      childs: ['b481690d-2dbc-47f3-a290-ed4c9ae9435d', 'f11f0691-d62c-4762-bf88-c7bd604b72db'],
    },
    '4dd31efe-8bed-411d-9696-4def20895739': {
      id: '4dd31efe-8bed-411d-9696-4def20895739',
      name: "Problème d'évacuation",
      imgsrc: '',
      childs: [
        'b67b3071-170b-457f-8832-89d388fa1633',
        '5ef3281c-049a-42f2-bf15-37b6534ffbaf',
        '809f6d07-35ac-4980-a972-c8248be26082',
      ],
    },
    '512a4b1a-afda-4709-9458-0ae9644aa444': {
      id: '512a4b1a-afda-4709-9458-0ae9644aa444',
      name: 'Autre problème',
      imgsrc: '',
    },
    'cdef0253-8cc9-4c09-bc53-f1653a48c2d3': {
      id: 'cdef0253-8cc9-4c09-bc53-f1653a48c2d3',
      name: 'Problème de fuite',
      imgsrc: '',
      childs: [
        '6723e14f-554b-41c6-aab3-620beaa5bf9b',
        '74a29b99-c40b-49d7-a27e-8e931687f677',
        'd3ee3e57-a64c-4a55-b636-76c3a7186b88',
        '6e087f98-a4ec-413f-b519-cb0ef1b01586',
      ],
    },
    '732f34c1-aabd-49cd-b765-403f0dfaf54b': {
      id: '732f34c1-aabd-49cd-b765-403f0dfaf54b',
      name: "Problème d'évacuation",
      imgsrc: '',
      childs: [
        'd7aa9fde-f759-46e3-8582-5c93bf151f24',
        '0a9eff6e-9090-416d-b0fa-ebba59a44b7c',
        'f33553de-00cd-44e8-93e5-0e3079b4ef80',
      ],
    },
    'f4ad8998-7919-4c50-a126-9b1f110a1042': {
      id: 'f4ad8998-7919-4c50-a126-9b1f110a1042',
      name: 'Autre problème',
      imgsrc: '',
    },
    'e07805b2-f500-4daf-b0c1-18326bc97534': {
      id: 'e07805b2-f500-4daf-b0c1-18326bc97534',
      name: "Arrivée d'eau au compteur",
      imgsrc: '',
    },
    '789fa424-758c-42a7-98ff-52eccf194560': {
      id: '789fa424-758c-42a7-98ff-52eccf194560',
      name: 'Evacuation des eaux usées',
      imgsrc: '',
    },
    '1fe2b8a8-b8e3-469f-a311-bf267cfc97be': {
      id: '1fe2b8a8-b8e3-469f-a311-bf267cfc97be',
      name: "Canalisation d'eau",
      imgsrc: '',
    },
    '371c3149-b642-4cc7-8402-90c7bad15eef': {
      id: '371c3149-b642-4cc7-8402-90c7bad15eef',
      name: 'Autre problème de canalisation',
      imgsrc: '',
    },
    'c707db7c-17dc-4676-8c16-e07f057e98b3': {
      id: 'c707db7c-17dc-4676-8c16-e07f057e98b3',
      name: 'Problème de mise en marche',
      imgsrc: '',
    },
    'cffa1d11-3946-44d6-bec3-59947a50556c': {
      id: 'cffa1d11-3946-44d6-bec3-59947a50556c',
      name: "Chute dans l'eau/Liquide tombé dessus",
      imgsrc: '',
    },
    'b76298a6-3b7d-4385-b12a-a1bbf081afa5': {
      id: 'b76298a6-3b7d-4385-b12a-a1bbf081afa5',
      name: 'Autre problème',
      imgsrc: '',
    },
    '239fa075-1e80-41cf-97f9-a4fc056fb2dc': {
      id: '239fa075-1e80-41cf-97f9-a4fc056fb2dc',
      name: 'Ecran endommagé',
      imgsrc: '',
    },
    '9b2f27c9-2bb8-44d1-b360-7bb0d833dd91': {
      id: '9b2f27c9-2bb8-44d1-b360-7bb0d833dd91',
      name: 'Batterie défectueuse',
      imgsrc: '',
    },

    'bd8b7abb-675a-4d4a-ac9c-33ed7229aa31': {
      type: 'appliance',
      id: 'bd8b7abb-675a-4d4a-ac9c-33ed7229aa31',
      name: 'PC portable',
      imgsrc: '/icons/tunnel/laptop.svg',
      childs: [
        '0069cd87-d4ae-4d37-9337-1b0379304558',
        'd151fd29-3981-481c-90c3-61ba93d52206',
        '9afe8c9f-dcac-485e-a1a5-9e80073357d1',
        '26e789fe-7c18-46d2-9f3c-eb89fdeeff46',
        '717e8198-017b-4bf9-9968-fbb2199efe90',
        '3624ebb8-e42e-45ef-836b-e0a32a7b65d7',
        'c7465b56-839e-4503-a44f-7de8c11a2d5a',
        '37ac67f9-6259-47e3-bfac-ddcf1dfee0b5',
      ],
    },
    '53722da8-59fb-4b28-b1cb-1c2d2ca9235d': {
      type: 'appliance',
      id: '53722da8-59fb-4b28-b1cb-1c2d2ca9235d',
      name: 'PC de bureau',
      imgsrc: '/icons/tunnel/pc_bureau.svg',
      childs: [
        '9b01b634-dbf4-48b4-907e-d572fb0d7f63',
        '33cd5c7e-23b9-452d-884d-11c43744aac7',
        '38a58481-42d8-4783-ad62-46e0058a3ff8',
        'fef6ee89-fb82-4367-9a00-d6e2dc82c2ec',
        '087b3ed4-6c2d-43e3-a445-d400d3f8c4e2',
        '79cf3c9e-53cd-488a-ba48-b091426afb29',
        '26dfe423-a0c6-4949-b7b9-23b6c16015ec',
      ],
    },
    '15f16e49-171b-4d97-b16f-4a6270ed2804': {
      id: '15f16e49-171b-4d97-b16f-4a6270ed2804',
      name: 'Ecran endommagé',
      imgsrc: '',
    },
    '94a5b5cc-9acc-4bf1-b15c-4217913cee56': {
      id: '94a5b5cc-9acc-4bf1-b15c-4217913cee56',
      name: 'Batterie défectueuse',
      imgsrc: '',
    },
    '0b828828-b4fb-4816-b600-a7a2df169423': {
      id: '0b828828-b4fb-4816-b600-a7a2df169423',
      name: 'Problème de mise en marche',
      imgsrc: '',
    },
    '4e19ebad-54df-4f2c-ba0d-505789ffd757': {
      id: '4e19ebad-54df-4f2c-ba0d-505789ffd757',
      name: 'Problème de connexion',
      imgsrc: '',
    },
    '34dafd7e-c51a-4c73-b761-39773cde46bc': {
      id: '34dafd7e-c51a-4c73-b761-39773cde46bc',
      name: "Chute dans l'eau/Liquide tombé dessus",
      imgsrc: '',
    },
    '0543c72f-51bf-4205-8cfb-0c61bccbe40a': {
      id: '0543c72f-51bf-4205-8cfb-0c61bccbe40a',
      name: 'Autre problème',
      imgsrc: '',
    },
    'a110d47c-c30c-469b-872b-59276225f9a1': {
      id: 'a110d47c-c30c-469b-872b-59276225f9a1',
      name: 'Ecran endommagé',
      imgsrc: '',
    },
    'baa713e6-9cce-4420-a762-eeda3098924e': {
      id: 'baa713e6-9cce-4420-a762-eeda3098924e',
      name: 'Batterie défectueuse',
      imgsrc: '',
    },
    '588ce31c-e45c-443a-853b-c93c4937a118': {
      id: '588ce31c-e45c-443a-853b-c93c4937a118',
      name: 'Problème de mise en marche',
      imgsrc: '',
    },
    'e9e31f6f-8e0a-4cb5-8048-b8d7c6d8d56d': {
      id: 'e9e31f6f-8e0a-4cb5-8048-b8d7c6d8d56d',
      name: "Chute dans l'eau/Liquide tombé dessus",
      imgsrc: '',
    },
    '2107c6cc-7443-42f5-95c1-220c74023f6a': {
      id: '2107c6cc-7443-42f5-95c1-220c74023f6a',
      name: 'Autre problème',
      imgsrc: '',
    },
    'cc0808d4-f0dd-4512-9b33-0e22ae825826': {
      id: 'cc0808d4-f0dd-4512-9b33-0e22ae825826',
      name: 'Antenne endommagée',
      imgsrc: '',
    },
    'b60e3c5c-c7aa-48ea-bcf0-dfe14492139c': {
      id: 'b60e3c5c-c7aa-48ea-bcf0-dfe14492139c',
      name: 'Câble endommagé',
      imgsrc: '',
    },
    'a6f38873-b264-4455-a390-625cfbb07df9': {
      id: 'a6f38873-b264-4455-a390-625cfbb07df9',
      name: 'Pas de réception',
      imgsrc: '',
    },
    '91aae8c9-a7e1-49c4-a103-4555e1e7da8d': {
      id: '91aae8c9-a7e1-49c4-a103-4555e1e7da8d',
      name: 'Autre problème',
      imgsrc: '',
    },
    '5fc1898a-a397-4b06-92fc-4c998dd87fad': {
      id: '5fc1898a-a397-4b06-92fc-4c998dd87fad',
      name: 'Antenne endommagée',
      imgsrc: '',
    },
    'b9e93831-8183-42b0-856b-987a7427ea48': {
      id: 'b9e93831-8183-42b0-856b-987a7427ea48',
      name: 'Câble endommagée',
      imgsrc: '',
    },
    '11d7179b-a3dc-4543-a31b-98fd400167f3': {
      id: '11d7179b-a3dc-4543-a31b-98fd400167f3',
      name: 'Pas de réception',
      imgsrc: '',
    },
    '379a4986-b0bf-4f0e-86d9-67bc6486a6b6': {
      id: '379a4986-b0bf-4f0e-86d9-67bc6486a6b6',
      name: 'Autre problème',
      imgsrc: '',
    },
    'c547dc8b-3681-4287-8493-5774f1d477cc': {
      type: 'appliance',
      id: 'c547dc8b-3681-4287-8493-5774f1d477cc',
      name: 'Fibre',
      imgsrc: '',
      childs: [
        '62f8d47b-0205-4caf-b516-88b303ddeb3c',
        '760d8e2b-ad53-4ed0-8f37-1ebb4d75a406',
        '5bc8deff-f5a9-4ddc-b184-ba3d13224095',
        '0e636e41-e438-474e-b127-d0df22042e70',
      ],
    },
    'b0fded4b-369c-420e-bc53-3e54eb726982': {
      type: 'appliance',
      id: 'b0fded4b-369c-420e-bc53-3e54eb726982',
      name: 'ADSL',
      imgsrc: '',
      childs: [
        '799853a8-5a67-42e0-958d-3a1e432485a2',
        '99d3b2ff-976f-4c37-8ceb-cb8b644c157c',
        'c7bb6d4f-9e7d-4ad0-9f6e-e4da72c7ad65',
        '4c7f4ba8-307d-4772-a265-fe7e1b9b3fe8',
      ],
    },
    'fb22b3db-ae06-4946-b04d-9d8aa999cad3': {
      type: 'appliance',
      id: 'fb22b3db-ae06-4946-b04d-9d8aa999cad3',
      name: 'Internet par satellite',
      imgsrc: '',
      childs: [
        '72a4d21a-7432-429f-90c3-4cd6d9e67a67',
        '7d0eded1-f243-41ef-8fe3-953dcc451e90',
        'f0945c20-cb24-4445-bc8a-87d3b487a5c1',
        '841b91ac-36ab-43c3-8fd7-ca562614418c',
      ],
    },
    '757cec5e-67b0-4aa7-ad7f-21d0ba78141e': {
      type: 'appliance',
      id: '757cec5e-67b0-4aa7-ad7f-21d0ba78141e',
      name: 'Autre',
      imgsrc: '',
    },
    'e184ad45-0830-4ff1-8fb4-36aafaeee77a': {
      id: 'e184ad45-0830-4ff1-8fb4-36aafaeee77a',
      name: "Pas d'arrivée d'eau",
      imgsrc: '',
    },
    'ab5a81ce-19a1-4bf6-8e37-edcf431b1f23': {
      id: 'ab5a81ce-19a1-4bf6-8e37-edcf431b1f23',
      name: "Fuite au niveau de l'arrivée d'eau",
      imgsrc: '',
    },
    'a7e5703b-ea2c-4208-b99d-97b98ab2e04a': {
      id: 'a7e5703b-ea2c-4208-b99d-97b98ab2e04a',
      name: "Fuite au niveau de l'évacuation d'eau",
      imgsrc: '',
    },
    '26539943-fb3a-4907-a1f1-a1f35a3f3cf0': {
      id: '26539943-fb3a-4907-a1f1-a1f35a3f3cf0',
      name: 'Fuite au niveau du bac à produit',
      imgsrc: '',
    },
    '53b6f8ff-7be4-4653-8f31-5045970738b3': {
      id: '53b6f8ff-7be4-4653-8f31-5045970738b3',
      name: 'Fuite en début de cycle',
      imgsrc: '',
    },
    'de79d149-c3b3-4961-baca-2d24a8313274': {
      id: 'de79d149-c3b3-4961-baca-2d24a8313274',
      name: 'Fuite en cours de lavage',
      imgsrc: '',
    },
    '878c2b09-49b7-46cb-9f95-63030db1719d': {
      id: '878c2b09-49b7-46cb-9f95-63030db1719d',
      name: 'Fuite en fin de cycle',
      imgsrc: '',
    },
    'c2bdb1f6-b5af-4c01-a01c-40edfce72f0c': {
      id: 'c2bdb1f6-b5af-4c01-a01c-40edfce72f0c',
      name: 'Autre fuite',
      imgsrc: '',
    },
    '809747c2-7aa4-4d71-b603-081ffe7fdf70': {
      id: '809747c2-7aa4-4d71-b603-081ffe7fdf70',
      name: "N'essore pas",
      imgsrc: '',
    },
    '62b5e2a9-d620-4e07-8f99-f24768bbc349': {
      id: '62b5e2a9-d620-4e07-8f99-f24768bbc349',
      name: 'Ne vidange pas',
      imgsrc: '',
    },
    '52feeb4f-b8a5-41ae-9270-dd3d997b6d88': {
      id: '52feeb4f-b8a5-41ae-9270-dd3d997b6d88',
      name: "Autre problème d'essorage, de vidange",
      imgsrc: '',
    },
    'b1713c24-be6b-4966-835f-b96a40affc4f': {
      id: 'b1713c24-be6b-4966-835f-b96a40affc4f',
      name: "Bruit a l'essorage",
      imgsrc: '',
    },
    '42241661-e14e-4527-b60f-8643da1076a2': {
      id: '42241661-e14e-4527-b60f-8643da1076a2',
      name: 'Bruit a la vidange',
      imgsrc: '',
    },
    'ab4233e9-bb5a-42c8-b34f-a44b22892d1a': {
      id: 'ab4233e9-bb5a-42c8-b34f-a44b22892d1a',
      name: 'Bruit de frottement',
      imgsrc: '',
    },
    'e5f32165-85d6-4928-a804-305efb22a460': {
      id: 'e5f32165-85d6-4928-a804-305efb22a460',
      name: 'Autre bruit',
      imgsrc: '',
    },
    '84cea3fb-a22d-4232-85a5-bab795a57b67': {
      id: '84cea3fb-a22d-4232-85a5-bab795a57b67',
      name: 'Linge pas propre',
      imgsrc: '',
    },
    '2cd67d5f-540f-4442-9c78-0907ddfcd862': {
      id: '2cd67d5f-540f-4442-9c78-0907ddfcd862',
      name: 'Tâches sur le linge',
      imgsrc: '',
    },
    'face0d2d-bc39-4e98-993a-5ceb0604008b': {
      id: 'face0d2d-bc39-4e98-993a-5ceb0604008b',
      name: 'Linge endommagé',
      imgsrc: '',
    },
    'a6b626bf-3edc-4097-b426-db64aad6a7de': {
      id: 'a6b626bf-3edc-4097-b426-db64aad6a7de',
      name: 'Autre problème de qualité de lavage',
      imgsrc: '',
    },
    '2a798635-3f6d-41d3-bbbc-aeed237fcdce': {
      id: '2a798635-3f6d-41d3-bbbc-aeed237fcdce',
      name: 'Odeur de moisissure',
      imgsrc: '',
    },
    'c0cfe2ce-66db-4a89-ad95-907204d84f73': {
      id: 'c0cfe2ce-66db-4a89-ad95-907204d84f73',
      name: 'Odeur de brulé',
      imgsrc: '',
    },
    '1df6b349-dad2-4a08-bd66-36dbda1c0b86': {
      id: '1df6b349-dad2-4a08-bd66-36dbda1c0b86',
      name: 'Odeur de plastique',
      imgsrc: '',
    },
    'e5f03f70-e30f-4496-9b61-d673f85faf3d': {
      id: 'e5f03f70-e30f-4496-9b61-d673f85faf3d',
      name: 'Autre odeur',
      imgsrc: '',
    },
    '5a1f4601-55ad-4902-9e78-821ac1ea30f6': {
      id: '5a1f4601-55ad-4902-9e78-821ac1ea30f6',
      name: 'Châssis exterieur',
      imgsrc: '',
    },
    '56c47fc9-43c5-4624-932a-eaa9479ce8f6': {
      id: '56c47fc9-43c5-4624-932a-eaa9479ce8f6',
      name: 'Poignée endommagée',
      imgsrc: '',
    },
    '2d49fdb4-1cc4-4872-bbf5-5b63c27a3663': {
      id: '2d49fdb4-1cc4-4872-bbf5-5b63c27a3663',
      name: 'Bouton/bandeau/selecteur endommagé',
      imgsrc: '',
    },
    '4ba464b3-c407-4606-987d-136e83682054': {
      id: '4ba464b3-c407-4606-987d-136e83682054',
      name: 'Porte/portillon/hublot endommagé',
      imgsrc: '',
    },
    '4b012a88-83c0-4a40-9e66-2907fcad781e': {
      id: '4b012a88-83c0-4a40-9e66-2907fcad781e',
      name: 'Joint endommagé',
      imgsrc: '',
    },
    '51268b12-fb9f-43ba-b591-a00c11a83709': {
      id: '51268b12-fb9f-43ba-b591-a00c11a83709',
      name: 'Câble/tuyau endommagé',
      imgsrc: '',
    },
    '142976d5-019d-4002-8edc-153cfb8d00d8': {
      id: '142976d5-019d-4002-8edc-153cfb8d00d8',
      name: 'Autre pièce endommagée',
      imgsrc: '',
    },
    '16ef4e98-2461-450d-b1af-ad5ba9ac0978': {
      id: '16ef4e98-2461-450d-b1af-ad5ba9ac0978',
      name: "Pas d'alimentation",
      imgsrc: '',
    },
    '64397c05-ec24-40d4-8139-d5d1f4c2043e': {
      id: '64397c05-ec24-40d4-8139-d5d1f4c2043e',
      name: "Ne s'éteint pas",
      imgsrc: '',
    },
    '95871eb3-e6ea-4ca3-bdc9-cdefb30c8c3b': {
      id: '95871eb3-e6ea-4ca3-bdc9-cdefb30c8c3b',
      name: 'Sous tension mais pas de fonctionnement',
      imgsrc: '',
    },
    'd75f9303-3207-4411-83c8-698b61326fa0': {
      id: 'd75f9303-3207-4411-83c8-698b61326fa0',
      name: 'Marche/arrêt intermittent',
      imgsrc: '',
    },
    '14369373-fcd7-49c5-9606-289cbd92a1ac': {
      id: '14369373-fcd7-49c5-9606-289cbd92a1ac',
      name: 'Fait disjoncter',
      imgsrc: '',
    },
    '9475f9c5-edcc-47d6-a751-00a12b6548de': {
      id: '9475f9c5-edcc-47d6-a751-00a12b6548de',
      name: "Autre problème d'alimentation",
      imgsrc: '',
    },
    '91d9941a-2584-47a8-b0f5-60345066db3b': {
      id: '91d9941a-2584-47a8-b0f5-60345066db3b',
      name: 'Entrez votre code erreur',
      imgsrc: '',
    },
    '12bba103-7663-495a-b035-1b0896fe02e8': {
      id: '12bba103-7663-495a-b035-1b0896fe02e8',
      name: "Plus d'alimentation suite orage/surtension",
      imgsrc: '',
    },
    '8fd59eea-5ef7-470b-bd07-df1e237ef315': {
      id: '8fd59eea-5ef7-470b-bd07-df1e237ef315',
      name: 'Fonctionnement perturbé suite orage/surtension',
      imgsrc: '',
    },
    '68dbc372-8240-4169-a46d-c3d5e0eac1ef': {
      id: '68dbc372-8240-4169-a46d-c3d5e0eac1ef',
      name: 'Quel problème rencontrez vous ?',
      imgsrc: '',
    },
    'c531951c-57de-4c22-aa54-ba2f0dd7bd8c': {
      id: 'c531951c-57de-4c22-aa54-ba2f0dd7bd8c',
      name: 'Pas de froid',
      imgsrc: '',
    },
    '300c30d4-7b79-4536-93ab-f58cccdb4ac0': {
      id: '300c30d4-7b79-4536-93ab-f58cccdb4ac0',
      name: 'Pas de congélation',
      imgsrc: '',
    },
    '4331ea36-7bdd-419c-9864-15248a64fdf4': {
      id: '4331ea36-7bdd-419c-9864-15248a64fdf4',
      name: 'Pas de dégivrage',
      imgsrc: '',
    },
    '75e35db6-0db8-44d6-aba4-bdcc762b7d00': {
      id: '75e35db6-0db8-44d6-aba4-bdcc762b7d00',
      name: 'Pas de ventilation',
      imgsrc: '',
    },
    '297361b9-de1f-451a-a72a-9ebfd7d7e4c8': {
      id: '297361b9-de1f-451a-a72a-9ebfd7d7e4c8',
      name: "La ventilation ne s'arrête pas",
      imgsrc: '',
    },
    '10ddb143-0af3-44e5-b327-3d2909c125a3': {
      id: '10ddb143-0af3-44e5-b327-3d2909c125a3',
      name: 'Pas de production de glaçons',
      imgsrc: '',
    },
    '0dd5fb64-467d-4680-83cc-c220599f5b59': {
      id: '0dd5fb64-467d-4680-83cc-c220599f5b59',
      name: "Pas de production d'eau fraiche",
      imgsrc: '',
    },
    '393eac5b-d8ed-4804-bbf2-f39d63949825': {
      id: '393eac5b-d8ed-4804-bbf2-f39d63949825',
      name: "Fuite par l'avant",
      imgsrc: '',
    },
    'c5091844-5186-4687-a66a-b8c0c7828082': {
      id: 'c5091844-5186-4687-a66a-b8c0c7828082',
      name: "Fuite par l'arrière",
      imgsrc: '',
    },
    '1c840108-7afd-4877-a010-d0d4d4d5c3be': {
      id: '1c840108-7afd-4877-a010-d0d4d4d5c3be',
      name: 'Fuite du bac à glaçons',
      imgsrc: '',
    },
    'cc842362-9027-4df6-bb8b-dbffcf818cbc': {
      id: 'cc842362-9027-4df6-bb8b-dbffcf818cbc',
      name: "Fuite de l'arrivée d'eau",
      imgsrc: '',
    },
    'e5405a4d-b83f-4b8f-acdc-e1af432e8532': {
      id: 'e5405a4d-b83f-4b8f-acdc-e1af432e8532',
      name: 'Autre fuite',
      imgsrc: '',
    },
    '36761cb6-7fff-4690-81c6-1b3a062d127d': {
      id: '36761cb6-7fff-4690-81c6-1b3a062d127d',
      name: 'Bruit au niveau du circuit de refroidissement',
      imgsrc: '',
    },
    '44f26d7c-b4ed-4dab-ace6-3b7a9d3b0fd5': {
      id: '44f26d7c-b4ed-4dab-ace6-3b7a9d3b0fd5',
      name: 'Bruit au niveau du moteur',
      imgsrc: '',
    },
    '64b199ba-714e-4b94-a737-acbc3b648b25': {
      id: '64b199ba-714e-4b94-a737-acbc3b648b25',
      name: 'Autre bruit',
      imgsrc: '',
    },
    'ba621c9e-f745-462b-948b-2ed5e7f1fcee': {
      id: 'ba621c9e-f745-462b-948b-2ed5e7f1fcee',
      name: 'Odeur de moisissure',
      imgsrc: '',
    },
    'c823aee1-23c9-48c9-a944-7a010622311b': {
      id: 'c823aee1-23c9-48c9-a944-7a010622311b',
      name: 'Odeur de brulé',
      imgsrc: '',
    },
    'dba4f734-3699-4bb6-a60c-17fc4072b116': {
      id: 'dba4f734-3699-4bb6-a60c-17fc4072b116',
      name: 'Odeur de plastique',
      imgsrc: '',
    },
    '54a696f0-47ee-4db9-aca3-0d536e8d71b8': {
      id: '54a696f0-47ee-4db9-aca3-0d536e8d71b8',
      name: 'Autre odeur',
      imgsrc: '',
    },
    '99f80f49-bd4d-4ada-b6aa-6833c63d3406': {
      id: '99f80f49-bd4d-4ada-b6aa-6833c63d3406',
      name: 'Parroie intérieure endommagée',
      imgsrc: '',
    },
    '86810bd3-bb05-4e1c-95d0-3a9fb01b909f': {
      id: '86810bd3-bb05-4e1c-95d0-3a9fb01b909f',
      name: 'Chassis extérieur endommagé',
      imgsrc: '',
    },
    '95f97a65-e248-4789-81e8-11fbdf9c6503': {
      id: '95f97a65-e248-4789-81e8-11fbdf9c6503',
      name: 'Poignée endommagée',
      imgsrc: '',
    },
    'd328afb3-5066-4123-b9c9-a229c6197b60': {
      id: 'd328afb3-5066-4123-b9c9-a229c6197b60',
      name: 'Clayette endommagée',
      imgsrc: '',
    },
    '1c59bcb4-d139-4806-9ed3-59949e82738d': {
      id: '1c59bcb4-d139-4806-9ed3-59949e82738d',
      name: 'Balconnet endommagé',
      imgsrc: '',
    },
    '8ad24cfc-0392-4990-b3d4-4d6ca2f9b04c': {
      id: '8ad24cfc-0392-4990-b3d4-4d6ca2f9b04c',
      name: 'Pas de lumière',
      imgsrc: '',
    },
    '56c3d2f3-0ef9-4132-b753-7ddf66af5191': {
      id: '56c3d2f3-0ef9-4132-b753-7ddf66af5191',
      name: 'Autre pièce défectueuse',
      imgsrc: '',
    },
    '490be1f4-f1e7-4a2c-81c1-1e69d3434583': {
      id: '490be1f4-f1e7-4a2c-81c1-1e69d3434583',
      name: "Pas d'alimentation",
      imgsrc: '',
    },
    'e2d8fc39-aab5-49a1-8ba4-77dba3736b9f': {
      id: 'e2d8fc39-aab5-49a1-8ba4-77dba3736b9f',
      name: 'Le moteur fonctionne sans cesse',
      imgsrc: '',
    },
    'e8c49ea5-3966-4225-8e6e-1ac74b833f0e': {
      id: 'e8c49ea5-3966-4225-8e6e-1ac74b833f0e',
      name: 'Sous tension mais pas de fonctionnement',
      imgsrc: '',
    },
    '62f020fa-dc5e-40db-904e-651f85245181': {
      id: '62f020fa-dc5e-40db-904e-651f85245181',
      name: 'Fait disjonter',
      imgsrc: '',
    },
    'ca9784d6-3218-4499-8f28-6a963d5b4237': {
      id: 'ca9784d6-3218-4499-8f28-6a963d5b4237',
      name: 'Autre problème',
      imgsrc: '',
    },
    'b4fe23b7-847e-4705-b205-bd323fa4d5c3': {
      id: 'b4fe23b7-847e-4705-b205-bd323fa4d5c3',
      name: "Plus d'alimentation suite orage ou surtension",
      imgsrc: '',
    },
    '9e513183-fcda-4aa8-9239-41471b0d62e1': {
      id: '9e513183-fcda-4aa8-9239-41471b0d62e1',
      name: 'Fonctionnement perturbé suite orage ou surtension',
      imgsrc: '',
    },
    '66a5a483-dbc3-4d3a-87d8-5d5468aa37ef': {
      id: '66a5a483-dbc3-4d3a-87d8-5d5468aa37ef',
      name: "Pas d'alimentation",
      imgsrc: '',
    },
    'ab4688ea-7af6-4931-a2c2-b242d49fca06': {
      id: 'ab4688ea-7af6-4931-a2c2-b242d49fca06',
      name: "Ne s'éteint pas",
      imgsrc: '',
    },
    '953a0c59-536c-4181-8ff0-65b0f9fc20ab': {
      id: '953a0c59-536c-4181-8ff0-65b0f9fc20ab',
      name: 'Sous tension mais pas de fonctionnement',
      imgsrc: '',
    },
    'daeaafa4-7d0b-4b7a-8a11-b2ea7d102104': {
      id: 'daeaafa4-7d0b-4b7a-8a11-b2ea7d102104',
      name: 'Marche arrêt intermittent',
      imgsrc: '',
    },
    '6a918149-d700-4ced-9896-477e4c501213': {
      id: '6a918149-d700-4ced-9896-477e4c501213',
      name: 'Fait disjoncter',
      imgsrc: '',
    },
    '39276de3-400f-4a24-8053-c3e8c95074c6': {
      id: '39276de3-400f-4a24-8053-c3e8c95074c6',
      name: "Autre problème d'alimentation",
      imgsrc: '',
    },
    '252cf381-6119-49c5-bbd7-6e4f11c2d33f': {
      id: '252cf381-6119-49c5-bbd7-6e4f11c2d33f',
      name: "Ne vidange pas l'eau ",
      imgsrc: '',
    },
    '7262bc6a-bfee-4823-8351-f609ec2c30ee': {
      id: '7262bc6a-bfee-4823-8351-f609ec2c30ee',
      name: "Fuite au niveau de l'arrivée d'eau",
      imgsrc: '',
    },
    'c4e19da2-c492-4ddb-92c9-75f879f7a685': {
      id: 'c4e19da2-c492-4ddb-92c9-75f879f7a685',
      name: "Fuite au niveau de l'évacuation d'eau",
      imgsrc: '',
    },
    '08f17afc-43fb-4995-b5b2-310cd383a9b3': {
      id: '08f17afc-43fb-4995-b5b2-310cd383a9b3',
      name: "Fuite sous l'appareil",
      imgsrc: '',
    },
    'fb9c184a-30a6-4472-960f-153c17efc1d1': {
      id: 'fb9c184a-30a6-4472-960f-153c17efc1d1',
      name: 'Autre fuite',
      imgsrc: '',
    },
    '4927388a-462e-4e1f-9866-eac9735a200b': {
      id: '4927388a-462e-4e1f-9866-eac9735a200b',
      name: 'Vaisselle sale',
      imgsrc: '',
    },
    'eacfbe28-db19-4d94-a80d-1fdadc6ccd67': {
      id: 'eacfbe28-db19-4d94-a80d-1fdadc6ccd67',
      name: 'Traces blanches sur la vaisselle',
      imgsrc: '',
    },
    '2a82e2e8-e8e4-4162-a568-676eb186b66d': {
      id: '2a82e2e8-e8e4-4162-a568-676eb186b66d',
      name: 'Vaisselle grasse',
      imgsrc: '',
    },
    'e6db1fba-a7b2-40dd-b63b-ec0ee854afd9': {
      id: 'e6db1fba-a7b2-40dd-b63b-ec0ee854afd9',
      name: 'Rouille sur couverts',
      imgsrc: '',
    },
    '853292f1-231e-4c64-a9e3-4936f44da124': {
      id: '853292f1-231e-4c64-a9e3-4936f44da124',
      name: 'Autre problème de lavage',
      imgsrc: '',
    },
    'cfb7f305-1388-41c6-86e3-11da8116f034': {
      id: 'cfb7f305-1388-41c6-86e3-11da8116f034',
      name: 'Bruit lors du remplissage',
      imgsrc: '',
    },
    '5431d32f-6561-4b19-aae0-002e32232144': {
      id: '5431d32f-6561-4b19-aae0-002e32232144',
      name: 'Bruit lors du lavage',
      imgsrc: '',
    },
    '778fece1-750d-4e8b-b409-ff0ebe688a87': {
      id: '778fece1-750d-4e8b-b409-ff0ebe688a87',
      name: 'Bruit lors de la vidange',
      imgsrc: '',
    },
    '345056c3-7a29-4ce9-a277-9cfcc8bfaa72': {
      id: '345056c3-7a29-4ce9-a277-9cfcc8bfaa72',
      name: 'Autre bruit suspect',
      imgsrc: '',
    },
    '301fedeb-2a4e-461d-8352-39c92d89afd7': {
      id: '301fedeb-2a4e-461d-8352-39c92d89afd7',
      name: 'Odeur de moisissure',
      imgsrc: '',
    },
    '4e7314f0-73f6-4ad8-8558-ffdf4e14a052': {
      id: '4e7314f0-73f6-4ad8-8558-ffdf4e14a052',
      name: 'Odeur de brûlé',
      imgsrc: '',
    },
    '48dbadc6-241b-4784-b814-86b93575a7e5': {
      id: '48dbadc6-241b-4784-b814-86b93575a7e5',
      name: 'Odeur de plastique',
      imgsrc: '',
    },
    '821ceca8-585d-4a17-bae4-dec4c20395c3': {
      id: '821ceca8-585d-4a17-bae4-dec4c20395c3',
      name: 'Autre odeur',
      imgsrc: '',
    },
    'c1142dac-74d7-4720-9754-b745c5ce56b5': {
      id: 'c1142dac-74d7-4720-9754-b745c5ce56b5',
      name: 'Affichage défectueux',
      imgsrc: '',
    },
    '2dec6c72-2b5b-4d10-93e4-b5496c5ac715': {
      id: '2dec6c72-2b5b-4d10-93e4-b5496c5ac715',
      name: 'Bouton, sélecteur endommagé',
      imgsrc: '',
    },
    '05b78268-ad98-4a3f-b8ec-888508684cb3': {
      id: '05b78268-ad98-4a3f-b8ec-888508684cb3',
      name: "Tuyau d'arrivée d'eau endommagé",
      imgsrc: '',
    },
    '34f4e796-6aad-43ca-b0db-abceee7c3225': {
      id: '34f4e796-6aad-43ca-b0db-abceee7c3225',
      name: "Tuyau d'évacuation d'eau défectueux",
      imgsrc: '',
    },
    '80e0fa47-9962-4fab-870d-ab668a22f4e4': {
      id: '80e0fa47-9962-4fab-870d-ab668a22f4e4',
      name: 'Châssis extérieur endommagé',
      imgsrc: '',
    },
    '7453ca85-4a32-4cff-817e-6df42cf1e25f': {
      id: '7453ca85-4a32-4cff-817e-6df42cf1e25f',
      name: 'Porte endommagée',
      imgsrc: '',
    },
    '7375079b-2891-475d-ab82-7bff20c5aae0': {
      id: '7375079b-2891-475d-ab82-7bff20c5aae0',
      name: 'Joint endommagé',
      imgsrc: '',
    },
    'b77860fe-41d3-4169-a978-26a32fdda5b0': {
      id: 'b77860fe-41d3-4169-a978-26a32fdda5b0',
      name: 'Panier vaisselle endommagé',
      imgsrc: '',
    },
    'a163a06d-cc28-4ae6-a44d-0b89bdd2a00d': {
      id: 'a163a06d-cc28-4ae6-a44d-0b89bdd2a00d',
      name: 'Autre pièce endommagée',
      imgsrc: '',
    },
    '0ed44a3c-ec78-4a62-84e4-5cc940a71ae0': {
      id: '0ed44a3c-ec78-4a62-84e4-5cc940a71ae0',
      name: 'Entrez votre code erreur',
      imgsrc: '',
    },
    '535e30e2-3fd2-444f-9d4b-30900389ea15': {
      id: '535e30e2-3fd2-444f-9d4b-30900389ea15',
      name: "Plus d'alimentation suite orage ou surtension",
      imgsrc: '',
    },
    'cf5c4ab5-e10d-445a-92d8-ef5a9499a8da': {
      id: 'cf5c4ab5-e10d-445a-92d8-ef5a9499a8da',
      name: 'Fonctionnement perturbé suite orage ou surtension',
      imgsrc: '',
    },
    '134d00e5-17d8-4e04-9ff6-09f8f36c04d6': {
      id: '134d00e5-17d8-4e04-9ff6-09f8f36c04d6',
      name: 'Ne chauffe pas',
      imgsrc: '',
    },
    'febee6cd-db7e-488a-8b03-fb4b5ec788bf': {
      id: 'febee6cd-db7e-488a-8b03-fb4b5ec788bf',
      name: 'Chauffe insuffisamment',
      imgsrc: '',
    },
    'cf025b8d-0482-4140-90aa-2a068679e9b3': {
      id: 'cf025b8d-0482-4140-90aa-2a068679e9b3',
      name: 'Chauffe excessivement',
      imgsrc: '',
    },
    'cdaadcec-6798-42c5-bc60-665ca11bf488': {
      id: 'cdaadcec-6798-42c5-bc60-665ca11bf488',
      name: 'Autre problème de chauffe',
      imgsrc: '',
    },
    '8790a31a-2f2c-4e41-84e0-e12cc60a59d0': {
      id: '8790a31a-2f2c-4e41-84e0-e12cc60a59d0',
      name: "Pas d'alimentation",
      imgsrc: '',
    },
    '6e6d80e9-b73b-4f7c-8162-d3c504bc851a': {
      id: '6e6d80e9-b73b-4f7c-8162-d3c504bc851a',
      name: "Ne s'éteint pas",
      imgsrc: '',
    },
    '7ce73901-cdc7-49cb-88bb-a22e901ad163': {
      id: '7ce73901-cdc7-49cb-88bb-a22e901ad163',
      name: 'Sous tension mais ne démarre pas ',
      imgsrc: '',
    },
    'd29c9a4f-49d0-4e5c-8b21-7e87045d3bf0': {
      id: 'd29c9a4f-49d0-4e5c-8b21-7e87045d3bf0',
      name: 'Marche arrêt intermittent',
      imgsrc: '',
    },
    '8defff2b-8bad-4dc7-beb9-9e0a52f40485': {
      id: '8defff2b-8bad-4dc7-beb9-9e0a52f40485',
      name: 'Fait disjoncter',
      imgsrc: '',
    },
    '6b210444-84b6-49c2-a4af-464047f1da6d': {
      id: '6b210444-84b6-49c2-a4af-464047f1da6d',
      name: 'Autre problème de fonctionnement',
      imgsrc: '',
    },
    '671cc239-df92-479e-a9ef-8242ffe39db1': {
      id: '671cc239-df92-479e-a9ef-8242ffe39db1',
      name: "Plus d'affichage",
      imgsrc: '',
    },
    'd8959d04-1f73-4c37-bc3b-726e4bb2933a': {
      id: 'd8959d04-1f73-4c37-bc3b-726e4bb2933a',
      name: "Problème de contraste de l'affichage",
      imgsrc: '',
    },
    '376f397b-f648-40d8-ab61-035ef2e74d32': {
      id: '376f397b-f648-40d8-ab61-035ef2e74d32',
      name: "Problème d'affichage de l'heure",
      imgsrc: '',
    },
    '5dd75241-2813-4b55-96ff-ced144298b54': {
      id: '5dd75241-2813-4b55-96ff-ced144298b54',
      name: "Problème d'affichage de la température",
      imgsrc: '',
    },
    'fb6f7ba6-ffc1-4f06-9e84-01cefe7feba6': {
      id: 'fb6f7ba6-ffc1-4f06-9e84-01cefe7feba6',
      name: "Problème d'affichage du mode de cuisson",
      imgsrc: '',
    },
    'a142842c-5209-47fd-8be7-f3ce1dc78958': {
      id: 'a142842c-5209-47fd-8be7-f3ce1dc78958',
      name: "Problème de langue de l'affichage",
      imgsrc: '',
    },
    '95a3685e-9541-4733-9d88-183e2850e35f': {
      id: '95a3685e-9541-4733-9d88-183e2850e35f',
      name: "Autre problème d'affichage",
      imgsrc: '',
    },
    '305e66f8-7750-447f-8ca6-ff915897e312': {
      id: '305e66f8-7750-447f-8ca6-ff915897e312',
      name: 'Pas de froid',
      imgsrc: '',
    },
    '17b590f1-53c0-430f-b6b0-badde597fbf8': {
      id: '17b590f1-53c0-430f-b6b0-badde597fbf8',
      name: 'Trop de froid',
      imgsrc: '',
    },
    'd632fd08-cd95-4761-8287-0fce6d7aae5f': {
      id: 'd632fd08-cd95-4761-8287-0fce6d7aae5f',
      name: 'Autre problème de température',
      imgsrc: '',
    },
    '103179d6-bf0b-4de4-ac03-335de61dabce': {
      id: '103179d6-bf0b-4de4-ac03-335de61dabce',
      name: "Fuite par l'avant",
      imgsrc: '',
    },
    '87673e53-e62b-440c-b234-d4d9795352af': {
      id: '87673e53-e62b-440c-b234-d4d9795352af',
      name: "Fuite par l'arrière",
      imgsrc: '',
    },
    '6638dcc6-e4a5-4eb0-a2b0-bef56b826836': {
      id: '6638dcc6-e4a5-4eb0-a2b0-bef56b826836',
      name: 'Autre fuite',
      imgsrc: '',
    },
    'bb45a3e4-ffaa-4030-b68d-cc02d599e644': {
      id: 'bb45a3e4-ffaa-4030-b68d-cc02d599e644',
      name: 'Bruit au niveau du circuit de refroidissement',
      imgsrc: '',
    },
    '48c3c254-be84-4e91-b360-d2e960035cf9': {
      id: '48c3c254-be84-4e91-b360-d2e960035cf9',
      name: 'Bruit au niveau du moteur',
      imgsrc: '',
    },
    'ce284ec7-1869-4ff7-87b4-7bc18940baef': {
      id: 'ce284ec7-1869-4ff7-87b4-7bc18940baef',
      name: 'Autre bruit',
      imgsrc: '',
    },
    '8f755d7c-63b1-4491-b351-df9ac5825718': {
      id: '8f755d7c-63b1-4491-b351-df9ac5825718',
      name: 'Odeur de moisissure',
      imgsrc: '',
    },
    '38ee6906-438e-4286-84c0-26779117f6fe': {
      id: '38ee6906-438e-4286-84c0-26779117f6fe',
      name: 'Odeur de brulé',
      imgsrc: '',
    },
    'd2afa176-666b-4cd1-bfa0-d625896ba5c7': {
      id: 'd2afa176-666b-4cd1-bfa0-d625896ba5c7',
      name: 'Odeur de plastique',
      imgsrc: '',
    },
    '0b367ca4-008f-4896-b315-a681e4e13e37': {
      id: '0b367ca4-008f-4896-b315-a681e4e13e37',
      name: 'Autre odeur',
      imgsrc: '',
    },
    'e0aa5f31-13aa-4ebb-aa43-f1b718df9932': {
      id: 'e0aa5f31-13aa-4ebb-aa43-f1b718df9932',
      name: 'Parroie intérieure endommagée',
      imgsrc: '',
    },
    'abf70233-8700-4543-9bc6-4cbbaf7273c6': {
      id: 'abf70233-8700-4543-9bc6-4cbbaf7273c6',
      name: 'Chassis extérieur endommagé',
      imgsrc: '',
    },
    '047296c9-cce6-4249-bdcc-cf0858415a52': {
      id: '047296c9-cce6-4249-bdcc-cf0858415a52',
      name: 'Poignée endommagée',
      imgsrc: '',
    },
    'a211152f-1b76-4b5d-acbd-8dcc755c626e': {
      id: 'a211152f-1b76-4b5d-acbd-8dcc755c626e',
      name: 'Clayette endommagée',
      imgsrc: '',
    },
    'bcdf13cf-6144-4616-946a-959e53dd582d': {
      id: 'bcdf13cf-6144-4616-946a-959e53dd582d',
      name: 'Balconnet endommagé',
      imgsrc: '',
    },
    '0ac165b8-8b73-494c-be81-4a2954e3030a': {
      id: '0ac165b8-8b73-494c-be81-4a2954e3030a',
      name: 'Pas de lumière',
      imgsrc: '',
    },
    'e2509548-3fc1-42aa-b712-dfcd255dc2b5': {
      id: 'e2509548-3fc1-42aa-b712-dfcd255dc2b5',
      name: 'Autre pièce défectueuse',
      imgsrc: '',
    },
    '370dc504-5179-45aa-89e7-22171112e0e7': {
      id: '370dc504-5179-45aa-89e7-22171112e0e7',
      name: "Pas d'alimentation",
      imgsrc: '',
    },
    '3a1d9266-a89f-47d7-92ea-b38a419376e1': {
      id: '3a1d9266-a89f-47d7-92ea-b38a419376e1',
      name: 'Le moteur fonctionne sans cesse',
      imgsrc: '',
    },
    'af00c2f6-b362-41a1-9c8e-0cffb92dd064': {
      id: 'af00c2f6-b362-41a1-9c8e-0cffb92dd064',
      name: 'Sous tension mais pas de fonctionnement',
      imgsrc: '',
    },
    '1e0c2ede-a1b5-4dd4-b774-d137a25410a8': {
      id: '1e0c2ede-a1b5-4dd4-b774-d137a25410a8',
      name: 'Fait disjonter',
      imgsrc: '',
    },
    '691119d1-7f7f-4498-88b2-d386289c548a': {
      id: '691119d1-7f7f-4498-88b2-d386289c548a',
      name: 'Autre problème',
      imgsrc: '',
    },
    '1237dc5e-568d-439f-84e2-3ff2977a73a2': {
      id: '1237dc5e-568d-439f-84e2-3ff2977a73a2',
      name: "Plus d'alimentation suite orage/surtension",
      imgsrc: '',
    },
    '010ab40c-04c8-4ca9-afd8-82b309e169ba': {
      id: '010ab40c-04c8-4ca9-afd8-82b309e169ba',
      name: 'Fonctionnement perturbé suite orage/surtension',
      imgsrc: '',
    },
    '3adbbc8c-729f-4dd3-9fde-8909b67832b8': {
      id: '3adbbc8c-729f-4dd3-9fde-8909b67832b8',
      name: 'Ne chauffe pas',
      imgsrc: '',
      childs: [
        '1b61a381-d47a-4765-be8d-58110e0fbf5c',
        'f256622a-8dd8-46ef-a112-c6c96e90498b',
        '88ab3bf5-70d7-4bab-b639-f0448917b43e',
        '04e3eb33-065b-4899-8a3b-e8aa341f6bd1',
        '04627667-c608-42c8-9d72-06c6ede752b7',
        'a528cfa6-aa51-4c65-a2ff-e6901501521f',
        '77f5cbe1-b16c-44d1-9e01-231367774799',
      ],
    },
    'bf02b4c7-be00-4cf7-b7f2-3059d93d9468': {
      id: 'bf02b4c7-be00-4cf7-b7f2-3059d93d9468',
      name: 'Chauffe insuffisamment',
      imgsrc: '',
      childs: [
        'b6acd033-0cb6-4fb8-8602-f6e4b8ba667a',
        'e9b45b6a-37a1-4d53-8d0a-a756847419e4',
        '72b2a661-5b16-4637-9860-3038b651376c',
        '90c8e8f3-6dc8-43c6-a7e5-abe130e01d1f',
        'b88cd4a8-457d-4cc6-9440-0435f150de1f',
        '059ec1ba-ac52-4366-9541-17d3eddc403b',
        'b1157f0f-4a55-4267-b340-e76c75058e74',
      ],
    },
    'c996a611-ad47-40ca-99fa-545aa558d808': {
      id: 'c996a611-ad47-40ca-99fa-545aa558d808',
      name: 'Chauffe excessivement',
      imgsrc: '',
      childs: [
        '8840eeff-5d2d-412a-8faa-dfd2708a49b5',
        '04199c1a-5792-4aa9-998f-5bda1f9c33d9',
        '564df021-2634-40bd-8dd4-48ef7a43d077',
        '2775c46f-78a4-4c26-96b3-d66be8223750',
        '6fa0f61b-94b3-4d23-873c-68d943dcc2c2',
        '1e61d470-1d43-4dac-b4e8-49c6abdae4d9',
        'b66dbe0b-8a73-4bfd-bfb6-1077ea323bef',
      ],
    },
    '77487e86-26a4-4ac0-a6a9-8a361ff16555': {
      id: '77487e86-26a4-4ac0-a6a9-8a361ff16555',
      name: 'Autre problème de chauffe',
      imgsrc: '',
      childs: [
        '9beb5a25-79f8-4eb0-859b-e443df14ca06',
        'ab50833a-1202-42e6-a4a1-630a983ff043',
        '2e7d0775-c383-43d6-9435-b6604c45dc93',
        '84115844-2ade-4b41-ba7e-99169c765640',
        'ba93ab11-0e8a-4251-9cc7-d7598f7fdf07',
        'a2516453-73e3-4ccf-bb09-c4f40719cfeb',
        'bad2c2da-b0aa-4881-9c4a-5d62698e0bf9',
      ],
    },
    '7804b1ee-b727-472d-9b0c-c73849d8ced0': {
      id: '7804b1ee-b727-472d-9b0c-c73849d8ced0',
      name: "Pas d'alimentation",
      imgsrc: '',
    },
    '0c245891-f475-49e0-84d0-655b82783fc2': {
      id: '0c245891-f475-49e0-84d0-655b82783fc2',
      name: "Ne s'éteint pas",
      imgsrc: '',
    },
    '40fc160a-cb81-489e-8bbd-4169d8b09d04': {
      id: '40fc160a-cb81-489e-8bbd-4169d8b09d04',
      name: 'Sous tension mais ne démarre pas ',
      imgsrc: '',
    },
    '94cf725e-6b79-4603-82cd-e04fdd63d80e': {
      id: '94cf725e-6b79-4603-82cd-e04fdd63d80e',
      name: 'Marche arrêt intermittent',
      imgsrc: '',
    },
    'edf7fd66-eb5e-4a7e-9836-330aa9deeab4': {
      id: 'edf7fd66-eb5e-4a7e-9836-330aa9deeab4',
      name: 'Fait disjoncter',
      imgsrc: '',
    },
    'abc1cfb8-e8a3-4b8f-83aa-bcf3f773465a': {
      id: 'abc1cfb8-e8a3-4b8f-83aa-bcf3f773465a',
      name: 'Autre problème de fonctionnement',
      imgsrc: '',
    },
    '0710aa7b-f3d0-408f-bd0e-fdf1bb0dd57a': {
      id: '0710aa7b-f3d0-408f-bd0e-fdf1bb0dd57a',
      name: "Plus d'affichage",
      imgsrc: '',
    },
    '564eb4d4-e4d8-4fe1-ab5e-a1f3fad0bbd8': {
      id: '564eb4d4-e4d8-4fe1-ab5e-a1f3fad0bbd8',
      name: "Problème de contraste de l'affichage",
      imgsrc: '',
    },
    '9be68297-392e-4758-9e25-8fc5590f09db': {
      id: '9be68297-392e-4758-9e25-8fc5590f09db',
      name: "Problème d'affichage de la température",
      imgsrc: '',
    },
    '7d4d98ea-8459-4586-9ecf-3d96959d22c5': {
      id: '7d4d98ea-8459-4586-9ecf-3d96959d22c5',
      name: "Problème d'affichage de la minuterie",
      imgsrc: '',
    },
    'e583daf6-ff2f-4a4d-baab-40a52dfb3743': {
      id: 'e583daf6-ff2f-4a4d-baab-40a52dfb3743',
      name: "Autre problème d'affichage",
      imgsrc: '',
    },
    '7f799ac7-cd21-4691-ba96-37cf121a5aeb': {
      id: '7f799ac7-cd21-4691-ba96-37cf121a5aeb',
      name: 'Ne chauffe pas',
      imgsrc: '',
      childs: [
        '09fc5e71-6663-449d-a933-82882b5cd4e2',
        '8ec5d0b3-7128-4b80-ace5-0c47f7c7e63d',
        '3fd19e61-611c-41c9-a5d1-726b9f3fb833',
      ],
    },
    '535afef5-b949-4e42-86e1-e1cc53018d69': {
      id: '535afef5-b949-4e42-86e1-e1cc53018d69',
      name: 'Chauffe insuffisamment',
      imgsrc: '',
      childs: [
        '421dab48-a411-49de-80b2-d686c66fdd2b',
        '976acb23-79e8-40bc-8a5f-194d0d12bd2b',
        '8ef3886f-d12e-489f-8828-bd6280a32f42',
      ],
    },
    'd316cf69-3058-4322-9c7d-4c4fa2efaaba': {
      id: 'd316cf69-3058-4322-9c7d-4c4fa2efaaba',
      name: 'Chauffe excessivement',
      imgsrc: '',
      childs: [
        'd33da54d-b530-4506-a223-93b1835b6fa6',
        'f888b43d-42a8-445e-a497-5f9225d054cf',
        'b388e730-cfba-4cba-90fe-56f409e01999',
      ],
    },
    '08d9f97f-7d0d-40ff-9dac-c7bbb2dd8c94': {
      id: '08d9f97f-7d0d-40ff-9dac-c7bbb2dd8c94',
      name: 'Autre problème de chauffe',
      imgsrc: '',
      childs: [
        '360f4def-25c0-4cb8-8721-2d289fb1167d',
        '7fef9185-2110-4823-9ee9-b615cab56310',
        'fa049251-ab43-4d75-b44d-ea0b2a977da7',
      ],
    },
    '808ff4dd-4659-4f75-b4e4-1e1f27dafc39': {
      id: '808ff4dd-4659-4f75-b4e4-1e1f27dafc39',
      name: "Pas d'alimentation",
      imgsrc: '',
    },
    '5f15919e-bd1d-43f2-bdb3-5e806971a114': {
      id: '5f15919e-bd1d-43f2-bdb3-5e806971a114',
      name: "Ne s'éteint pas",
      imgsrc: '',
    },
    'd2c70b5d-c0ef-4231-ada2-3266fec0b0f1': {
      id: 'd2c70b5d-c0ef-4231-ada2-3266fec0b0f1',
      name: 'Sous tension mais ne démarre pas ',
      imgsrc: '',
    },
    '9208795b-758b-48f8-9815-e4b620033eaf': {
      id: '9208795b-758b-48f8-9815-e4b620033eaf',
      name: 'Marche arrêt intermittent',
      imgsrc: '',
    },
    'c17c03c4-65ab-40ff-b051-4c7bb85c5d94': {
      id: 'c17c03c4-65ab-40ff-b051-4c7bb85c5d94',
      name: 'Fait disjoncter',
      imgsrc: '',
    },
    '772c92f1-a93a-43a6-9fe3-e949ac9676a7': {
      id: '772c92f1-a93a-43a6-9fe3-e949ac9676a7',
      name: 'Autre problème de fonctionnement',
      imgsrc: '',
    },
    '3558d795-a4fe-4081-bdd5-140af2f40e7a': {
      id: '3558d795-a4fe-4081-bdd5-140af2f40e7a',
      name: "Plus d'affichage",
      imgsrc: '',
    },
    '4e3f76dd-976e-4e62-b761-5a00ee793a3e': {
      id: '4e3f76dd-976e-4e62-b761-5a00ee793a3e',
      name: "Problème de contraste de l'affichage",
      imgsrc: '',
    },
    '7cb627de-8dc6-488e-84cc-eb98578a86e9': {
      id: '7cb627de-8dc6-488e-84cc-eb98578a86e9',
      name: "Problème d'affichage de l'heure",
      imgsrc: '',
    },
    '937721e6-2f88-4f08-bd83-b92b4ddb7548': {
      id: '937721e6-2f88-4f08-bd83-b92b4ddb7548',
      name: "Problème d'affichage de la température",
      imgsrc: '',
    },
    'e2edd7cb-7ffb-48d7-8e11-4cf5505f11eb': {
      id: 'e2edd7cb-7ffb-48d7-8e11-4cf5505f11eb',
      name: "Problème d'affichage du mode de cuisson",
      imgsrc: '',
    },
    'dfed2d4f-7bba-4947-affd-0076852317f0': {
      id: 'dfed2d4f-7bba-4947-affd-0076852317f0',
      name: "Problème de langue de l'affichage",
      imgsrc: '',
    },
    '0e95b2fe-1678-4310-962b-96ed82dc5b8a': {
      id: '0e95b2fe-1678-4310-962b-96ed82dc5b8a',
      name: "Autre problème d'affichage",
      imgsrc: '',
    },
    '74055348-d755-4458-80d2-0dd7543671d3': {
      id: '74055348-d755-4458-80d2-0dd7543671d3',
      name: "Au niveau de l'arrivée d'eau",
      imgsrc: '',
      childs: [
        '29f3ac0b-0188-4b13-afaa-4f366e39a8cb',
        '5227222e-c223-4dc4-988c-7d7f566412cc',
        'af6e7b85-8b13-4d8e-9791-2cb58a8a2373',
        'b2b466ff-4341-4015-8aba-4d7cf05dc727',
      ],
    },
    '9ea3fb60-616e-48f5-b5c0-4082de03f961': {
      id: '9ea3fb60-616e-48f5-b5c0-4082de03f961',
      name: 'Au niveau des robinets',
      imgsrc: '',
      childs: [
        '3c5d7bf6-9cbc-4d9c-8fc7-4b2628200d95',
        'b953bbfa-90b7-49ed-b29f-444d5b0b157d',
        'd92dde13-8634-4c6f-9307-3668a92fc2ad',
      ],
    },
    '7656d14f-a206-4b68-b256-b2fb2dc77a42': {
      id: '7656d14f-a206-4b68-b256-b2fb2dc77a42',
      name: "Débit d'évacuation faible",
      imgsrc: '',
    },
    'aa5113f6-d3c5-476e-804c-fffd73d2daf7': {
      id: 'aa5113f6-d3c5-476e-804c-fffd73d2daf7',
      name: "Pas d'évacuation",
      imgsrc: '',
    },
    'b5a231f4-4f5c-4f3f-8303-443d3bf4294d': {
      id: 'b5a231f4-4f5c-4f3f-8303-443d3bf4294d',
      name: "Autre problème d'évacuation",
      imgsrc: '',
    },
    '2f861993-27b0-4d34-b210-f1e70d92e741': {
      id: '2f861993-27b0-4d34-b210-f1e70d92e741',
      name: "Au niveau de l'arrivée d'eau",
      imgsrc: '',
      childs: [
        '0a9af10c-15b6-4ca0-bdc9-12619b8fac01',
        '269e41d3-cfa0-425c-8dbc-176eccd259c2',
        '1c41418c-6ed7-4b31-b1a0-99007a092f42',
        '7aa74c12-13ce-40d3-96fd-4edd72190f1c',
      ],
    },
    '5eee23fd-d00e-4427-a960-436975e17870': {
      id: '5eee23fd-d00e-4427-a960-436975e17870',
      name: 'Au niveau des robinets',
      imgsrc: '',
    },
    '00274476-7ea7-4b2b-ac8c-f844368aedf6': {
      id: '00274476-7ea7-4b2b-ac8c-f844368aedf6',
      name: "Débit d'évacuation faible",
      imgsrc: '',
    },
    'ef7b8605-ef89-44a7-8a5b-0d491c6d8913': {
      id: 'ef7b8605-ef89-44a7-8a5b-0d491c6d8913',
      name: "Pas d'évacuation",
      imgsrc: '',
    },
    '994f49c2-8eab-4d64-a90a-212bf4ea388a': {
      id: '994f49c2-8eab-4d64-a90a-212bf4ea388a',
      name: "Autre problème d'évacuation",
      imgsrc: '',
    },
    'b481690d-2dbc-47f3-a290-ed4c9ae9435d': {
      id: 'b481690d-2dbc-47f3-a290-ed4c9ae9435d',
      name: "Au niveau de l'arrivée d'eau",
      imgsrc: '',
      childs: [
        'c3552dcc-a78c-4454-b76f-21cf5763b684',
        '7e1f7a12-da6b-4fec-aa19-72f49920d81b',
        '05f6af09-a677-44b1-a0e3-f1c7ce62d608',
        'd76cd50c-79ad-4d69-8570-6ca93384f858',
      ],
    },
    'f11f0691-d62c-4762-bf88-c7bd604b72db': {
      id: 'f11f0691-d62c-4762-bf88-c7bd604b72db',
      name: 'Au niveau des robinets',
      imgsrc: '',
      childs: [
        '3cafb8ec-436a-45cf-8ebf-d4679a38feec',
        'c06c6a11-807f-4466-a140-e06643d367bb',
        '2d8fcfc8-6d44-44c3-91fe-3c18518cc74b',
      ],
    },
    'b67b3071-170b-457f-8832-89d388fa1633': {
      id: 'b67b3071-170b-457f-8832-89d388fa1633',
      name: "Débit d'évacuation faible",
      imgsrc: '',
    },
    '5ef3281c-049a-42f2-bf15-37b6534ffbaf': {
      id: '5ef3281c-049a-42f2-bf15-37b6534ffbaf',
      name: "Pas d'évacuation",
      imgsrc: '',
    },
    '809f6d07-35ac-4980-a972-c8248be26082': {
      id: '809f6d07-35ac-4980-a972-c8248be26082',
      name: "Autre problème d'évacuation",
      imgsrc: '',
    },
    '6723e14f-554b-41c6-aab3-620beaa5bf9b': {
      id: '6723e14f-554b-41c6-aab3-620beaa5bf9b',
      name: "Au niveau de l'arrivée d'eau",
      imgsrc: '',
    },
    '74a29b99-c40b-49d7-a27e-8e931687f677': {
      id: '74a29b99-c40b-49d7-a27e-8e931687f677',
      name: 'Au niveau du robinet',
      imgsrc: '',
    },
    'd3ee3e57-a64c-4a55-b636-76c3a7186b88': {
      id: 'd3ee3e57-a64c-4a55-b636-76c3a7186b88',
      name: 'Au niveau de la cuve',
      imgsrc: '',
    },
    '6e087f98-a4ec-413f-b519-cb0ef1b01586': {
      id: '6e087f98-a4ec-413f-b519-cb0ef1b01586',
      name: "Au niveau de l'évacuation",
      imgsrc: '',
    },
    'd7aa9fde-f759-46e3-8582-5c93bf151f24': {
      id: 'd7aa9fde-f759-46e3-8582-5c93bf151f24',
      name: "Débit d'évacuation faible",
      imgsrc: '',
    },
    '0a9eff6e-9090-416d-b0fa-ebba59a44b7c': {
      id: '0a9eff6e-9090-416d-b0fa-ebba59a44b7c',
      name: "Pas d'évacuation",
      imgsrc: '',
    },
    'f33553de-00cd-44e8-93e5-0e3079b4ef80': {
      id: 'f33553de-00cd-44e8-93e5-0e3079b4ef80',
      name: "Autre problème d'évacuation",
      imgsrc: '',
    },
    '0069cd87-d4ae-4d37-9337-1b0379304558': {
      id: '0069cd87-d4ae-4d37-9337-1b0379304558',
      name: 'Ecran endommagé',
      imgsrc: '',
    },
    'd151fd29-3981-481c-90c3-61ba93d52206': {
      id: 'd151fd29-3981-481c-90c3-61ba93d52206',
      name: "Problème de charge/d'alimentation",
      imgsrc: '',
    },
    '9afe8c9f-dcac-485e-a1a5-9e80073357d1': {
      id: '9afe8c9f-dcac-485e-a1a5-9e80073357d1',
      name: 'Problème de mise en marche',
      imgsrc: '',
    },
    '26e789fe-7c18-46d2-9f3c-eb89fdeeff46': { id: '26e789fe-7c18-46d2-9f3c-eb89fdeeff46', name: 'Virus', imgsrc: '' },
    '717e8198-017b-4bf9-9968-fbb2199efe90': {
      id: '717e8198-017b-4bf9-9968-fbb2199efe90',
      name: 'Problème de logiciel',
      imgsrc: '',
    },
    '3624ebb8-e42e-45ef-836b-e0a32a7b65d7': {
      id: '3624ebb8-e42e-45ef-836b-e0a32a7b65d7',
      name: "Chute dans l'eau / Liquide tombé dessus",
      imgsrc: '',
    },
    'c7465b56-839e-4503-a44f-7de8c11a2d5a': {
      id: 'c7465b56-839e-4503-a44f-7de8c11a2d5a',
      name: 'Orage/Surtension',
      imgsrc: '',
    },
    '37ac67f9-6259-47e3-bfac-ddcf1dfee0b5': {
      id: '37ac67f9-6259-47e3-bfac-ddcf1dfee0b5',
      name: 'Autre problème',
      imgsrc: '',
    },
    '9b01b634-dbf4-48b4-907e-d572fb0d7f63': {
      id: '9b01b634-dbf4-48b4-907e-d572fb0d7f63',
      name: 'Ecran endommagé',
      imgsrc: '',
    },
    '33cd5c7e-23b9-452d-884d-11c43744aac7': {
      id: '33cd5c7e-23b9-452d-884d-11c43744aac7',
      name: 'Problème de mise en marche',
      imgsrc: '',
    },
    '38a58481-42d8-4783-ad62-46e0058a3ff8': { id: '38a58481-42d8-4783-ad62-46e0058a3ff8', name: 'Virus', imgsrc: '' },
    'fef6ee89-fb82-4367-9a00-d6e2dc82c2ec': {
      id: 'fef6ee89-fb82-4367-9a00-d6e2dc82c2ec',
      name: 'Problème de logiciel',
      imgsrc: '',
    },
    '087b3ed4-6c2d-43e3-a445-d400d3f8c4e2': {
      id: '087b3ed4-6c2d-43e3-a445-d400d3f8c4e2',
      name: 'Problème de fonctionnement',
      imgsrc: '',
    },
    '79cf3c9e-53cd-488a-ba48-b091426afb29': {
      id: '79cf3c9e-53cd-488a-ba48-b091426afb29',
      name: 'Orage/Surtension',
      imgsrc: '',
    },
    '26dfe423-a0c6-4949-b7b9-23b6c16015ec': {
      id: '26dfe423-a0c6-4949-b7b9-23b6c16015ec',
      name: 'Autre problème',
      imgsrc: '',
    },
    '62f8d47b-0205-4caf-b516-88b303ddeb3c': {
      id: '62f8d47b-0205-4caf-b516-88b303ddeb3c',
      name: "Problème d'accès à Internet",
      imgsrc: '',
      childs: [
        'f6763403-7cdd-47f4-9d16-33fd1cdcfe5d',
        'e9f0d835-f991-4893-a3c3-13a1c6befb53',
        'e979ff36-e1a7-4fae-97c7-74e3e79f1de8',
        '599d191d-61bc-4e66-be52-ca275ddbe70f',
      ],
    },
    '760d8e2b-ad53-4ed0-8f37-1ebb4d75a406': {
      id: '760d8e2b-ad53-4ed0-8f37-1ebb4d75a406',
      name: 'Problème de matériel',
      imgsrc: '',
    },
    '5bc8deff-f5a9-4ddc-b184-ba3d13224095': {
      id: '5bc8deff-f5a9-4ddc-b184-ba3d13224095',
      name: 'Problème suite orage/surtension',
      imgsrc: '',
    },
    '0e636e41-e438-474e-b127-d0df22042e70': {
      id: '0e636e41-e438-474e-b127-d0df22042e70',
      name: 'Autre problème',
      imgsrc: '',
    },
    '799853a8-5a67-42e0-958d-3a1e432485a2': {
      id: '799853a8-5a67-42e0-958d-3a1e432485a2',
      name: "Problème d'accès à Internet",
      imgsrc: '',
      childs: [
        '35a91066-059c-4fd0-ab9d-65336560a94d',
        '541b27e9-7194-4305-9344-268497863a31',
        '4c58dcbc-9db6-474a-b5aa-cde82ef5a83b',
        'a4b62d80-df79-4cad-a604-9aee06dfeb6d',
      ],
    },
    '99d3b2ff-976f-4c37-8ceb-cb8b644c157c': {
      id: '99d3b2ff-976f-4c37-8ceb-cb8b644c157c',
      name: 'Problème de matériel',
      imgsrc: '',
    },
    'c7bb6d4f-9e7d-4ad0-9f6e-e4da72c7ad65': {
      id: 'c7bb6d4f-9e7d-4ad0-9f6e-e4da72c7ad65',
      name: 'Problème suite orage/surtension',
      imgsrc: '',
    },
    '4c7f4ba8-307d-4772-a265-fe7e1b9b3fe8': {
      id: '4c7f4ba8-307d-4772-a265-fe7e1b9b3fe8',
      name: 'Autre problème',
      imgsrc: '',
    },
    '72a4d21a-7432-429f-90c3-4cd6d9e67a67': {
      id: '72a4d21a-7432-429f-90c3-4cd6d9e67a67',
      name: "Problème d'accès à Internet",
      imgsrc: '',
      childs: [
        '80e2c0bb-32c4-4e08-a15b-939919d49e09',
        '70f7c1da-360a-4315-9998-2a6be222c97f',
        'c60e6960-2855-4268-b6b9-a8df76448e63',
        '7329e99d-ef13-4863-8442-fecfd96f433d',
      ],
    },
    '7d0eded1-f243-41ef-8fe3-953dcc451e90': {
      id: '7d0eded1-f243-41ef-8fe3-953dcc451e90',
      name: 'Problème de matériel',
      imgsrc: '',
    },
    'f0945c20-cb24-4445-bc8a-87d3b487a5c1': {
      id: 'f0945c20-cb24-4445-bc8a-87d3b487a5c1',
      name: 'Problème suite orage/surtension',
      imgsrc: '',
    },
    '841b91ac-36ab-43c3-8fd7-ca562614418c': {
      id: '841b91ac-36ab-43c3-8fd7-ca562614418c',
      name: 'Autre problème',
      imgsrc: '',
    },
    '1b61a381-d47a-4765-be8d-58110e0fbf5c': {
      id: '1b61a381-d47a-4765-be8d-58110e0fbf5c',
      name: 'Sur tous les foyers',
      imgsrc: '',
    },
    'f256622a-8dd8-46ef-a112-c6c96e90498b': {
      id: 'f256622a-8dd8-46ef-a112-c6c96e90498b',
      name: 'Sur le foyer en haut à gauche',
      imgsrc: '',
    },
    '88ab3bf5-70d7-4bab-b639-f0448917b43e': {
      id: '88ab3bf5-70d7-4bab-b639-f0448917b43e',
      name: 'Sur le foyer en haut à droite',
      imgsrc: '',
    },
    '04e3eb33-065b-4899-8a3b-e8aa341f6bd1': {
      id: '04e3eb33-065b-4899-8a3b-e8aa341f6bd1',
      name: 'Sur le foyer en bas à gauche',
      imgsrc: '',
    },
    '04627667-c608-42c8-9d72-06c6ede752b7': {
      id: '04627667-c608-42c8-9d72-06c6ede752b7',
      name: 'Sur le foyer en bas à droite',
      imgsrc: '',
    },
    'a528cfa6-aa51-4c65-a2ff-e6901501521f': {
      id: 'a528cfa6-aa51-4c65-a2ff-e6901501521f',
      name: 'Sur le foyer central',
      imgsrc: '',
    },
    '77f5cbe1-b16c-44d1-9e01-231367774799': {
      id: '77f5cbe1-b16c-44d1-9e01-231367774799',
      name: 'Sur un autre foyer',
      imgsrc: '',
    },
    'b6acd033-0cb6-4fb8-8602-f6e4b8ba667a': {
      id: 'b6acd033-0cb6-4fb8-8602-f6e4b8ba667a',
      name: 'Sur tous les foyers',
      imgsrc: '',
    },
    'e9b45b6a-37a1-4d53-8d0a-a756847419e4': {
      id: 'e9b45b6a-37a1-4d53-8d0a-a756847419e4',
      name: 'Sur le foyer en haut à gauche',
      imgsrc: '',
    },
    '72b2a661-5b16-4637-9860-3038b651376c': {
      id: '72b2a661-5b16-4637-9860-3038b651376c',
      name: 'Sur le foyer en haut à droite',
      imgsrc: '',
    },
    '90c8e8f3-6dc8-43c6-a7e5-abe130e01d1f': {
      id: '90c8e8f3-6dc8-43c6-a7e5-abe130e01d1f',
      name: 'Sur le foyer en bas à gauche',
      imgsrc: '',
    },
    'b88cd4a8-457d-4cc6-9440-0435f150de1f': {
      id: 'b88cd4a8-457d-4cc6-9440-0435f150de1f',
      name: 'Sur le foyer en bas à droite',
      imgsrc: '',
    },
    '059ec1ba-ac52-4366-9541-17d3eddc403b': {
      id: '059ec1ba-ac52-4366-9541-17d3eddc403b',
      name: 'Sur le foyer central',
      imgsrc: '',
    },
    'b1157f0f-4a55-4267-b340-e76c75058e74': {
      id: 'b1157f0f-4a55-4267-b340-e76c75058e74',
      name: 'Sur un autre foyer',
      imgsrc: '',
    },
    '8840eeff-5d2d-412a-8faa-dfd2708a49b5': {
      id: '8840eeff-5d2d-412a-8faa-dfd2708a49b5',
      name: 'Sur tous les foyers',
      imgsrc: '',
    },
    '04199c1a-5792-4aa9-998f-5bda1f9c33d9': {
      id: '04199c1a-5792-4aa9-998f-5bda1f9c33d9',
      name: 'Sur le foyer en haut à gauche',
      imgsrc: '',
    },
    '564df021-2634-40bd-8dd4-48ef7a43d077': {
      id: '564df021-2634-40bd-8dd4-48ef7a43d077',
      name: 'Sur le foyer en haut à droite',
      imgsrc: '',
    },
    '2775c46f-78a4-4c26-96b3-d66be8223750': {
      id: '2775c46f-78a4-4c26-96b3-d66be8223750',
      name: 'Sur le foyer en bas à gauche',
      imgsrc: '',
    },
    '6fa0f61b-94b3-4d23-873c-68d943dcc2c2': {
      id: '6fa0f61b-94b3-4d23-873c-68d943dcc2c2',
      name: 'Sur le foyer en bas à droite',
      imgsrc: '',
    },
    '1e61d470-1d43-4dac-b4e8-49c6abdae4d9': {
      id: '1e61d470-1d43-4dac-b4e8-49c6abdae4d9',
      name: 'Sur le foyer central',
      imgsrc: '',
    },
    'b66dbe0b-8a73-4bfd-bfb6-1077ea323bef': {
      id: 'b66dbe0b-8a73-4bfd-bfb6-1077ea323bef',
      name: 'Sur un autre foyer',
      imgsrc: '',
    },
    '9beb5a25-79f8-4eb0-859b-e443df14ca06': {
      id: '9beb5a25-79f8-4eb0-859b-e443df14ca06',
      name: 'Sur tous les foyers',
      imgsrc: '',
    },
    'ab50833a-1202-42e6-a4a1-630a983ff043': {
      id: 'ab50833a-1202-42e6-a4a1-630a983ff043',
      name: 'Sur le foyer en haut à gauche',
      imgsrc: '',
    },
    '2e7d0775-c383-43d6-9435-b6604c45dc93': {
      id: '2e7d0775-c383-43d6-9435-b6604c45dc93',
      name: 'Sur le foyer en haut à droite',
      imgsrc: '',
    },
    '84115844-2ade-4b41-ba7e-99169c765640': {
      id: '84115844-2ade-4b41-ba7e-99169c765640',
      name: 'Sur le foyer en bas à gauche',
      imgsrc: '',
    },
    'ba93ab11-0e8a-4251-9cc7-d7598f7fdf07': {
      id: 'ba93ab11-0e8a-4251-9cc7-d7598f7fdf07',
      name: 'Sur le foyer en bas à droite',
      imgsrc: '',
    },
    'a2516453-73e3-4ccf-bb09-c4f40719cfeb': {
      id: 'a2516453-73e3-4ccf-bb09-c4f40719cfeb',
      name: 'Sur le foyer central',
      imgsrc: '',
    },
    'bad2c2da-b0aa-4881-9c4a-5d62698e0bf9': {
      id: 'bad2c2da-b0aa-4881-9c4a-5d62698e0bf9',
      name: 'Sur un autre foyer',
      imgsrc: '',
    },
    '09fc5e71-6663-449d-a933-82882b5cd4e2': {
      id: '09fc5e71-6663-449d-a933-82882b5cd4e2',
      name: 'Au niveau du four ET de la plaque',
      imgsrc: '',
    },
    '8ec5d0b3-7128-4b80-ace5-0c47f7c7e63d': {
      id: '8ec5d0b3-7128-4b80-ace5-0c47f7c7e63d',
      name: 'Uniquement au niveau du four',
      imgsrc: '',
    },
    '3fd19e61-611c-41c9-a5d1-726b9f3fb833': {
      id: '3fd19e61-611c-41c9-a5d1-726b9f3fb833',
      name: 'Uniquement au niveau de la plaque',
      imgsrc: '',
      childs: [
        'e02e64e7-2d95-4c79-8e21-c7151f103f8a',
        '9e729924-3f9d-4aa4-9800-13a57ed33e95',
        'a040fe00-68f2-46a3-b7fd-7296de14f006',
        '30d58784-9836-4c7a-a486-93e95fb6ae17',
        '7a8f80ea-b758-40b7-b6c0-9086d5bd08f8',
        '41859fbf-65b8-4808-bc18-a68ddd78cbed',
        '5f3392fa-c803-4e20-897d-9e05aa6efa98',
      ],
    },
    '421dab48-a411-49de-80b2-d686c66fdd2b': {
      id: '421dab48-a411-49de-80b2-d686c66fdd2b',
      name: 'Au niveau du four ET de la plaque',
      imgsrc: '',
    },
    '976acb23-79e8-40bc-8a5f-194d0d12bd2b': {
      id: '976acb23-79e8-40bc-8a5f-194d0d12bd2b',
      name: 'Uniquement au niveau du four',
      imgsrc: '',
    },
    '8ef3886f-d12e-489f-8828-bd6280a32f42': {
      id: '8ef3886f-d12e-489f-8828-bd6280a32f42',
      name: 'Uniquement au niveau de la plaque',
      imgsrc: '',
      childs: [
        'f679b28f-2a29-48ad-a675-d7b9e864d208',
        '1926bb9e-e58e-4e59-bce8-abdd244c6238',
        'b69051ec-e8d4-463c-b164-719e068e26a2',
        '9978d775-aff0-4482-9ee7-f3f745ae0615',
        'fef0f95b-7eca-49a6-98f0-d2b0f938346f',
        '9216cebe-f697-40fc-b005-35fe9c160f3c',
        'f32f8cf5-204c-4944-882c-ed4f79eb506e',
      ],
    },
    'd33da54d-b530-4506-a223-93b1835b6fa6': {
      id: 'd33da54d-b530-4506-a223-93b1835b6fa6',
      name: 'Au niveau du four ET de la plaque',
      imgsrc: '',
    },
    'f888b43d-42a8-445e-a497-5f9225d054cf': {
      id: 'f888b43d-42a8-445e-a497-5f9225d054cf',
      name: 'Uniquement au niveau du four',
      imgsrc: '',
    },
    'b388e730-cfba-4cba-90fe-56f409e01999': {
      id: 'b388e730-cfba-4cba-90fe-56f409e01999',
      name: 'Uniquement au niveau de la plaque',
      imgsrc: '',
      childs: [
        '33bc7936-7fa7-448a-8bd0-fd7df435d6f2',
        '8715bd88-3504-485b-8ad9-7267f28ef307',
        '39c09c1c-7e06-45fe-bcc4-7aaf6046d6b3',
        '46c82e8a-776b-41f3-a60e-6127f1ade1d9',
        '885d1ffa-b661-4444-9127-197321933559',
        '9352a604-54ea-4816-b75d-5b7e7f6a7bfb',
        'c81f2e03-1dcf-4f14-95b1-9adb2ecf96dc',
      ],
    },
    '360f4def-25c0-4cb8-8721-2d289fb1167d': {
      id: '360f4def-25c0-4cb8-8721-2d289fb1167d',
      name: 'Au niveau du four ET de la plaque',
      imgsrc: '',
    },
    '7fef9185-2110-4823-9ee9-b615cab56310': {
      id: '7fef9185-2110-4823-9ee9-b615cab56310',
      name: 'Uniquement au niveau du four',
      imgsrc: '',
    },
    'fa049251-ab43-4d75-b44d-ea0b2a977da7': {
      id: 'fa049251-ab43-4d75-b44d-ea0b2a977da7',
      name: 'Uniquement au niveau de la plaque',
      imgsrc: '',
      childs: [
        '12a7247a-8ca1-4be9-a0cf-0899eae763b8',
        '68fb986d-806a-427b-ac17-7e651fca500d',
        'c55eb855-973c-4257-b077-edfdeea1d776',
        '48fc67b7-a9ef-443d-88de-1741db1d5ebe',
        '054f3b18-c325-4668-998a-1bd7277b4ee9',
        '0d0f0b6a-101e-45da-a0b3-c1f4cdc6f67a',
        '9321cf5f-7ffa-4bf7-b6f4-8dc9a040426c',
      ],
    },
    '29f3ac0b-0188-4b13-afaa-4f366e39a8cb': {
      id: '29f3ac0b-0188-4b13-afaa-4f366e39a8cb',
      name: "Au niveau des 2 arrivées d'eau",
      imgsrc: '',
    },
    '5227222e-c223-4dc4-988c-7d7f566412cc': {
      id: '5227222e-c223-4dc4-988c-7d7f566412cc',
      name: "Au niveau de l'arrivée d'eau chaude",
      imgsrc: '',
    },
    'af6e7b85-8b13-4d8e-9791-2cb58a8a2373': {
      id: 'af6e7b85-8b13-4d8e-9791-2cb58a8a2373',
      name: "Au niveau de l'arrivée d'eau froide",
      imgsrc: '',
    },
    'b2b466ff-4341-4015-8aba-4d7cf05dc727': {
      id: 'b2b466ff-4341-4015-8aba-4d7cf05dc727',
      name: 'Je ne sais pas',
      imgsrc: '',
    },
    '3c5d7bf6-9cbc-4d9c-8fc7-4b2628200d95': {
      id: '3c5d7bf6-9cbc-4d9c-8fc7-4b2628200d95',
      name: 'Au niveau des 2 robinets',
      imgsrc: '',
    },
    'b953bbfa-90b7-49ed-b29f-444d5b0b157d': {
      id: 'b953bbfa-90b7-49ed-b29f-444d5b0b157d',
      name: "Au niveau du robinet d'eau chaude",
      imgsrc: '',
    },
    'd92dde13-8634-4c6f-9307-3668a92fc2ad': {
      id: 'd92dde13-8634-4c6f-9307-3668a92fc2ad',
      name: "Au niveau du robinet d'eau froide",
      imgsrc: '',
    },
    '0a9af10c-15b6-4ca0-bdc9-12619b8fac01': {
      id: '0a9af10c-15b6-4ca0-bdc9-12619b8fac01',
      name: "Au niveau des 2 arrivées d'eau",
      imgsrc: '',
    },
    '269e41d3-cfa0-425c-8dbc-176eccd259c2': {
      id: '269e41d3-cfa0-425c-8dbc-176eccd259c2',
      name: "Au niveau de l'arrivée d'eau chaude",
      imgsrc: '',
    },
    '1c41418c-6ed7-4b31-b1a0-99007a092f42': {
      id: '1c41418c-6ed7-4b31-b1a0-99007a092f42',
      name: "Au niveau de l'arrivée d'eau froide",
      imgsrc: '',
    },
    '7aa74c12-13ce-40d3-96fd-4edd72190f1c': {
      id: '7aa74c12-13ce-40d3-96fd-4edd72190f1c',
      name: 'Je ne sais pas ',
      imgsrc: '',
    },
    'c3552dcc-a78c-4454-b76f-21cf5763b684': {
      id: 'c3552dcc-a78c-4454-b76f-21cf5763b684',
      name: "Au niveau des 2 arrivées d'eau",
      imgsrc: '',
    },
    '7e1f7a12-da6b-4fec-aa19-72f49920d81b': {
      id: '7e1f7a12-da6b-4fec-aa19-72f49920d81b',
      name: "Au niveau de l'arrivée d'eau chaude",
      imgsrc: '',
    },
    '05f6af09-a677-44b1-a0e3-f1c7ce62d608': {
      id: '05f6af09-a677-44b1-a0e3-f1c7ce62d608',
      name: "Au niveau de l'arrivée d'eau froide",
      imgsrc: '',
    },
    'd76cd50c-79ad-4d69-8570-6ca93384f858': {
      id: 'd76cd50c-79ad-4d69-8570-6ca93384f858',
      name: 'Je ne sais pas ',
      imgsrc: '',
    },
    '3cafb8ec-436a-45cf-8ebf-d4679a38feec': {
      id: '3cafb8ec-436a-45cf-8ebf-d4679a38feec',
      name: 'Au niveau des 2 robinets',
      imgsrc: '',
    },
    'c06c6a11-807f-4466-a140-e06643d367bb': {
      id: 'c06c6a11-807f-4466-a140-e06643d367bb',
      name: "Au niveau du robinet d'eau chaude",
      imgsrc: '',
    },
    '2d8fcfc8-6d44-44c3-91fe-3c18518cc74b': {
      id: '2d8fcfc8-6d44-44c3-91fe-3c18518cc74b',
      name: "Au niveau du robinet d'eau froide",
      imgsrc: '',
    },
    'f6763403-7cdd-47f4-9d16-33fd1cdcfe5d': {
      id: 'f6763403-7cdd-47f4-9d16-33fd1cdcfe5d',
      name: 'Aucune connexion',
      imgsrc: '',
    },
    'e9f0d835-f991-4893-a3c3-13a1c6befb53': {
      id: 'e9f0d835-f991-4893-a3c3-13a1c6befb53',
      name: 'Connexion intermittente',
      imgsrc: '',
    },
    'e979ff36-e1a7-4fae-97c7-74e3e79f1de8': { id: 'e979ff36-e1a7-4fae-97c7-74e3e79f1de8', name: 'Lenteur', imgsrc: '' },
    '599d191d-61bc-4e66-be52-ca275ddbe70f': {
      id: '599d191d-61bc-4e66-be52-ca275ddbe70f',
      name: 'Autre problème',
      imgsrc: '',
    },
    '35a91066-059c-4fd0-ab9d-65336560a94d': {
      id: '35a91066-059c-4fd0-ab9d-65336560a94d',
      name: 'Aucune connexion',
      imgsrc: '',
    },
    '541b27e9-7194-4305-9344-268497863a31': {
      id: '541b27e9-7194-4305-9344-268497863a31',
      name: 'Connexion intermittente',
      imgsrc: '',
    },
    '4c58dcbc-9db6-474a-b5aa-cde82ef5a83b': { id: '4c58dcbc-9db6-474a-b5aa-cde82ef5a83b', name: 'Lenteur', imgsrc: '' },
    'a4b62d80-df79-4cad-a604-9aee06dfeb6d': {
      id: 'a4b62d80-df79-4cad-a604-9aee06dfeb6d',
      name: 'Autre problème',
      imgsrc: '',
    },
    '80e2c0bb-32c4-4e08-a15b-939919d49e09': {
      id: '80e2c0bb-32c4-4e08-a15b-939919d49e09',
      name: 'Aucune connexion',
      imgsrc: '',
    },
    '70f7c1da-360a-4315-9998-2a6be222c97f': {
      id: '70f7c1da-360a-4315-9998-2a6be222c97f',
      name: 'Connexion intermittente',
      imgsrc: '',
    },
    'c60e6960-2855-4268-b6b9-a8df76448e63': { id: 'c60e6960-2855-4268-b6b9-a8df76448e63', name: 'Lenteur', imgsrc: '' },
    '7329e99d-ef13-4863-8442-fecfd96f433d': {
      id: '7329e99d-ef13-4863-8442-fecfd96f433d',
      name: 'Autre problème',
      imgsrc: '',
    },
    'e02e64e7-2d95-4c79-8e21-c7151f103f8a': {
      id: 'e02e64e7-2d95-4c79-8e21-c7151f103f8a',
      name: 'Sur tous les foyers',
      imgsrc: '',
    },
    '9e729924-3f9d-4aa4-9800-13a57ed33e95': {
      id: '9e729924-3f9d-4aa4-9800-13a57ed33e95',
      name: 'Sur le foyer en haut à gauche',
      imgsrc: '',
    },
    'a040fe00-68f2-46a3-b7fd-7296de14f006': {
      id: 'a040fe00-68f2-46a3-b7fd-7296de14f006',
      name: 'Sur le foyer en haut à droite',
      imgsrc: '',
    },
    '30d58784-9836-4c7a-a486-93e95fb6ae17': {
      id: '30d58784-9836-4c7a-a486-93e95fb6ae17',
      name: 'Sur le foyer en bas à gauche',
      imgsrc: '',
    },
    '7a8f80ea-b758-40b7-b6c0-9086d5bd08f8': {
      id: '7a8f80ea-b758-40b7-b6c0-9086d5bd08f8',
      name: 'Sur le foyer en bas à droite',
      imgsrc: '',
    },
    '41859fbf-65b8-4808-bc18-a68ddd78cbed': {
      id: '41859fbf-65b8-4808-bc18-a68ddd78cbed',
      name: 'Sur le foyer central',
      imgsrc: '',
    },
    '5f3392fa-c803-4e20-897d-9e05aa6efa98': {
      id: '5f3392fa-c803-4e20-897d-9e05aa6efa98',
      name: 'Sur un autre foyer',
      imgsrc: '',
    },
    'f679b28f-2a29-48ad-a675-d7b9e864d208': {
      id: 'f679b28f-2a29-48ad-a675-d7b9e864d208',
      name: 'Sur tous les foyers',
      imgsrc: '',
    },
    '1926bb9e-e58e-4e59-bce8-abdd244c6238': {
      id: '1926bb9e-e58e-4e59-bce8-abdd244c6238',
      name: 'Sur le foyer en haut à gauche',
      imgsrc: '',
    },
    'b69051ec-e8d4-463c-b164-719e068e26a2': {
      id: 'b69051ec-e8d4-463c-b164-719e068e26a2',
      name: 'Sur le foyer en haut à droite',
      imgsrc: '',
    },
    '9978d775-aff0-4482-9ee7-f3f745ae0615': {
      id: '9978d775-aff0-4482-9ee7-f3f745ae0615',
      name: 'Sur le foyer en bas à gauche',
      imgsrc: '',
    },
    'fef0f95b-7eca-49a6-98f0-d2b0f938346f': {
      id: 'fef0f95b-7eca-49a6-98f0-d2b0f938346f',
      name: 'Sur le foyer en bas à droite',
      imgsrc: '',
    },
    '9216cebe-f697-40fc-b005-35fe9c160f3c': {
      id: '9216cebe-f697-40fc-b005-35fe9c160f3c',
      name: 'Sur le foyer central',
      imgsrc: '',
    },
    'f32f8cf5-204c-4944-882c-ed4f79eb506e': {
      id: 'f32f8cf5-204c-4944-882c-ed4f79eb506e',
      name: 'Sur un autre foyer',
      imgsrc: '',
    },
    '33bc7936-7fa7-448a-8bd0-fd7df435d6f2': {
      id: '33bc7936-7fa7-448a-8bd0-fd7df435d6f2',
      name: 'Sur tous les foyers',
      imgsrc: '',
    },
    '8715bd88-3504-485b-8ad9-7267f28ef307': {
      id: '8715bd88-3504-485b-8ad9-7267f28ef307',
      name: 'Sur le foyer en haut à gauche',
      imgsrc: '',
    },
    '39c09c1c-7e06-45fe-bcc4-7aaf6046d6b3': {
      id: '39c09c1c-7e06-45fe-bcc4-7aaf6046d6b3',
      name: 'Sur le foyer en haut à droite',
      imgsrc: '',
    },
    '46c82e8a-776b-41f3-a60e-6127f1ade1d9': {
      id: '46c82e8a-776b-41f3-a60e-6127f1ade1d9',
      name: 'Sur le foyer en bas à gauche',
      imgsrc: '',
    },
    '885d1ffa-b661-4444-9127-197321933559': {
      id: '885d1ffa-b661-4444-9127-197321933559',
      name: 'Sur le foyer en bas à droite',
      imgsrc: '',
    },
    '9352a604-54ea-4816-b75d-5b7e7f6a7bfb': {
      id: '9352a604-54ea-4816-b75d-5b7e7f6a7bfb',
      name: 'Sur le foyer central',
      imgsrc: '',
    },
    'c81f2e03-1dcf-4f14-95b1-9adb2ecf96dc': {
      id: 'c81f2e03-1dcf-4f14-95b1-9adb2ecf96dc',
      name: 'Sur un autre foyer',
      imgsrc: '',
    },
    '12a7247a-8ca1-4be9-a0cf-0899eae763b8': {
      id: '12a7247a-8ca1-4be9-a0cf-0899eae763b8',
      name: 'Sur tous les foyers',
      imgsrc: '',
    },
    '68fb986d-806a-427b-ac17-7e651fca500d': {
      id: '68fb986d-806a-427b-ac17-7e651fca500d',
      name: 'Sur le foyer en haut à gauche',
      imgsrc: '',
    },
    'c55eb855-973c-4257-b077-edfdeea1d776': {
      id: 'c55eb855-973c-4257-b077-edfdeea1d776',
      name: 'Sur le foyer en haut à droite',
      imgsrc: '',
    },
    '48fc67b7-a9ef-443d-88de-1741db1d5ebe': {
      id: '48fc67b7-a9ef-443d-88de-1741db1d5ebe',
      name: 'Sur le foyer en bas à gauche',
      imgsrc: '',
    },
    '054f3b18-c325-4668-998a-1bd7277b4ee9': {
      id: '054f3b18-c325-4668-998a-1bd7277b4ee9',
      name: 'Sur le foyer en bas à droite',
      imgsrc: '',
    },
    '0d0f0b6a-101e-45da-a0b3-c1f4cdc6f67a': {
      id: '0d0f0b6a-101e-45da-a0b3-c1f4cdc6f67a',
      name: 'Sur le foyer central',
      imgsrc: '',
    },
    '9321cf5f-7ffa-4bf7-b6f4-8dc9a040426c': {
      id: '9321cf5f-7ffa-4bf7-b6f4-8dc9a040426c',
      name: 'Sur un autre foyer',
      imgsrc: '',
    },
  },
}

export { AppliPbForm }
