<template>
  <div>
    <ApplianceKindInput v-if="categories" :value="applianceKind" :categories="categories" @input="onKindInput" />
    <v-combobox
      v-model="appliance.brand"
      :items="brands"
      clearable
      dense
      label="Marque"
      :menu-props="{ 'allow-overflow': true }"
    ></v-combobox>
    <v-text-field v-model="appliance.model" dense label="Model" />
    <v-text-field v-model="appliance.type" dense label="Type" />
    <v-text-field v-model="appliance.gtin" dense label="Réf" />
    <v-text-field v-model="appliance.serial" dense label="N° série" />
    <v-checkbox v-model="appliance.underwarranty" dense label="Sous garantie" />
  </div>
</template>

<script>
import ApplianceKindInput from '~/components/ApplianceKindInput'
import { brands } from '~/api/brands'
export default {
  components: {
    ApplianceKindInput,
  },
  props: {
    value: {
      type: Object, // Appliance
      default: null,
    },
    categories: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      applianceKind: this.value?.kind_id,
      appliance: {
        kind_id: undefined,
        kind: '',
        brand: '',
        model: '',
        type: '',
        gtin: '',
        serial: '',
        underwarranty: false,
        ...this.value,
      },
      brands: [],
    }
  },
  watch: {
    appliance: {
      deep: true,
      immediate: false,
      handler(changed) {
        this.$emit('input', changed)
      },
    },
  },
  created() {
    brands().then((r) => (this.brands = r.map((b) => b.name)))
  },
  methods: {
    onKindInput(kind) {
      this.applianceKind = kind
      if (kind) {
        this.appliance.kind_id = kind.id
        this.appliance.kind = kind.name
      } else {
        this.appliance.kind_id = undefined
        this.appliance.kind = ''
      }
    },
  },
}
</script>
