import Vue from 'vue'
import VueMatomo from 'vue-matomo'

export default ({ app }) => {
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  Vue.use(VueMatomo, {
    router: app.router,

    /** Other configuration options **/
    host: 'https://matomo.aposio.com',
    siteId: 2,
    // debug: true,
    preInitActions: [
      ['setCustomVariable', 1, 'commit', process.env.commitId, 'visit'],
      ['setCustomVariable', 2, 'build_at', process.env.buildTime, 'visit'],
    ],
  })
}
