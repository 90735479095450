import { render, staticRenderFns } from "./DevInfo.vue?vue&type=template&id=09e451cd&scoped=true&"
import script from "./DevInfo.vue?vue&type=script&lang=ts&"
export * from "./DevInfo.vue?vue&type=script&lang=ts&"
import style0 from "./DevInfo.vue?vue&type=style&index=0&id=09e451cd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09e451cd",
  null
  
)

export default component.exports