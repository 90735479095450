<template>
  <v-autocomplete
    v-model="selectedCompany"
    :items="repair_shops"
    item-key="id"
    item-value="id"
    item-text="name"
    return-object
    clearable
    hide-details
    :loading="loading"
    :filter="search"
    placeholder="Entreprise à controler"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #item="props">
      <v-list-item-avatar tile>
        <v-img v-if="props.item.logo_url" :src="props.item.logo_url" contain />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="d-flex flex-row">
          {{ props.item.name }}
          <v-spacer />
          <div class="body-1 grey--text darken-1">{{ props.item.status }}</div>
        </v-list-item-title>
        <v-list-item-subtitle v-if="props.item.addresses">
          <span v-for="(address, i) in props.item.addresses" :key="i"> {{ address.zip }} {{ address.city }} </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <!-- <template #selection="{ item }">
      <v-img v-if="item.logo_url" :src="item.logo_url" max-height="32" max-width="32" />
      {{ item.name }}
      <span v-if="item.adresses[0]"> {{ item.adresses[0].zip }} {{ item.adresses[0].city }} </span>
    </template> -->
  </v-autocomplete>
</template>

<script>
export default {
  data() {
    return {
      repair_shops: [],
      loading: false,
    }
  },
  computed: {
    selectedCompany: {
      get() {
        return this.$store.state.selectedCompany || null
      },
      set(company) {
        this.$store.commit('changeSelectedCompany', company)
      },
    },
  },
  mounted() {
    this.getAllShops()
  },
  methods: {
    search(item, queryText) {
      const text = item.name.replace('.', '') + ' ' + item.addresses?.map((a) => a.zip + ' ' + a.city)?.join(' ') || ''
      const textnorm = text
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      const query = queryText
        .replace('.', '')
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      return textnorm.includes(query)
    },

    async getAllShops() {
      this.loading = true
      const res = await fetch('/api/v1/admin/repairshop', {
        headers: { Accept: 'application/json' },
        credentials: 'include',
      })
        .then((res) => res.json())
        .catch((e) => console.log(e))
        .finally(() => {
          this.loading = false
        })
      res.shops.sort((a, b) => (b.users?.length || 0) - (a.users?.length || 0))
      this.repair_shops = res.shops
    },
  },
}
</script>
