export default async function ({ store, route, redirect }) {
  // check auth
  // console.log('aposio auth plugin', store.getters.isAuthenticated)
  // await store.dispatch('validateAuth')
  // if (!store.getters.isAuthenticated) {
  //   console.log('pas auth !!!!')
  //   if (route.path.startsWith('/app') && !route.path.startsWith('/app/connexion')) {
  //     console.log('access denied to', route.fullPath)
  //     redirect(302, '/app/connexion', { to: encodeURI(route.fullPath) })
  //   }
  // }
}
