<template>
  <v-list>
    <v-list-item v-if="noSSE">
      <v-list-item-title class="text-wrap">
        <p>Votre navigateur web est un peu vieux...</p>
        <p>
          Nous vous conseillons d'utiliser
          <a href="https://www.mozilla.org/fr/firefox/new/" target="_blank">Firefox</a> ou
          <a href="https://www.google.com/intl/fr_fr/chrome/" target="_blank">Chrome</a>
        </p>
      </v-list-item-title>
    </v-list-item>
    <template v-for="(notif, i) in notifications">
      <EventItem
        v-if="notif.type === 'event'"
        :key="i"
        :value="notif.data"
        @click="$router.push('/app/sheet/' + notif.data.repair_id)"
      >
        <v-list-item-action>
          <v-btn small icon @click="seeOne(notif)"> <v-icon small>mdi-close</v-icon> </v-btn>
        </v-list-item-action>
      </EventItem>
      <v-list-item v-else :key="i">
        <v-list-item-content>
          <v-list-item-title class="text-wrap">{{ notif.data.title }}</v-list-item-title>
          <v-list-item-subtitle class="text-wrap">{{ notif.data.subtitle }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn small icon @click="seeOne(notif)"> <v-icon small>mdi-close</v-icon> </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-list-item v-if="!notifications || notifications.length === 0">
      <v-list-item-title>Pas de nouveau rendez-vous</v-list-item-title>
    </v-list-item>
    <v-divider class="mb-1" />
    <a class="px-4 pt-4 pb-2 ma-0" @click.prevent="seeAll">Tout marquer comme lu</a>
  </v-list>
</template>

<script>
import { mapMutations } from 'vuex'
import EventItem from '~/components/EventItem'
export default {
  components: {
    EventItem,
  },
  data() {
    return {
      noSSE: !window.EventSource,
    }
  },
  computed: {
    notifications() {
      return this.$store.state.notify.notifications || []
    },
  },
  methods: {
    ...mapMutations({ seeAll: 'notify/delNotificationAll', seeOne: 'notify/delNotification' }),
  },
}
</script>
