const middleware = {}

middleware['auth-custom'] = require('../middleware/auth-custom.js')
middleware['auth-custom'] = middleware['auth-custom'].default || middleware['auth-custom']

middleware['ip'] = require('../middleware/ip.js')
middleware['ip'] = middleware['ip'].default || middleware['ip']

middleware['logger'] = require('../middleware/logger.js')
middleware['logger'] = middleware['logger'].default || middleware['logger']

middleware['redirect-domain'] = require('../middleware/redirect-domain.js')
middleware['redirect-domain'] = middleware['redirect-domain'].default || middleware['redirect-domain']

middleware['selectiveSSR'] = require('../middleware/selectiveSSR.js')
middleware['selectiveSSR'] = middleware['selectiveSSR'].default || middleware['selectiveSSR']

export default middleware
