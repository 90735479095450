<template>
  <v-list-item v-bind="$attrs" class="fill-height" v-on="$listeners">
    <v-list-item-content class="text-wrap">
      <v-list-item-subtitle class="text-wrap">
        <v-chip v-if="repair && repair.urgency > 0" small color="red" dark>
          <v-icon small left>mdi-fire</v-icon> Urgent
        </v-chip>
        <v-chip v-if="!hideStatus" small>{{ repair.status }}</v-chip>
        <span>
          <!-- depuis {{ $dayjs(repair.statusFrom).calendar() }}, -->
          {{ $dayjs(lastTimeStatusChanged).calendar() }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-title v-if="repair.client" class="text-wrap">
        {{ repair.client.firstname && repair.client.firstname.charAt(0).toUpperCase() }}.
        {{ repair.client.lastname }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="repair.appliance" class="text-wrap">
        {{ repair.appliance.kind || repair.appliance.category }} {{ repair.appliance.brand }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="nextEvent" class="text-wrap">
        <v-icon color="grey" small>mdi-calendar</v-icon> {{ $dayjs(nextEvent.start_at).calendar() }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-avatar v-if="applianceIcon" tile>
      <v-img :src="applianceIcon" class="img-gray-filter" />
    </v-list-item-avatar>
  </v-list-item>
</template>

<script>
import { familyTypeImg } from '~/api/skills'
export default {
  props: {
    repair: {
      type: Object,
      required: true,
    },
    hideStatus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    applianceIcon() {
      if (this.repair?.appliance) {
        return familyTypeImg(this.repair.appliance.kind_id || this.repair.appliance.category).imgsrc
      }
      return null
    },

    nextEvent() {
      return this.repair?.events && this.repair.events[this.repair.events.length - 1]
    },

    lastTimeStatusChanged() {
      return this.repair?.statusFrom || this.repair?.created_at
    },
  },
}
</script>

<style></style>
