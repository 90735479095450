<template>
  <div class="flex-column">
    <div class="pb-4">
      <a v-for="ans in AnswerArray" :key="ans.id" @click="back(ans)">
        <span v-if="AnswerArray[0] != ans"> > </span> {{ ans.name }}
      </a>
    </div>

    <v-list>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(choice, i) in questions"
          :key="i"
          :value="choice"
          class="grey lighten-5 my-2"
          @click="choose(choice)"
        >
          <v-list-item-content>
            {{ choice.name }}
          </v-list-item-content>
          <v-avatar v-if="choice.imgsrc && choice.imgsrc !== ''" tile>
            <img :src="choice.imgsrc" :alt="`image ${choice.name}`" class="img-gray-filter" />
          </v-avatar>

          <!-- <v-card v-if="choice.input" class="d-flex-column justify-center mb-8" flat>
            <h4>{{ choice.text }}</h4>
            <v-text-field v-model="inputAnswer" :label="choice.enonce || choice.name" :type="choice.input" />

            <v-btn :disabled="inputAnswer == ''" color="primary" @click="choose(choice)">
              OK <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card> -->
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<style scoped>
img {
  max-width: 32px;
  max-height: 32px;
}
</style>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: (_) => [],
    },
    init: {
      type: Object,
      default: (_) => null,
    },
    qcm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputAnswer: '',
      AnswerArray: [],
    }
  },
  computed: {
    questions() {
      if (this.AnswerArray.length === 0) {
        return this.init?.childs?.map((childID) => this.qcm.nodes[childID])
      }
      return this.AnswerArray[this.AnswerArray.length - 1]?.childs?.map((childID) => this.qcm.nodes[childID])
    },
  },
  methods: {
    choose(choice) {
      this.$set(this.AnswerArray, this.AnswerArray.length, choice)
      if (!choice.childs || choice.childs.length === 0) {
        return this.$emit('input', this.AnswerArray)
      }
    },

    back(answer) {
      const indexToDelete = this.AnswerArray.indexOf(answer)
      if (indexToDelete >= 0) {
        this.AnswerArray.splice(indexToDelete)
      }
    },
  },
}
</script>
