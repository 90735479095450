<template>
  <v-list-item :value="value" v-bind="$attrs">
    <v-list-item-content v-if="!client" :class="dense ? 'py-0' : ''">
      <v-list-item-title class="grey--text">
        <v-icon left color="grey lighten-1">mdi-account-off</v-icon> Pas de client
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-content v-else :class="dense ? 'py-0' : ''">
      <v-list-item-title class="client-name">{{ client.firstname }} {{ client.lastname }}</v-list-item-title>
      <v-list-item-subtitle v-if="client.email && client.email !== ''">
        <!-- <v-icon small left>mdi-email</v-icon> -->
        {{ client.email }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="client.phones && client.phones.length > 0">
        <!-- <v-icon small left>mdi-phone</v-icon> -->
        <span v-for="(phone, i) in client.phones" :key="i">
          {{ i === 0 ? '' : ' | ' }}
          <a :href="'tel:' + phone.number">{{ phoneFormated(phone.number) }}</a> {{ phone.name }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-for="(address, i) in client.addresses" :key="i" class="text-wrap">
        <!-- <v-icon small left>mdi-map-marker</v-icon> -->
        <template v-if="address && address.tags && client.addresses.length > 1">
          <v-icon v-if="address.tags.includes('facturation')" x-small>mdi-file</v-icon>
          <v-icon v-if="!address.tags.includes('intervention')" x-small>mdi-truck</v-icon>
        </template>
        <span>
          {{ !smalladdress ? address.street : '' }}
          {{ address.zip }}
          {{ address.city }}
        </span>
        <span v-if="$store.getters.isAdmin && address.lat">
          <br />
          <v-icon x-small>mdi-map-marker</v-icon> {{ address.lat }} {{ address.lon }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="client.address && client.address.note !== ''" class="text-wrap">
        <span>
          {{ client.address.note }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="client.note" class="text-wrap">
        <span>
          {{ client.note }}
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="$slots.default">
      <slot></slot>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  props: {
    value: {
      type: [String, undefined],
      default: () => undefined,
    },
    client: {
      type: Object,
      default() {
        return null
      },
    },
    smalladdress: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change() {
      console.log('change')
    },
    phoneFormated(phone) {
      if (!phone) return null
      const ph = parsePhoneNumberFromString(phone, 'FR')
      return ph?.formatNational() || phone
    },
  },
}
</script>

<style scoped>
@media print {
  .client-name {
    font-size: 1.6rem;
  }
}
</style>
