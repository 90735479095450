<template>
  <v-app class="">
    <v-main>
      <nuxt />
    </v-main>

    <v-footer v-if="!$route.path.startsWith('/app')">
      <v-row class="justify-space-between text-caption">
        <div @click="devmodal = true">aposio &copy; {{ new Date().getFullYear() }}</div>
        <v-bottom-sheet v-model="devmodal"><DevInfo /></v-bottom-sheet>
        <a href="/legal#rgpd">politique de protection des données</a>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import DevInfo from '~/components/DevInfo'
export default {
  components: {
    DevInfo,
  },
  data() {
    return {
      devmodal: false,
    }
  },
}
</script>

<style scoped>
#app {
  background-color: whitesmoke;
}
</style>
