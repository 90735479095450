<template>
  <div v-bind="$attrs">
    <v-form>
      <div v-if="!hideTags">
        Type d'adresse :
        <v-chip-group v-model="address.tags" multiple column color="primary" class="text-capitalize">
          <v-chip v-for="(tag, itag) in Taglist" :key="itag" outlined :value="tag">
            <v-icon v-if="tag === 'facturation'" small left>mdi-file</v-icon>
            <v-icon v-if="tag !== 'facturation'" small left>mdi-truck</v-icon>
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </div>

      <AddressInput :value="address" :locality="locality" @input="address = { ...address, ...$event }" />
      <v-text-field v-model="address.street" dense label="Voie, nom de rue, ..." />
      <v-text-field v-model="address.complement" dense label="Complement" />
      <div class="d-flex flex-rows">
        <v-text-field v-model="address.zip" dense label="Code postal" style="max-width: 5em" class="mr-4" />
        <v-text-field v-model="address.city" dense label="Ville" />
      </div>
      <v-text-field v-model="address.country" dense label="Pays"> </v-text-field>
      <div v-if="$store.getters.isAdmin" class="d-flex flex-rows">
        <v-text-field v-model="address.lat" dense type="number" label="Latitude" class="mr-4" />
        <v-text-field v-model="address.lon" dense type="number" label="Longitude" class="mr-4" />
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="getCoords"><v-icon>mdi-map-marker</v-icon></v-btn>
          </template>
          <span>Redéfinir les coordonnées GPS en fonction de l'adresse</span>
        </v-tooltip>
      </div>
    </v-form>
    <v-card-actions v-if="!hideActions">
      <v-btn text @click.prevent="$emit('cancel')"> Annuler </v-btn>
      <v-spacer />
      <v-btn color="primary" @click.prevent="validate"> Valider </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { gql } from 'graphql-tag'
import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'
import AddressInput from '~/components/AddressInput'
export default {
  name: 'UpsertClientAddress',
  components: {
    AddressInput,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          address_id: null,
          tags: ['facturation', 'intervention'],
          street: '',
          complement: '',
          zip: '',
          city: '',
          country: 'France',
          lat: null,
          lon: null,
        }
      },
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideTags: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      repair_shop: {},
      address: null,
      Taglist: ['facturation', 'intervention'],
      autocomplete: null,
    }
  },
  computed: {
    locality() {
      return this.repair_shop?.address
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(p) {
        this.address = cloneDeep(p) || {
          address_id: null,
          tags: ['facturation', 'intervention'],
          street: '',
          complement: '',
          zip: '',
          city: '',
          country: 'France',
          lat: null,
          lon: null,
        }
      },
    },
    address: {
      deep: true,
      handler(p) {
        if (!isEqual(p, this.value)) {
          this.$emit('input', p)
        }
      },
    },
  },
  methods: {
    getCoords() {
      const url =
        'https://api-adresse.data.gouv.fr/search/?q=' +
        encodeURIComponent(this.address.street + ' ' + this.address.zip + ' ' + this.address.city)
      fetch(url)
        .then((res) => res.json())
        .then((res) => {
          if (res?.features?.length > 0) {
            this.address.lat = res?.features[0].geometry.coordinates[1]
            this.address.lon = res?.features[0].geometry.coordinates[0]
          }
        })
    },

    validate() {
      if (!this.address?.lat) this.getCoords()
      this.$emit('finish', this.address)
    },
  },

  apollo: {
    repair_shop: {
      query: gql`
        query RepairShop($id: ID!) {
          repair_shop(id: $id) {
            id
            address {
              address_id
              tags
              street
              complement
              zip
              city
              country
              lat
              lon
            }
          }
        }
      `,
      variables() {
        return { id: this.$store.state.authSession.me.shop_id }
      },
    },
  },
}
</script>
