export default (context) => {
  context.$dayjs.updateLocale('fr', {
    calendar: {
      lastDay: '[Hier à] LT',
      sameDay: "[Aujourd'hui à] LT",
      nextDay: '[Demain à] LT',
      lastWeek: 'dddd [dernier à] LT',
      nextWeek: 'dddd [à] LT',
      sameElse: 'L',
    },
  })
}
