<template>
  <v-autocomplete
    :value="value"
    :search-input.sync="search"
    :items="clients"
    :item-text="itemTextTransform"
    return-object
    :loading="$apollo.loading"
    clearable
    label="Recherche du client"
    hint="Par nom, addresse, téléphone, email..."
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #no-data>
      <v-divider />
      <p class="text-center mt-8">Aucun client trouvé</p>
    </template>
    <template #prepend-item>
      <v-list-item>
        <v-btn block text @click="onNewClient">Nouveau client</v-btn>
      </v-list-item>
    </template>
    <template #item="data">
      <div :key="data.item.id">
        <v-divider :key="'client_div_' + data.item.id" block />
        <ClientItem :key="data.item.id" :client="data.item" />
      </div>
    </template>
    <template #selection="data">
      <ClientItem :client="data.item" />
    </template>
  </v-autocomplete>
</template>

<script>
import { gql } from 'graphql-tag'
import ClientItem from '~/components/client-item.vue'

export default {
  components: {
    ClientItem,
  },

  props: {
    value: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      search: null,
    }
  },
  methods: {
    onInput(client) {
      this.$emit('input', client)
    },
    onNewClient() {
      this.$emit('new-client', this.search)
    },
    filterClient(item, queryText, itemText) {
      console.log(item, itemText)
      return true
    },
    itemTextTransform(item) {
      return (
        item.lastname +
        ' ' +
        item.firstname +
        ' ' +
        (item.phones?.map((p) => p.number).join(' ') || '') +
        ' ' +
        item.email
      )
    },
  },
  apollo: {
    clients: {
      query: gql`
        query RepairShop($shop_id: ID!) {
          repair_shop(id: $shop_id) {
            id
            clients {
              id
              type
              lastname
              firstname
              phones {
                name
                number
              }
              email
              addresses {
                street
                complement
                zip
                city
                country
              }
              note
            }
          }
        }
      `,
      variables() {
        return {
          shop_id: this.$store.getters.selectedCompanyID,
        }
      },
      skip() {
        return !this.$store.state.authSession
      },
      update: (data) => data && data.repair_shop.clients,
    },
  },
}
</script>
