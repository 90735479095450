const STORAGE_KEY = 'aposio_notify'
export const state = () => {
  let state = { notifications: [], lastConnection: null }
  try {
    state = JSON.parse(localStorage.getItem(STORAGE_KEY))
  } catch (e) {
    console.log(e)
  }
  return state
}

export const getters = {
  count: (state) => state.notifications?.length || 0,
}

export const mutations = {
  addNotification(state, notif) {
    state.notifications = [notif, ...(state.notifications || [])]
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
  },

  delNotification(state, notif) {
    state.notifications = state.notifications?.filter((n) => n !== notif) || []
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
  },

  delNotificationAll(state) {
    state.notifications = []
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
  },
}

export const action = {}
